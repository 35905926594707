import React from 'react';
import FormInput from '../../../../components/FormInput/FormInput';
import '../subForm.css';

export default function SubFormAdvances(props: any) {
    return (
        <div className='scroll-container'>
            <div className="sub-forms-container">
                <div className="sub-form">
                    <h1>Advances</h1>
                    <div className="sub-form">
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Quarter(s) Covered"} name={`advances.quartersCovered`} disabled={props.isAdmin} />
                            </div>
                        </div>
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Advance Period Start Date"} type={"date"} name={`advances.periodStartDate`} disabled={props.isAdmin} />
                                <FormInput label={"Advance Period End Date"} type={"date"} name={`advances.periodEndDate`} disabled={props.isAdmin} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}