import React from 'react';
import { Link } from "react-router-dom";
import './AdminHeader.css'

interface Props {
    currentTab: number
}

export default class AdminHeader extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.onTabClicked = this.onTabClicked.bind(this);
        this.state = {
            currentTab: 0
        }
    }

    onTabClicked(event: any) {
        const tabId = event.target.getAttribute('data-key');
        this.setState({ currentTab: tabId });
    }

    render() {
        return (
            <div className="admin-header">
                <h2>Digital Skills for Youth</h2>
                <span>Admin Portal</span>
                <div className="admin-header-tabs">
                    <Link to={ "/admin/applications/" }>
                        <div className={this.props.currentTab === 0 ? "admin-header-tab active" : "admin-header-tab"} data-key={0} onClick={this.onTabClicked}>
                            Applications
                        </div>
                    </Link>
                    <Link to={ "/admin/claims/" }>
                        <div className={this.props.currentTab === 1 ? "admin-header-tab active" : "admin-header-tab"} data-key={1} onClick={this.onTabClicked}>
                            Claims
                        </div>
                    </Link>
                    <Link to={"/admin/advances/"}>
                        <div className={this.props.currentTab === 2 ? "admin-header-tab active" : "admin-header-tab"} data-key={2} onClick={this.onTabClicked}>
                            Advances
                        </div>
                    </Link>
                    <Link to={"/admin/users/"}>
                        <div className={this.props.currentTab === 3 ? "admin-header-tab active" : "admin-header-tab"} data-key={3} onClick={this.onTabClicked}>
                            Users
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}              