import React from 'react';
import { Link, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import FormHeader from '../../../components/FormHeader/FormHeader';
import FormInput from '../../../components/FormInput/FormInput';
import OutlineButton from '../../../components/OutlineButton/OutlineButton';
import RadioQuestion from '../../../components/RadioQuestion/RadioQuestion';
import SolidButton from '../../../components/SolidButton/SolidButton';
import TextArea from '../../../components/TextArea/TextArea';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "../../../http-common";
import EmployeeInfoErrorList from '../../../components/ErrorList/EmployeeInfoErrorList';

enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset",
} 

interface Props {
    isWithin: boolean,
    isAdmin?: boolean,
    application?: any,
    location: {
        state: any
    }
}

interface State {
    redirect: boolean
    initialValues: any
    edit: boolean
    disabled: boolean
    errorsList: any
    formKey: number
}

class EmployerInformationForm extends React.Component<Props & RouteComponentProps, State> {
    questionNumber: number = 17;
    titleText: string = "outside";
    applicationId: number = 0;
    isAdminPage: any = window.location.pathname.includes('/admin/') || window.location.pathname.includes('/myforms/');

    initialValues = {
        legalBusinessName: '',
        doingBusinessAs: '',
        under500Employees: 'true',
        legalStatus: 'true',
        registeredBusiness: 'true',
        locatedIn: 'AB',
        registrationProof: 'true',
        hostInternshipCapacity: 'true',
        provideTraining: 'true',
        internshipDespiteLockdown: 'true',
        postSecondaryGraduate: 'true',
        employerType: 'true',
        businessNumber: '',
        contactName: '',
        address: '',
        workAddress: '',
        contactPhone: '',
        contactEmail: '',
        recruitUnderrepresented: 'true',
        previousParticipation: 'true',
        numInternships: 1,
        description: '',
        purpose: '',
        process: ''
    }

    validationSchema = Yup.object().shape({
        legalBusinessName: Yup.string().required("*This field is required."),
        under500Employees: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        legalStatus: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        registeredBusiness: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        registrationProof: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        hostInternshipCapacity: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        provideTraining: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        internshipDespiteLockdown: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        postSecondaryGraduate: Yup.string().oneOf(['true'], "*This statement must be true to qualify."),
        doingBusinessAs: Yup.string().required("*This field is required."),
        locatedIn: Yup.string().required("*This field is required."),
        businessNumber: Yup.string().required("*This field is required."),
        contactName: Yup.string().required("*This field is required."),
        address: Yup.string().required("*This field is required."),
        contactPhone: Yup.string().required("*This field is required.").min(10, "*Must use a valid phone number."),
        contactEmail: Yup.string().required("*This field is required.").email("*Must use a valid email."),
        numInternships: Yup.number().required("*This field is required.").min(1, "*Must be a valid number.").integer("*Must be a valid number.").positive("*Number is invalid."),
        description: Yup.string().required("*This field is required."),
        purpose: Yup.string().required("*This field is required."),
        process: Yup.string().required("*This field is required.")
    });

    constructor(props: any){
        super(props);

        this.state = {
            redirect: false, 
            initialValues: this.initialValues, 
            edit: false, 
            disabled: this.isAdminPage ? true : false,
            errorsList: {},
            formKey: 0
        };

        if (this.props.isWithin) {
            this.questionNumber = 18;
            this.titleText = "for"
        }

        if (this.props.isAdmin) {
            const { Employers } = this.props.application;
            const { 
                legalBusinessName,
                doingBusinessAs,
                under500Employees,
                legalStatus,
                registeredBusiness,
                locatedIn,
                registrationProof,
                hostInternshipCapacity,
                provideTraining,
                internshipDespiteLockdown,
                postSecondaryGraduate,
                employerType,
                businessNumber,
                contactName,
                address,
                workAddress,
                contactPhone,
                contactEmail,
                recruitUnderrepresented,
                previousParticipation,
                numInternships,
                description,
                purpose,
                process } = Employers[0];

            this.initialValues = {
                legalBusinessName: legalBusinessName,
                doingBusinessAs: doingBusinessAs,
                under500Employees: String(under500Employees),
                legalStatus: String(legalStatus),
                registeredBusiness: String(registeredBusiness),
                locatedIn: locatedIn,
                registrationProof: String(registrationProof),
                hostInternshipCapacity: String(hostInternshipCapacity),
                provideTraining: String(provideTraining),
                internshipDespiteLockdown: String(internshipDespiteLockdown),
                postSecondaryGraduate: String(postSecondaryGraduate),
                employerType: String(employerType),
                businessNumber: businessNumber,
                contactName: contactName,
                address: address,
                workAddress: workAddress,
                contactPhone: contactPhone,
                contactEmail: contactEmail,
                recruitUnderrepresented: String(recruitUnderrepresented),
                previousParticipation: String(previousParticipation),
                numInternships: numInternships,
                description: description,
                purpose: purpose,
                process: process
            }
        }

        this.onSubmit = this.onSubmit.bind(this);
    }
    
    componentDidMount() {
        if (this.isAdminPage) {
            this.applicationId = this.props.application.id
        } else {
            this.applicationId = this.props.location.state?.applicationId;
        }
        
        
        if (this.applicationId !== undefined){
            axios.get("/Applications/" + this.applicationId).then((response) => {

                let data = response.data?.Employers[0];

                this.initialValues = {
                    legalBusinessName: data.legalBusinessName,
                    doingBusinessAs: data.doingBusinessAs,
                    under500Employees: String(data.under500Employees),
                    legalStatus: String(data.legalStatus),
                    registeredBusiness: String(data.registeredBusiness),
                    locatedIn: data.locatedIn,
                    registrationProof: String(data.registrationProof),
                    hostInternshipCapacity: String(data.hostInternshipCapacity),
                    provideTraining: String(data.provideTraining),
                    internshipDespiteLockdown: String(data.internshipDespiteLockdown),
                    postSecondaryGraduate: String(data.postSecondaryGraduate),
                    employerType: String(data.employerType),
                    businessNumber: data.businessNumber,
                    contactName: data.contactName,
                    address: data.address,
                    workAddress: data.workAddress,
                    contactPhone: data.contactPhone,
                    contactEmail: data.contactEmail,
                    recruitUnderrepresented: String(data.recruitUnderrepresented),
                    previousParticipation: String(data.previousParticipation),
                    numInternships: data.numInternships,
                    description: data.description,
                    purpose: data.purpose,
                    process: data.process
                }

                this.setState({redirect: false, initialValues: this.initialValues})                   
            }); 
        }
    }

    onSubmit( data: any ) {
        if (this.isAdminPage) {
            if (window.confirm("Click 'OK' to save your edits")) {
                this.submitForm(data)
            }
        } else {
            this.submitForm(data)
        }
    }

    submitForm(data: any) {
        window.sessionStorage.setItem('email', data.contactEmail);
        
        const applicationData = {
            "withinTerritories": this.props.isWithin
        };

        if (this.applicationId > 0){
            data.applicationId = this.applicationId;
            axios.post("/employers/update/"+this.applicationId, data).then((response) => {
                if (this.isAdminPage) {
                    this.setState({edit: false, disabled: true})
                } else {
                    this.setState({redirect: true});
                }
            });
        } else {
            axios.post("/applications", applicationData).then((response) => {
                this.applicationId = response.data.id;
                data.applicationId = this.applicationId;
    
                axios.post("/employers", data).then((response) => {
                    this.setState({redirect: true});
                });
            });
        }
    }

    handleEdit() {
        if (!this.state.edit) {
            this.setState({edit: true, disabled: false})
        } else {
            if (window.confirm("Cancel Edit?")) {
                this.setState({edit: false, disabled: true, formKey: Math.random()})
            }
        }
    }
    
    render() {
        if (this.state.redirect) {
            return <Redirect to = 
                {{
                    pathname: this.props.isWithin? '/application/within/proposedInternship': '/application/outside/proposedInternship',
                    state: { applicationId: this.applicationId}
                }} 
            />
        }
        return (
            <div>
                <Formik 
                    enableReinitialize={true} 
                    initialValues={this.initialValues} 
                    onSubmit={this.onSubmit} 
                    key={this.state.formKey}
                    validationSchema={this.validationSchema}
                >{({ values, handleChange, handleBlur, errors }) => (
                    <Form>
                    <FormHeader subtitle={'Internship Host Organization (IHO) Application'} subtitle2={'('+this.titleText+' territories)'}/>
                    <BlueHeaderSeparator title={"Employer Information"}/>
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <div className="sub-form-sub-container" style={{paddingBottom:0}}>
                                <FormInput label={"1. Legal Business Name"} name={'legalBusinessName'} disabled={this.state.disabled} />
                                <FormInput label={"2. Doing Business As"} name={'doingBusinessAs'} disabled={this.state.disabled} />
                            </div>
                            <RadioQuestion text={"3. Does your company have under 500 employees?"} name={"under500Employees"} disabled={this.state.disabled}/>
                            <RadioQuestion text={"4. Does your company have legal status in Canada?"} name={"legalStatus"} disabled={this.state.disabled}/>
                            <div className="margin-item">
                                <span>5. Is your company a registered business?</span>
                            </div>
                            <div style={{marginLeft:"30px"}}>
                                <RadioQuestion text={"a) I am a registered business"} name={"registeredBusiness"} disabled={this.state.disabled}/>
                                <div className="radio-input">
                                    <span>b) I am located in (province/territory)</span>
                                    <select
                                    name="locatedIn"
                                    value={values.locatedIn}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ display: 'block', width:"250px"}}
                                    disabled={this.state.disabled}
                                    >
                                    <option value="AB" label="Alberta" />
                                    <option value="BC" label="British Columbia" />
                                    <option value="MB" label="Manitoba" />
                                    <option value="NB" label="New Brunswick" />
                                    <option value="NL" label="Newfoundland and Labrador" />
                                    <option value="NT" label="Northwest Territories" />
                                    <option value="NS" label="Nova Scotia" />
                                    <option value="NU" label="Nunavut" />
                                    <option value="ON" label="Ontario" />
                                    <option value="PE" label="Prince Edward Island" />
                                    <option value="QC" label="Quebec" />
                                    <option value="SK" label="Saskatchewan" />
                                    <option value="YT" label="Yukon" />
                                    </select>
                                    <ErrorMessage name='locatedIn' component="div" className='error-msg'/>
                                </div>
                                <RadioQuestion text={"c) I can provide a proof of registration if asked"} name={"registrationProof"} disabled={this.state.disabled}/>
                            </div>
                            <RadioQuestion text={"6. Does your company have capacity to host internships?"} name={"hostInternshipCapacity"} disabled={this.state.disabled}/>
                            <RadioQuestion text={"7. Does your company have the capacity to provide intern training and support?"} name={"provideTraining"} disabled={this.state.disabled}/>
                            
                            {this.props.isWithin
                                ?<RadioQuestion text={"8. If there is another lockdown due to COVID-19, can the internship(s) still be performed?"} name={"internshipDespiteLockdown"} disabled={this.state.disabled}/>
                                :<RadioQuestion text={"8. Will the intern be a post-secondary graduate by the start of internship?"} name={"postSecondaryGraduate"} disabled={this.state.disabled}/>
                            }

                            <RadioQuestion text={"9. Employer type:"} name={"employerType"} option1={"Profit"} option2={"Not for Profit"} disabled={this.state.disabled}/>
                            <div className="sub-form-sub-container">
                                <FormInput label={"10. Business Number"} name={"businessNumber"} disabled={this.state.disabled} />
                                <FormInput label={"11. Contact Name"} name={"contactName"} disabled={this.state.disabled} />
                            </div>
                            <div className="sub-form-sub-container print-break-EI-12">
                                <FormInput label={"12. Address"} name={"address"} disabled={this.state.disabled} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"13. Address where work is being performed (if different)"} name={"workAddress"} disabled={this.state.disabled} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"14. Contact Telephone"} name={"contactPhone"} disabled={this.state.disabled} />
                                <FormInput label={"15. Contact Email"} name={"contactEmail"} disabled={this.state.disabled} />
                            </div>
                            <div className="margin-item">
                                16. The applicant will, upon approval as an IHO, endeavour to recruit qualified 
                                interns from the following underrepresented groups: Indigenous people/official 
                                languages minorities/persons with disabilities/visible minorities/women
                            </div>
                            <RadioQuestion text={""} name={"recruitUnderrepresented"} option1={"Yes"} option2={"No"} disabled={this.state.disabled}/>
                            {this.props.isWithin &&
                                <>
                                <div className="margin-item">
                                    17. Have any of the interns previously participated in DS4Y, either with 
                                    Pinnguaq or another delivery organization?
                                </div>
                                <RadioQuestion text={""} name={"previousParticipation"} option1={"Yes"} option2={"No"} disabled={this.state.disabled}/>
                                </>
                            }
                            <div className="radio-input">
                                <span>{this.questionNumber}. Number of internships applied for</span>
                                
                                <Field 
                                    type="number" 
                                    name = 'numInternships'
                                    style={{width:"250px"}}
                                    disabled={this.state.disabled}
                                />
                                <ErrorMessage name='numInternships' component="div" className='error-msg'/>
                            </div>
                            
                            <div className="margin-item print-break-EI-19">
                                {this.questionNumber+1}. Brief history and description of organization (including mandate)
                            </div>
                            <div className='print-textarea'>{values.description}</div>
                            <TextArea name={'description'} disabled={this.state.disabled} />

                            <div className="margin-item">
                                {this.questionNumber+2}. Purpose of funding request
                            </div>
                            <div className='print-textarea'>{values.purpose}</div>
                            <TextArea name={'purpose'} disabled={this.state.disabled}/>


                            <div className="margin-item">
                                {this.questionNumber+3}. Brief description of recruitment and hiring process for interns
                            </div>
                            <div className='print-textarea'>{values.process}</div>
                            <TextArea name={'process'} disabled={this.state.disabled}/>
                        </div>
                    </div>
                    
                    <EmployeeInfoErrorList errors={errors} />

                    {
                        !this.props.isAdmin &&
                        <div className="submit-btn-wrapper">
                            <Link to='/welcome/application'>
                                <OutlineButton text={"Previous: Employer Location"}/>
                            </Link>
                            <SolidButton type={ButtonTypes.submit} text={"Next: Proposed Internship"}/>
                        </div>
                    }

                    {
                        this.isAdminPage && this.state.edit ?
                        <div className='edit-btn-container'>
                            <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                            <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                        </div>
                        : null
                    }
                    </Form>
                )}</Formik>

                {
                    this.isAdminPage && !this.state.edit ?
                    <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                    : null
                }
            </div>
        );
    }
}

export default withRouter(EmployerInformationForm);