import React from 'react';
import './selectTerritory.css';
import SolidButton from '../../../components/SolidButton/SolidButton';
import Title from '../../../components/Title/Title';
import { Link } from 'react-router-dom';

export default class SelectTerritory extends React.Component {
    render() {
      return (
          <div className = "inner-container select-territory">
              <Title/>
              <div>Where is your business located?</div>
              <div className = "btn-container">
                <Link to="/application/within/employerInformation"><SolidButton text={"Within Nunavut, Northwest Territories, Yukon"}/></Link>
                <Link to="/application/outside/employerInformation"><SolidButton text={"Outside Nunavut, Northwest Territories, Yukon"}/></Link>
              </div>
          </div>
      );
    }
}