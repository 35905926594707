import React from 'react';
import './adminLogin.css';
import { TiLockClosed, TiMail } from 'react-icons/ti';
import { Link, withRouter } from "react-router-dom";
import { signIn, auth, resetPassword } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import SolidButton from '../../../components/SolidButton/SolidButton';
import Title from '../../../components/Title/Title';
import axios from "../../../http-common";

interface State{
    email: string
    password: string
    formErrors: { [name: string]: string }
    emailValid: boolean
    passwordValid: boolean
    forgotForm: boolean
    forgotEmail: string
}
class AdminLogin extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.setPassword = this.setPassword.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setForgotEmail = this.setForgotEmail.bind(this);
        this.state = {
            email: '',
            password: '',
            formErrors: { email: "" },
            emailValid: false,
            passwordValid: false,
            forgotForm: false,
            forgotEmail: '',
        };
    }

    setPassword(e: { target: { value: string; }; }) {
        this.setState({ password: e.target.value });
    }

    setEmail(e: { target: { value: string; }; }) {
        this.setState({ email: e.target.value });
    }

    setForgotEmail(e: { target: { value: string; }; }) {
        this.setState({forgotEmail: e.target.value})
    }

    async login() {
        // TODO: email and password field validation
        await signIn(this.state.email, this.state.password);
    }

    async loginKey(e: any) {
        // TODO: email and password field validation
        if (e.key === "Enter") {
            await signIn(this.state.email, this.state.password);
        }
    }

    handleReset() {
        resetPassword(this.state.forgotEmail)
        this.setState({forgotForm: false})
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            if (auth.currentUser != null) {
                axios.get("/users/" + auth.currentUser?.uid).then((response) => {
                    if (response.data && response.data.isAdmin) {
                        this.props.history.push({
                            pathname: '/admin/applications',
                            state: { userFk: response.data.userId }
                        });
                    }
                });
            }
        });
    }

    render() {
        return (
            <div className="inner-container login">
                <Title />

                <div className="login-container">
                {
                this.state.forgotForm ?
                <div className="forgot-container">
                    <div className="forgot-icon">
                        <TiMail className="icon"/>
                        <input type="text" onChange={this.setForgotEmail}/>
                    </div>

                    <div className='forgot-button' onClick={() => this.handleReset()}><SolidButton text={"Send Email"} /></div>
                </div>
                :
                <>
                <div>Administrator Login</div>
                <form className="login-form" onKeyDown={(e) => this.loginKey(e)} >
                    <div className="icon-input"><TiMail className="icon" /><input type="text" onChange={this.setEmail} /></div>

                    <div className="icon-input">
                        <TiLockClosed className="icon" />
                        <input type="password" onChange={this.setPassword} />
                    </div>
                </form>

                <Link to="/admin" onClick={() => this.login()}><SolidButton text={"Login"} /></Link>
                </>
                }

                {
                    this.state.forgotForm ? null :
                    <span className="link"><a onClick={() => this.setState({forgotForm: true})}>Forgot Password?</a></span>
                }
                </div>
            </div>
        );
    }
}
export default withRouter(AdminLogin);