import React from 'react';
import './BlueHeaderSeparator.css'

interface Props{
    title: string
    subtitle?: string
}
export default class BlueHeaderSeparator extends React.Component<Props> {
    render() {
      return (
        <div className={this.props.subtitle === 'IHO Claim Summary and/or Advance Claim' ? "blue-header-separator print-header-IHO" : "blue-header-separator print-header"}>
            <span>{this.props.title}</span>
            <span>{this.props.subtitle}</span>
        </div>
      );
    }
}