import React from 'react';
import { TiPlus } from 'react-icons/ti'
import './AddRow.css'

interface Props{
    text: string
}

export default class AddRow extends React.Component<Props> {
    render() {
      return (
          <div className="add-row">
              <TiPlus className="add-row-icon"/>
              <span>Add another row for this {this.props.text}</span>
          </div>
      );
    }
}