import React, { useEffect, useState } from 'react';
import '../subForm.css';
import Separator from '../../../../components/Separator/Separator'
import FormInput from '../../../../components/FormInput/FormInput'
import { TrainingTable, TrainingTotalTable } from '../../../index'
import AddAnother from '../../../../components/AddAnother/AddAnother';
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';

export default function SubFormTraining(props: any) {
  useEffect(() => {
    if (_.isEqual(initialValues, values.internForms[0])) {
      setIsEqual(true)
    }
  }, [])

  const initialValuesTraining = {
    provider: '',
    type: '',
    startDate: '',
    invoiceDate: '',
    invoiceNum: 0,
    invoiceAmount: 0,
    tax: 0,
    gstHst: 0,
    totalClaimed: 0
  }

  const initialValues = {
    name: '',
    training: [initialValuesTraining] 
  }
  
  let values: any = useFormikContext().values;
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [gstHst, setGstHst] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [isEqual, setIsEqual] = useState(false);
  const [enable, setEnable] = useState(false);
  const [isEmpty, setIsEmpty] = useState(window.sessionStorage.getItem("emptyTraining") === "true" ? true : false)

  if (isEqual && props.isAdminPage && !enable || isEmpty) {
    values.internForms = []
  }

  const totalValues = {
    invoiceAmount: invoiceAmount,
    tax: tax,
    gstHst: gstHst,
    totalClaimed: totalClaimed
  }

  const updateValues = (values: any) => {
    setInvoiceAmount(values.invoiceAmount)
    setTax(values.tax)
    setGstHst(values.gstHst)
    setTotalClaimed(values.totalClaimed)
  }

  const addNewRow = (helper: any) => {
    setEnable(true)
    setIsEmpty(false)
    helper.push(initialValues)
  }

  return(
    <div className='scroll-container advance-training-print-break'>
      <div className="sub-forms-container">
        <div className="sub-form">
        <FieldArray
            name="internForms"
            render={arrayHelpers => (
            <>
            <h1 style={{textTransform:"none"}}>
                TRAINING 
                (Note: This is for actual training invoices only. For other costs related to training, see below)
            </h1>
            {
              values.internForms.length === 0 ? null : 
              <div className="sub-form">
              {
                values.internForms.map((item: any, index: number) => (
                  <div className="sub-form-sub-container-wrapper" key={index}>
                    { !props.isAdmin &&
                        <div onClick={() => { arrayHelpers.remove(index) }}>
                            <RemoveItem/>
                        </div>
                    }
                    <div className="sub-form-sub-container">
                      <FormInput label={"Intern's Name"} name={`internForms.${index}.name`} disabled={props.isAdmin} />
                    </div>
                    <TrainingTable text={props.text} internIndex={index} updateValues={updateValues} isAdmin={props.isAdmin}/>
                    <div style={{marginTop:"20px", marginLeft:"-30px", marginRight:"-30px"}}><Separator/></div>
                  </div>
                ))
              } 
              </div>
            }

            {
              !props.isAdmin &&
                <div style={{ marginBottom: "30px" }} onClick={() => addNewRow(arrayHelpers)}>
                  <AddAnother text={"intern"} />
                </div>
            }
            </>
          )}/>
          <TrainingTotalTable text={props.text} values={totalValues}/>
        </div>
      </div>
    </div>
  );
}