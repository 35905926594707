import React from 'react';
import './myFormBrowseClaims.css';
import { withRouter } from "react-router-dom";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import axios from "../../http-common";
import MyFormHeader from '../../components/MyFormHeader/MyFormHeader';
import UserMenu from '../../components/UserMenu/UserMenu';

interface State{
    claimAdvances: Array<any>,
    userId: any
}

class myFormBrowseClaims extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            claimAdvances: [],
            userId: null
        };
    }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                this.props.history.push("/myforms");
            } else {
                if (auth.currentUser) {
                    axios.get("/users/" + auth.currentUser.uid).then((response) => {
                        const userFk = response.data.userId;
                        this.setState({userId: userFk})
        
                        axios.get("/claimAdvances").then((response) => {
                            let data : any = [];
                            response.data.filter((claimAdvance: { userId: number, type: string; }) => {
                                if (claimAdvance.type === "C" || claimAdvance.type === "CA") {
                                    if (claimAdvance.userId === userFk) {
                                        data.push(claimAdvance)
                                    }
                                }
                            })
        
                            this.setState({ claimAdvances: data});
                        });
                    })
                }
            }
        });
    }

    renderTableHeader() {
        return (
            <tr>
                <th>Date</th>
                <th>Project Start Date</th>
                <th>Project Completion Date</th>
                <th>Attached Paystubs</th>
                <th>Attached Invoices</th>
                <th>Organization</th>
                <th>Contact</th>
                <th>Submitted By</th>
            </tr>
        );
    }

    onClaimSelected(id: number) {
        this.props.history.push("/myforms/claim/" + id);
    }

    renderTableData() {
        return this.state.claimAdvances.map((claimAdvance, index) => {
            const { claimAdvanceId, createdAt, type, Attachments, User, Information } = claimAdvance;

            if (Information === null || Information.length === 0) {
                return;
            }

            const dateString = (new Date(createdAt).toDateString());

            let email = "";
            if (User != null) email = User.email;

            const { projectStartDate, projectCompletionDate, organizationName, contactName } = Information[0];

            let numPaystubs = 0;
            let numInvoices = 0;
            Attachments.forEach((attachment: any) => {
                if (attachment.isPaystub) {
                    numPaystubs++;
                } else {
                    numInvoices++;
                }
            });

            const startDateString = (new Date(projectStartDate).toDateString());
            const endDateString = (new Date(projectCompletionDate).toDateString());

            return (
                <tr key={claimAdvanceId} onClick={() => this.onClaimSelected(claimAdvanceId)}>
                    <td>{dateString}</td>
                    <td>{startDateString}</td>
                    <td>{endDateString}</td>
                    <td>{numPaystubs}</td>
                    <td>{numInvoices}</td>
                    <td>{organizationName}</td>
                    <td>{contactName}</td>
                    <td>{email}</td>
                </tr>
            );
        });
    }

    navigateHome = () => {
        this.props.history.push({pathname: '/welcome'});
    }

    render() {
        if (auth.currentUser == null) {
            return (<div />);
        } else {
            return (
                <>
                    <div className="home-btn" onClick={() => this.navigateHome()}>Home</div>
                    <UserMenu email={auth.currentUser.email!} />
                    <MyFormHeader currentTab={0} />

                    <div className="inner-container browse">
                        <table id='applications'>
                            <tbody>
                                {this.renderTableHeader()}
                                {this.renderTableData()}
                            </tbody>
                        </table>
                    </div>
                </>
            );
        }
    }
}

export default withRouter(myFormBrowseClaims);