import React from 'react';
import FormInput from '../../../../components/FormInput/FormInput';
import '../subForm.css';

export default function SubFormTotalEligibleCosts(props: any) {
    return (
        <div className='scroll-container'>
            <div className="sub-forms-container">
                <div className="sub-form">
                    <h1>Total Eligible Costs</h1>
                    <div className="sub-form">
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Total Eligible Costs (as per 'Detailed Claim Sheet')"} type={'number'} name={`totalEligibleCosts`} disabled={props.isAdmin} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}