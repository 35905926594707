import React from 'react';
import './otherTotalTable.css';
import '../table.css';
import NumberFormat from "react-number-format";

interface Props{
    text: string
    totalClaimed: number
}

interface State{
    totalClaimed: number
}

export default class OtherTotalTable extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            totalClaimed: 0
        };

        this.setState({
            totalClaimed: this.props.totalClaimed
        })
    }

    render() {
        return (
            <>
                <div className="custom-table travel-total-table">
                    <div className="custom-table-cell header"></div>
                    <div className="custom-table-cell header">Total {this.props.text} Amount</div>

                    <div className="custom-table-cell">Total</div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.totalClaimed} />
                    </div>
                </div>
            </>
        );
    }
}