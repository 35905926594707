import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import './App.css'
import SelectType from './views/forms/selectType/selectType';
import {
    Intro, Form, ClaimForm, AdvanceForm, ACStepTwoForm, CompleteForm,
    EmployerInformationForm, ProposedInternshipForm, TrainingPlanForm, DeclarationForm,
    AdminLogin, AdminBrowseApplications, AdminBrowseClaims, AdminBrowseAdvances,
    AdminBrowseUsers, AdminApplication, AdminClaim, AdminAdvance, myFormBrowseAdvances, myFormBrowseClaims
} from './views/index'
import './Print.css';

class App extends React.Component {
  render() {
    return (
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/welcome"/>
              </Route>
              <Route path="/welcome" component={Intro}/>
              <Route exact path="/claims" render={(props) => <SelectType {...props}/>}/>
              <Route exact path="/claims/claimStepOne" render={(props) => <ClaimForm {...props}/>}/>
              <Route exact path="/claims/advanceStepOne" render={(props) => <AdvanceForm {...props}/>}/>
              <Route exact path="/claims/stepTwo" render={(props) => <ACStepTwoForm {...props}/>}/>
              <Route exact path="/claims/complete">
                <CompleteForm text={"Thank you for submitting your claim and/or advance"}/>
              </Route>
              <Route exact path="/application/within/employerInformation">
                <Form component={<EmployerInformationForm isWithin={true}/>}/>
              </Route>
              <Route exact path="/application/outside/employerInformation">
                <Form component={<EmployerInformationForm isWithin={false}/>}/>
              </Route>
              <Route exact path="/application/within/proposedInternship">
                <Form component={<ProposedInternshipForm isWithin={true}/>}/>
              </Route>
              <Route exact path="/application/outside/proposedInternship">
                <Form component={<ProposedInternshipForm isWithin={false}/>}/>
              </Route>
              <Route exact path="/application/within/trainingPlan">
                <Form component={<TrainingPlanForm isWithin={true}/>}/>
              </Route>
              <Route exact path="/application/outside/trainingPlan">
                <Form component={<TrainingPlanForm isWithin={false}/>}/>
              </Route>
              <Route exact path="/application/within/declaration">
                <Form component={<DeclarationForm isWithin={true}/>}/>
              </Route>
              <Route exact path="/application/outside/declaration">
                <Form component={<DeclarationForm isWithin={false}/>}/>
              </Route>
              <Route exact path="/application/complete">
                <CompleteForm text={"Thank you for applying to the Digital Skills for Youth program!"}/>
              </Route>
              
              <Route exact path="/admin" component={AdminLogin}/>
              <Route exact path="/admin/applications" component={AdminBrowseApplications}/>
              <Route exact path="/admin/claims" component={AdminBrowseClaims}/>
              <Route exact path="/admin/advances" component={AdminBrowseAdvances}/>
              <Route exact path="/admin/users" component={AdminBrowseUsers}/>
              <Route exact path="/admin/application/:applicationId" component={AdminApplication}/>
              <Route exact path="/admin/claim/:claimAdvanceId" component={AdminClaim}/>
              <Route exact path="/admin/advance/:claimAdvanceId" component={AdminAdvance}/>
              <Route exact path="/myforms/claims" component={myFormBrowseClaims} />
              <Route exact path="/myforms/advances" component={myFormBrowseAdvances} />
              <Route exact path="/myforms/claim/:claimAdvanceId" component={AdminClaim}/>
              <Route exact path="/myforms/advance/:claimAdvanceId" component={AdminAdvance}/>
            </Switch>
          </Router>
        </div>
    );
  }
}

export default App;
