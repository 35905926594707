import { Field, FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import AddRow from '../../../../components/AddRow/AddRow';
import './trainingTable.css'
import NumberFormat from "react-number-format";
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';

export default function TrainingTable(props:any) {
    useEffect(() => {
        initializeValues()
    })

    const initialValuesTraining = {
        provider: '',
        type: '',
        startDate: '',
        invoiceDate: '',
        invoiceNum: 0,
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalClaimed: 0
    }

    const values: any = useFormikContext().values;
    const handleChange: any = useFormikContext().handleChange;

    const totalValues = useRef({
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalClaimed: 0
    })

    const updateInvoiceAmount = () => {
        let total = 0;
        const interns = values.internForms;
        
        if (interns) {
            interns.forEach((element: any) => {
                const training = element.training;
                if (training) {
                    training.forEach((element: any) => {
                        const invoiceAmount = element.invoiceAmount ? parseFloat(element.invoiceAmount) : 0;
                        total+=invoiceAmount;
                    });
                }
            });
        }

        totalValues.current.invoiceAmount = total;
        props.updateValues(totalValues.current);
    }

    const updateTax = () => {
        let total = 0;
        const interns = values.internForms;
            interns.forEach((element: any) => {
                const training = element.training;
                if (training) {
                    training.forEach((element:any) => {
                        const tax = element.tax ? parseFloat(element.tax) : 0;
                        total+=tax;
                    });
                }
                
            });
            
            totalValues.current.tax = total;
            props.updateValues(totalValues.current);
    }

    const updateGstHst = () => {
        let total = 0;
        const interns = values.internForms;
        interns.forEach((element: any) => {
            const training = element.training;
            if (training) {
                training.forEach((element:any) => {
                    const gstHst = element.gstHst ? parseFloat(element.gstHst) : 0;
                    total+=gstHst;
                });
            }
        });
        totalValues.current.gstHst = total;
        props.updateValues(totalValues.current);
    }

    const updateTotalClaimed = () => {
        let total = 0;
        const interns = values.internForms;
        interns.forEach((element: any) => {
            const training = element.training;
            if (training) {
                training.forEach((element:any) => {
                    const totalClaimed = element.totalClaimed ? parseFloat(element.totalClaimed) : 0;
                    total+=totalClaimed;
                });
            }
        });
        totalValues.current.totalClaimed = total;
        props.updateValues(totalValues.current);
    }

    const initializeValues = () =>{
        updateInvoiceAmount();
        updateTax();
        updateGstHst();
        updateTotalClaimed();
    }

    return (
        <FieldArray
            name={`internForms.${props.internIndex}.training`}
            render={arrayHelpers => (
            <>
            <div className="custom-table training-table">
                <div className="custom-table-cell header">Training Provider</div>
                <div className="custom-table-cell header">Type of Training (Description of Training)</div>
                <div className="custom-table-cell header">Training Start Date</div>
                <div className="custom-table-cell header">Invoice Date</div>
                <div className="custom-table-cell header">Invoice Ref. No.</div>
                <div className="custom-table-cell header">Invoice Amount Before Taxes</div>
                <div className="custom-table-cell header">Provincial Tax (after Rebate)</div>
                <div className="custom-table-cell header">GST/HST (after Rebate - 50%)</div>
                <div className="custom-table-cell header">Total {props.text} Amount</div>
                <div className="custom-table-cell header remove" />
 
                {
                    values.internForms[props.internIndex].training &&
                    values.internForms[props.internIndex].training.map((item: any, index: number) => (
                        <>

                        <div className="custom-table-cell">
                            <Field type="text" name={`internForms.${props.internIndex}.training.${index}.provider`} disabled={props.isAdmin}/>
                        </div>
                        <div className="custom-table-cell">
                            <Field type="text" name={`internForms.${props.internIndex}.training.${index}.type`} disabled={props.isAdmin}/>
                        </div>
                        <div className="custom-table-cell">
                            <Field type="date" max={'9999-12-31'} name={`internForms.${props.internIndex}.training.${index}.startDate`} disabled={props.isAdmin}/>
                        </div>
                        <div className="custom-table-cell">
                            <Field type="date" max={'9999-12-31'} name={`internForms.${props.internIndex}.training.${index}.invoiceDate`} disabled={props.isAdmin}/>
                        </div>
                        <div className="custom-table-cell">
                            <Field type="text" name={`internForms.${props.internIndex}.training.${index}.invoiceNum`} disabled={props.isAdmin}/>
                        </div>
                        <div className="custom-table-cell">
                            <span>$</span>
                            <NumberFormat
                                className="fill"
                                displayType={props.isAdmin ? "text" : "input"}
                                name={`internForms.${props.internIndex}.training.${index}.invoiceAmount`}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                    updateInvoiceAmount()
                                }}
                                value={item.invoiceAmount}
                                disabled={props.isAdmin} />
                        </div>
                        <div className="custom-table-cell">
                            <span>$</span>
                            <NumberFormat
                                className="fill"
                                displayType={props.isAdmin ? "text" : "input"}
                                name={`internForms.${props.internIndex}.training.${index}.tax`}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                    updateTax()
                                }}
                                value={item.tax}
                                disabled={props.isAdmin} />
                        </div>
                        <div className="custom-table-cell">
                            <span>$</span>
                            <NumberFormat
                                className="fill"
                                displayType={props.isAdmin ? "text" : "input"}
                                name={`internForms.${props.internIndex}.training.${index}.gstHst`}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                    updateGstHst()
                                }}
                                value={item.gstHst}
                                disabled={props.isAdmin} />
                        </div>
                        <div className="custom-table-cell">
                            <span>$</span>
                            <NumberFormat
                                className="fill"
                                displayType={props.isAdmin ? "text" : "input"}
                                name={`internForms.${props.internIndex}.training.${index}.totalClaimed`}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                    updateTotalClaimed()
                                }}
                                value={item.totalClaimed}
                                disabled={props.isAdmin} />
                        </div>
                        
                        <div className="custom-table-cell header remove">
                            <div hidden={index === 0 || props.isAdmin} onClick={() => {arrayHelpers.remove(index)}}>
                                <RemoveItem/>
                            </div>   
                        </div>

                        </>
                    ))
                }
            </div>
            

            { !props.isAdmin &&
                <div onClick={() => {
                        arrayHelpers.push(initialValuesTraining)
                    }
                }>
                    <AddRow text={"intern"}/>
                </div>
            }

            </>
        )}/>
    );
}