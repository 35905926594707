import React from 'react';
import _ from 'lodash';
import './ErrorList.css'

interface Props {
    errors: any
}

export default function DeclarationErrorList(props: Props) {
    const {
        date,
        employerName,
        employerSignature,
        employerTitle
    } = props.errors
    
    return (
        <div>
            {_.isEmpty(props.errors) ? null : "The below fields are Missing or are filled out incorrectly"} 
            {employerName && <div className='err-msg'>Employer Name</div>}
            {employerTitle && <div className='err-msg'>Employer Title</div>}
            {employerSignature && <div className='err-msg'>Employer Signature</div>}
            {date && <div className='err-msg'>Date</div>}
        </div>
    )

}