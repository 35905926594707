import React from 'react';
import _ from 'lodash';
import './ErrorList.css'

interface Props {
    errors: any
}

export default function TrainingPlanErrorList(props: Props) {
    return (
        <div>
            {_.isEmpty(props.errors) ? null : "The below fields are Missing or are filled out incorrectly"} 
            {_.isEmpty(props.errors) ? null : props.errors.forms[0].trainingPlan && <div className='err-msg'>2. Description of training plan</div>}
            {_.isEmpty(props.errors) ? null : props.errors.forms[0].amount && <div className='err-msg'>3. Amount of training per intern</div>}
        </div>
    )

}