import React from 'react';
import _ from 'lodash';
import './ErrorList.css'

interface Props {
    errors: any
}

export default function ClaimAdvanceSummaryErrorList(props: Props) {
    const {
        certification,
        claim,
        information
    } = props.errors
    
    return (
        <>
            {_.isEmpty(props.errors) ? null : "The below fields are Missing or are filled out incorrectly"} 

            <div className='error-container'>
                {
                    information &&
                    <div>
                        {information && <div className='err-msg-header'>Information Form</div>}
                        {information && information.projectStartDate && <div className='err-msg'>Project Start Date</div>}
                        {information && information.projectCompletionDate && <div className='err-msg'>Project Completion Date</div>}
                        {information && information.organizationName && <div className='err-msg'>Name of Organization</div>}
                        {information && information.address && <div className='err-msg'>Address, Street, Unit Number, etc.</div>}
                        {information && information.city && <div className='err-msg'>City</div>}
                        {information && information.postalCode && <div className='err-msg'>Postal Code</div>}
                        {information && information.contactName && <div className='err-msg'>Contact Name</div>}
                        {information && information.phoneNumber && <div className='err-msg'>Telephone Number</div>}
                        {information && information.email && <div className='err-msg'>Contact Email</div>}
                    </div>
                }
                {
                    claim &&
                    <div>
                        {claim && <div className='err-msg-header'>Claim form</div>}
                        {claim && claim.periodStartDate && <div className='err-msg'>Claim Period Start Date</div>}
                        {claim && claim.periodEndDate && <div className='err-msg'>Claim Period End Date</div>}
                    </div>
                }
                {
                    certification &&
                    <div>
                        {certification && <div className='err-msg-header'>Certification form</div>}
                        {certification && certification.signature && <div className='err-msg'>Signature of Authorized Offical</div>}
                        {certification && certification.name && <div className='err-msg'>Name</div>}
                        {certification && certification.date && <div className='err-msg'>Date</div>}
                        {certification && certification.title && <div className='err-msg'>Title</div>}
                    </div>
                }
            </div>
        </>
    )

}