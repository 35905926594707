import React from 'react';
import './adminBrowseUsers.css';
import { withRouter } from "react-router-dom";
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import axios from "../../../http-common";
import AdminHeader from '../../../components/AdminHeader/AdminHeader';
import UserMenu from '../../../components/UserMenu/UserMenu';

interface State{
    users: Array<any>,
    isAdmin: boolean,
}

class AdminBrowseUsers extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            users: [],
            isAdmin: false,
        };

        axios.get("/users").then((response) => {
            this.setState({ users: response.data });
        });
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                this.props.history.push("/admin");
            } else {
                axios.get("/users/" + auth.currentUser?.uid).then((response) => {
                    this.setState({ isAdmin: response.data != null && response.data.isAdmin });
                });
            }
        });
    }

    renderTableHeader() {
        if (this.state.users.length <= 0) {
            return null;
        }

        return (
            <tr>
                <th>Id</th>
                <th>Creation Date</th>
                <th>Email</th>
                <th>Approved</th>
                <th>Admin</th>
            </tr>
        );
    }

    onUserSelected(id: number) {
        //this.props.history.push("/admin/user/" + id);
    }

    renderTableData() {
        return this.state.users.map((user, index) => {
            const { userId, createdAt, email, isAdmin, approved} = user;
            const dateString = (new Date(createdAt).toDateString());
            let value;
            
            const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                let tempObj = [...this.state.users]
                

                if (approved) {
                    value = false;
                } else {
                    value = true;
                }

                axios.post(`/users/update`, { userId, approved: value }).then((response) => {
                    let tempUser: any = tempObj.map((user: any, i: any) => {
                        if (user.email === response.data.email) {
                            if (user.approved) {
                                user.approved = false
                            } else {
                                user.approved = true
                            }
                        }

                        return {user, i}
                    })
                    
                    
                    let num: any = tempUser?.['i']
                    tempObj[num] = tempUser?.['user']

                    this.setState({users: tempObj})
                })

                
            }

            return (
                <tr key={userId} onClick={() => this.onUserSelected(userId)}>
                    <td>{userId}</td>
                    <td>{dateString}</td>
                    <td>{email}</td>
                    <td>
                        <input 
                            type="checkbox" 
                            onChange={handleChange}
                            defaultChecked={approved}
                        />
                        {approved ? " Yes" : " No"}
                    </td>
                    <td>{isAdmin ? "Yes" : "No"}</td>
                </tr>
            );
        });
    }

    render() {

        if (auth.currentUser == null) {
            return (<div />);
        }

        if (!this.state.isAdmin) {
            return (<div />);
        } else {
            return (
                <>
                    <UserMenu email={auth.currentUser.email!} />
                    <AdminHeader currentTab={3} />
                    <div className="inner-container browse">
                        <table id='applications'>
                            <tbody>
                                {this.renderTableHeader()}
                                {this.renderTableData()}
                            </tbody>
                        </table>
                    </div>
                </>
            );
        }
    }
}
export default withRouter(AdminBrowseUsers);