import React from 'react';
import './Title.css'

interface Props{
  text?: string
}

export default class Title extends React.Component<Props> {
    static defaultProps = {
      text: 'Welcome to the Digital Skills for Youth (DS4Y) Program'
    }
    render() {
      return (
            <div><h1><span>{this.props.text}</span></h1></div>
      );
    }
}