import React from 'react';
import './BigImage.css'

export default class BigImage extends React.Component {
    render() {
      return (
          <div className="blue-img">
            <div className="big-img"></div>
          </div>
      );
    }
}