import { Form, Formik } from 'formik';
import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { SubFormAdvances, SubFormCertification, SubFormClaim, SubFormInformation, SubFormTotalEligibleCosts, SubFormTotalRequested } from '../..';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import FormHeader from '../../../components/FormHeader/FormHeader';
import SolidButton from '../../../components/SolidButton/SolidButton';
import UserMenu from '../../../components/UserMenu/UserMenu';
import { auth, storage, ref } from '../../../firebase';
import { onAuthStateChanged } from "firebase/auth"
import * as Yup from "yup";
import axios from "../../../http-common";
import emailjs from "emailjs-com";
import { init } from 'emailjs-com';
import OutlineButton from '../../../components/OutlineButton/OutlineButton';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import ClaimAdvanceSummaryErrorList from '../../../components/ErrorList/ClaimAdvanceSummaryErrorList'

enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset"
} 

interface Props {
    isAdmin?: boolean
    claimAdvance?: any
    location: {
        state: any
    }
}

interface State {
    redirect: boolean
    edit: boolean
    disabled: boolean
    formKey: number
}

export default class ACStepTwoForm extends React.Component<any, State> {
    claimAdvanceId: number = 0;
    type: string = '';
    detailedJustification: string = '';
    isAdminPage: any = window.location.pathname.includes('/admin/') || window.location.pathname.includes('/myforms/');

    uploadsRemaining = 0;

    attachedInvoices: Array<File> = new Array<File>();
    attachedPaystubs: Array<File> = new Array<File>();

    initialValuesInformation = {
        projectStartDate: '',
        projectCompletionDate: '',
        organizationName: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        contactName: '',
        phoneNumber: '',
        email: ''
    }

    initialValuesClaim = {
        amountIncurred: 0,
        amountPaid: 0,
        quartersCovered: 0,
        periodStartDate: '',
        periodEndDate: ''
    }

    initialValuesAdvances = {
        quartersCovered: 0,
        periodStartDate: '',
        periodEndDate: ''
    }

    initialValuesCertification = {
        signature: '',
        name: '',
        date: '',
        title: ''
    }

    initialValues = {
        information: this.initialValuesInformation,
        claim: this.initialValuesClaim,
        totalEligibleCosts: 0,
        advances: this.initialValuesAdvances,
        totalRequested: 0,
        certification: this.initialValuesCertification
    }

    validationSchema = Yup.object().shape({
        information: Yup.object().shape({
            projectStartDate: Yup.date().required("*This field is required."),
            projectCompletionDate: Yup.date().required("*This field is required."),
            organizationName: Yup.string().required("*This field is required."),
            address: Yup.string().required("*This field is required."),
            city: Yup.string().required("*This field is required."),
            postalCode: Yup.string().required("*This field is required."),
            contactName: Yup.string().required("*This field is required."),
            phoneNumber: Yup.number().required("*This field is required.").positive("*Phone number is not valid.").integer("*Phone number is not valid."),
            email: Yup.string().email("*Email is not valid.").required("*This field is required.")
        }),
        claim: Yup.object().shape({
            amountIncurred: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
            amountPaid: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
            quartersCovered: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
            periodStartDate: Yup.date().required("*This field is required."),
            periodEndDate: Yup.date().required("*This field is required.")
        }),
        totalEligibleCosts: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
        advances: Yup.object().shape({
            quartersCovered: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
            periodStartDate: Yup.date(),
            periodEndDate: Yup.date()
        }),
        totalRequested: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
        certification: Yup.object().shape({
            signature: Yup.string().required("*This field is required."),
            name: Yup.string().required("*This field is required."),
            date: Yup.date().required("*This field is required."),
            title: Yup.string().required("*This field is required."),
        }),
    });

    constructor(props: any){
        super(props);
        // ClaimAdvanceId is not available (and not required) if isAdmin=true
        this.claimAdvanceId = this.props.isAdmin ? this.props.claimAdvance.claimAdvanceId : this.props.location.state.claimAdvanceId;

        if (!this.props.isAdmin) {
            this.type = this.props.location.state.type;
            if (this.type === 'A' || this.type === 'CA') {
                this.detailedJustification = this.props.location.state.detailedJustification;
            }

        } else {
            this.type = this.props.claimAdvance.type;
            if (this.type === 'A' || this.type === 'CA') {
                this.detailedJustification = this.props.claimAdvance.detailedJustification;
            }

            const { Information, Claims, Advances, Certifications, totalEligibleCosts, totalRequested } = this.props.claimAdvance;

            if (Information.length > 0) {
                this.initialValues.information = Information[0];
            }

            if (Claims.length > 0) {
                this.initialValues.claim = Claims[0];
            }

            if (Advances.length > 0) {
                this.initialValues.advances = Advances[0];
            }

            if (Certifications.length > 0) {
                this.initialValues.certification = Certifications[0];
            }

            this.initialValues.totalEligibleCosts = totalEligibleCosts;
            this.initialValues.totalRequested = totalRequested;
        }

        this.state = {
            redirect: false,
            edit: false, 
            disabled: this.isAdminPage ? true : false,
            formKey: 0
        }
        
        this.onSubmit = this.onSubmit.bind(this);
    }

    uploadAttachment(file: File, uid: string, timestamp: number, isPaystub: boolean) {
        // Upload each file to Firebase storage
        const directory = isPaystub ? '/paystubs/' : '/invoices/';
        const storageRef = ref(storage, uid + directory + timestamp + '_' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                // TODO: progress visualization
                //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
                // TODO: handle failed uploads
            },
            () => {
                // Handle successful upload
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // Add entry to database Attachments table
                    let attachment = {
                        claimAdvanceId: this.claimAdvanceId,
                        isPaystub: isPaystub,
                        path: downloadURL
                    }

                    axios.post("/attachments", attachment)
                });
            }
        );
    }
    
    onSubmit( data: any ) {
        sessionStorage.clear()
        
        if (this.isAdminPage) {
            if (window.confirm("Click 'OK' to save your edits")) {
                this.submitForm(data)
            }
        } else {
            this.submitForm(data)
        }
    }

    submitForm(data: any) {
        if (this.isAdminPage) {
            let deleteObj = {
                claimAdvanceId: this.claimAdvanceId
            }

            const claim = {
                claimAdvanceId: this.claimAdvanceId,
                totalEligibleCosts: data.totalEligibleCosts,
                totalRequested: data.totalRequested
            }
            
            axios.post("/claimAdvances/addTotals", claim)

            axios.put("/information/", deleteObj).then(() => {
                data.information.claimAdvanceId = this.claimAdvanceId;
                axios.post("/information/", data.information)
            })

            axios.put("/claims/", deleteObj).then(() => {
                data.claim.claimAdvanceId = this.claimAdvanceId;
                axios.post("/claims/", data.claim)
            })

            axios.put("/advances/", deleteObj).then(() => {
                data.advances.claimAdvanceId = this.claimAdvanceId;

                if (this.type === 'A' || this.type === 'CA'){
                    data.advances.detailedJustification = this.detailedJustification;
                }

                axios.post("/advances/", data.advances)
            })

            axios.put("/certifications/", deleteObj).then(() => {
                data.certification.claimAdvanceId = this.claimAdvanceId;
                axios.post("/certifications/", data.certification).then(()  => {
                
                    if (!this.isAdminPage) {
                        this.setState({ redirect: true });
                    } else {
                        this.setState({edit: false, disabled: true})
                    }
                });
            })
        } else {
            const claim = {
                claimAdvanceId: this.claimAdvanceId,
                totalEligibleCosts: data.totalEligibleCosts,
                totalRequested: data.totalRequested
            }
            
            axios.post("/claimAdvances/addTotals", claim)
    
            data.information.claimAdvanceId = this.claimAdvanceId;
            axios.post("/information/", data.information)
    
            data.claim.claimAdvanceId = this.claimAdvanceId;
            axios.post("/claims/", data.claim)
    
            data.advances.claimAdvanceId = this.claimAdvanceId;
            if(this.type === 'A' || this.type === 'CA'){
                data.advances.detailedJustification = this.detailedJustification;
            }
            axios.post("/advances/", data.advances)
    
            data.certification.claimAdvanceId = this.claimAdvanceId;
            axios.post("/certifications/", data.certification).then(async (response)  => {
                init("user_oPeZQ3GPWqFkyMWPuU0vN");
                let templateParams;

                if (this.type === 'A' || this.type === 'CA') {
                    templateParams = {
                        to_name: "DS4Y administrator",
                        from_name: data.information.organizationName,
                        message: "View Advance",
                        type: "Advance",
                        link: "http://3.135.250.131/admin/advance/" + this.claimAdvanceId
                    }
                    emailjs.send("service_30vfdxi", "template_ar6b18k", templateParams);
                }
                if (this.type === 'C' || this.type === 'CA') {
                    templateParams = {
                        to_name: "DS4Y administrator",
                        from_name: data.information.organizationName,
                        message: "View Claim",
                        type: "Claim",
                        link: "http://3.135.250.131/admin/claim/" + this.claimAdvanceId
                    }
                    emailjs.send("service_30vfdxi", "template_ar6b18k", templateParams);
                }
                
                if (!this.isAdminPage) {
                    await this.uploadAttachments();
                }
    
                if (!this.isAdminPage) {
                    this.setState({ redirect: true });
                } else {
                    this.setState({edit: false, disabled: true})
                }
            });
        }
    }

    async uploadAttachments(){
        const uid = auth.currentUser!.uid;
        const timestamp = Date.now();
        this.attachedPaystubs = this.props.history?.location?.state?.attached?.paystubs;
        this.attachedInvoices = this.props.history?.location?.state?.attached?.invoices;

        if (this.attachedPaystubs) {
            Promise.all(this.attachedPaystubs.map((paystub: File) => {
                return this.uploadAttachment(paystub, uid, timestamp, true)
            }))
        }
        

        if (this.attachedInvoices) {
            Promise.all(this.attachedInvoices.map((invoice: File) => {
                return this.uploadAttachment(invoice, uid, timestamp, false)
            }))
        }
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            if (user === null) {
                this.props.history.push("/");
            } else {
                this.forceUpdate();
            }
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleEdit() {
        if (!this.state.edit) {
            this.setState({edit: true, disabled: false})
        } else {
            if (window.confirm("Cancel Edit?")) {
                this.setState({edit: false, disabled: true, formKey: Math.random()})
            }
        }
    }

    render() {
        if (auth.currentUser === null) {
            return (<div />);
        }

        if (this.state.redirect) {
            return <Redirect to='/claims/complete' />
        }

        let previousRoute: string;

        switch(this.type){
            default:
            case 'C':
                previousRoute = "/claims/claimStepOne";
                break;

            case 'A':
                previousRoute = "/claims/advanceStepOne";
                break;

            case 'CA':
                previousRoute = "/claims/advanceStepOne";
                break;
        }

        return (
            <>
            <UserMenu email={auth.currentUser.email!} />
            <div>
            <Formik
                enableReinitialize={true}
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                >{(errors) => (
                    <Form>
                        { !this.props.isAdmin &&
                            <FormHeader subtitle={'IHO Claim Summary and/or Advance Claim'} />
                        }

                        <BlueHeaderSeparator title={this.isAdminPage ? "" : "STEP 2. "} subtitle={"IHO Claim Summary and/or Advance Claim"} />

                        { !this.props.isAdmin &&
                            <div className="form-inner-section-container">
                                <div className="form-inner-section">
                                    <span className="block-span">
                                        a) Please fill in your organization and project information
                        </span>
                                </div>
                            </div>
                        }
                        <SubFormInformation isAdmin={this.state.disabled} />

                        { !this.props.isAdmin &&
                            <div className="form-inner-section-container">
                                <div className="form-inner-section">
                                    <span className="block-span">
                                        b) Please fill in the amount of the claim you incurred, paid, the period the claim covers (from and to date)
                        </span>
                                </div>
                            </div>
                        }
                        <SubFormClaim isAdmin={this.state.disabled} />

                        { !this.props.isAdmin &&
                            <div className="form-inner-section-container">
                                <div className="form-inner-section">
                                    <span className="block-span">
                                        c) Please fill in the total eligible claim amount as per your total amount from the DETAILED CLAIM Sheet.
                        </span>
                                </div>
                            </div>
                        }
                        <SubFormTotalEligibleCosts isAdmin={this.state.disabled} />

                        { !this.props.isAdmin &&
                            <div className="form-inner-section-container">
                                <div className="form-inner-section">
                                    <span className="block-span">
                                        d) Please fill in the total advance you are request which is to NOT exceed 85% of Pinnguaq contribution.
                        </span>
                                </div>
                            </div>
                        }
                        <SubFormAdvances isAdmin={this.state.disabled} />
                        <SubFormTotalRequested isAdmin={this.state.disabled} />

                        { !this.props.isAdmin &&
                            <div className="form-inner-section-container">
                                <div className="form-inner-section">
                                    <span className="block-span">
                                        e) Please read the certification standards and sign and date this document.
                        </span>
                                </div>
                            </div>
                        }

                        <SubFormCertification isAdmin={this.state.disabled} />
                        
                        <ClaimAdvanceSummaryErrorList errors={errors.errors} />

                        { 
                            !this.props.isAdmin &&
                            <div className="submit-btn-wrapper">
                                <Link to={{pathname: previousRoute, state: {claimAdvanceId: this.claimAdvanceId, type: this.type, attached: this.props.history.location.state.attached}}}>
                                    <OutlineButton text={"Previous"}/>
                                </Link>
                                <SolidButton type={ButtonTypes.submit} text={"Submit"} />
                            </div>
                        }

                        {
                            this.isAdminPage && this.state.edit ?
                            <div className='edit-btn-container'>
                                <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                                <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                            </div>
                            : null
                        }
                    </Form>
                )}</Formik>

                {
                    this.isAdminPage && !this.state.edit ?
                    <div className='edit-btn-container'>
                        <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                    </div>
                    :
                    null
                }
            </div>
            </>
        );
    }
}