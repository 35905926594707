import React from 'react';
import './FormInput.css'
import { Field, ErrorMessage } from 'formik';

interface Props {
  label: string
  name?: string
  placeholder?: string
  type?: string
  onChange?: any
  handleChange?: any
  disabled?: boolean
  training?: any
}

export default class SubFormWages extends React.Component<Props> {
  static defaultProps = {
    placeholder: '',
    name: '',
    type: 'text'
  }
  
  constructor(props: Props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e: any, index: number) {
    this.props.handleChange(e);
    this.props.onChange(e.target.value, index);
  }

  render() {
    return (
      <div className="custom-form-input">
        <label>{this.props.label}</label>
        {
          this.props.onChange?
          <Field 
            type={this.props.type}
            name={this.props.name} 
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            disabled={this.props.disabled}
          />
          :
          <Field 
            type={this.props.type}
            max={this.props.name === 'forms.0.endDate' ? '2023-03-31' : '9999-12-31'}
            min={this.props.name === 'forms.0.startDate' ? '2022-04-01' : null}
            name={this.props.name} 
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
          />
        }
        
        {
          this.props.training !== null && this.props.training ?
          <ErrorMessage name={this.props.name!} component="div" className='error-msg'/>
          : null
        }
      </div>
    );
  }
}