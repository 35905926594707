import React from 'react';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import DocumentUploader from '../../../components/DocumentUploader/DocumentUploader';

interface Props {
    isAdmin?: boolean,
    claimAdvance?: any,
    claimAdvanceFk: number,
    onPaystubsAdded: (paystubs: Array<File>) => void,
    onInvoicesAdded: (invoices: Array<File>) => void,
    attached: {
        paystubs: any,
        invoices: any
    },
    edit: boolean,
    disabled: boolean,
    isAdminPage: boolean,
    handlePaystubEdit: any,
    handleInvoiceEdit: any
}

export default class SupportingDocumentation extends React.Component<Props> {
    onInvoicesAdded(invoices: Array<File>) {
        this.props.onInvoicesAdded(invoices);
    }

    onPaystubsAdded(paystubs: Array<File>) {
        this.props.onPaystubsAdded(paystubs);
    }

    render() {
        return (
            <div>
                <BlueHeaderSeparator title={this.props.isAdminPage ? "" : "Step 1."} subtitle={" Supporting Documentation"} />
                <div className="form-inner-section-container">
                    <div className="form-inner-section">
                        {!this.props.isAdmin && <>
                            <span className="block-span">
                                a) Please attach all Paystubs in the "Paystub" tab that align with the wages worked of your intern(s)
                        during this current claim period. <span className="important-info">Please include the paystubs from
                        2 weeks before the start and the end of the quarter.</span>  I.e. Quarter 3 start date is October
                        1st 2020 however the first Paystub needs to capture the WHOLE pay cycle. THUS the first paystub
                        submitted should be from 2 weeks prior to October 1st. <span className="important-info">Please
                                attach paystubs to this form, along with a PDF version emailed
                        to <strong>ds4y@pinnguaq.com.</strong></span>
                            </span>
                            <span className="block-span" style={{ marginBottom: "40px" }}>
                                b) Please attach all other invoices in the "Other Invoices" tab that were incurred over the current claim period.
                            </span>
                        </>}
                        
                        <DocumentUploader
                            isAdmin={this.props.isAdmin}
                            claimAdvance={this.props.claimAdvance}
                            claimAdvanceFk={this.props.claimAdvanceFk}
                            onInvoicesAdded={(invoices) => this.onInvoicesAdded(invoices)}
                            onPaystubsAdded={(paystubs) => this.onPaystubsAdded(paystubs)}
                            attached={this.props.attached} 
                            edit={this.props.edit}
                            disabled={this.props.disabled}
                            isAdminPage={this.props.isAdminPage}
                            handlePaystubEdit={this.props.handlePaystubEdit}
                            handleInvoiceEdit={this.props.handleInvoiceEdit}
                        />
                    </div>
                </div>
            </div>
        );
    }
}