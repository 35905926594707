import React from 'react';
import './SolidButton.css'

enum ButtonTypes {
  button = "button",
  submit = "submit",
  reset = "reset"
} 

interface Props{
  text: string
  type?: ButtonTypes
}

export default class SolidButton extends React.Component<Props> {
  static defaultProps = {
    type: ButtonTypes.button,
  }
  
  render() {
    return (
      <button type={this.props.type} className="solid-btn">
        {this.props.text}
      </button>
    );
  }
}