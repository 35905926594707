import React, { useEffect, useRef } from 'react';
import AddRow from '../../../../components/AddRow/AddRow';
import './travelTable.css';
import '../table.css';
import { Field, FieldArray, useFormikContext } from 'formik';
import NumberFormat from "react-number-format";
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';

export default function TravelTable(props: any) {
    useEffect(() => {
        updateTotal()
    })

    const initialValuesTrips = {
        kmsTravelled: 0,
        kmRate: 0,
        invoiceNum: 0,
        invoiceDate: '',
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalExpense: 0
    }

    const values: any = useFormikContext().values;
    const handleChange: any = useFormikContext().handleChange;

    const totalExpense = useRef(0);

    const updateTotal = () => {
        let total = 0;
        const travels = values.travelForms;

        if (travels) {
            travels.forEach((element: any) => {
                const trips = element.trips;
                if (trips) {
                    trips.forEach((element: any) => {
                        const totalExpense = element.totalExpense ? parseFloat(element.totalExpense) : 0;
                        total+=totalExpense;
                    });
                }
            });
            
            totalExpense.current = total;
            props.updateTotal(totalExpense.current);
        }
    }

    return (
        <FieldArray
            name={`travelForms.${props.tripIndex}.trips`}
            render={arrayHelpers => (
            <>
            <div className="custom-table travel-table">
                <div className="custom-table-cell header">KMs Travelled (for private vehicle use only)</div>
                <div className="custom-table-cell header">KM Rate (for private vehicle use only. See NUC Directive for rates)</div>
                <div className="custom-table-cell header">Invoice Ref. No.</div>
                <div className="custom-table-cell header">Invoice Date</div>
                <div className="custom-table-cell header">Invoice Amount Before Tax (Leave blank if claiming KMs)</div>
                <div className="custom-table-cell header">Provincial Tax (After rebates. Leave blank if claiming KMs)</div>
                <div className="custom-table-cell header">GST / HST (After rebates. Leave blank if claiming KMs)</div>
                <div className="custom-table-cell header">Total Travel Expense</div>
                <div className="custom-table-cell header remove"></div>

                {
                    values.travelForms[props.tripIndex].trips &&
                    values.travelForms[props.tripIndex].trips.map((item: any, index: number) => (
                        <>
                            <div className="custom-table-cell">
                                <Field type="number" name={`travelForms.${props.tripIndex}.trips.${index}.kmsTravelled`} disabled={props.isAdmin}/>
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`travelForms.${props.tripIndex}.trips.${index}.kmRate`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.kmRate}
                                    disabled={props.isAdmin} />                        </div>
                            <div className="custom-table-cell">
                                <Field type="text" name={`travelForms.${props.tripIndex}.trips.${index}.invoiceNum`} disabled={props.isAdmin}/>
                            </div>
                            <div className="custom-table-cell">
                                <Field type="date" max={'9999-12-31'} name={`travelForms.${props.tripIndex}.trips.${index}.invoiceDate`} disabled={props.isAdmin}/>
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`travelForms.${props.tripIndex}.trips.${index}.invoiceAmount`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.invoiceAmount}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`travelForms.${props.tripIndex}.trips.${index}.tax`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.tax}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`travelForms.${props.tripIndex}.trips.${index}.gstHst`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.gstHst}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`travelForms.${props.tripIndex}.trips.${index}.totalExpense`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.totalExpense}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell header remove">
                                <div hidden={index === 0 || props.isAdmin} onClick={() => { arrayHelpers.remove(index) }}>
                                    <RemoveItem/>
                                </div>   
                            </div>
                        </>
                    ))
                }
            </div>

                    { !props.isAdmin &&
                        <div onClick={() => { arrayHelpers.push(initialValuesTrips) }}>
                            <AddRow text={"traveler"} />
                        </div>
                    }

            </>
        )}/>
    );
}