import React from 'react';
import './Separator.css'
import { TiMediaRecordOutline } from 'react-icons/ti'

export default class Separator extends React.Component {
    render() {
      return (
        <div className="separator">
            <TiMediaRecordOutline/>
            <TiMediaRecordOutline/>
            <TiMediaRecordOutline/>
        </div>
      );
    }
}