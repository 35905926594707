import React from 'react';
import './TextArea.css'
import { Field, ErrorMessage } from 'formik';

interface Props{
    id?: string
    name?: string
    placeholder?: string
    disabled?: boolean
    training?: any
}

export default class SubFormWages extends React.Component<Props> {
    static defaultProps = {
      placeholder: '',
      id: '',
      name: ''
    }

    render() {
      return (
        <>
            <Field 
                as="textarea"
                id = ''
                className="form-textarea"
                name = {this.props.name}
                disabled = {this.props.disabled}
            />

        {
          this.props.training !== null && this.props.training ?
          <ErrorMessage name={this.props.name!} component="div" className='error-msg'/>
          : null
        }
        </>
      );
    }
}