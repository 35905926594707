import React from 'react';
import './adminApplication.css';
import { withRouter } from "react-router-dom";
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import axios from "../../../http-common";
import { EmployerInformationForm, ProposedInternshipForm, TrainingPlanForm, DeclarationForm } from '../..';

interface State{
    application: any
}

class AdminApplication extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            application: null
        };

        axios.get("/applications/" + this.props.match.params.applicationId).then((response) => {
            this.setState({ application: response.data });
        });
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            this.forceUpdate();
        });
    }

    boolToString(input: boolean) {
        return input ? 'Yes' : 'No';
    }

    renderFormData() {
        const { withinTerritories } = this.state.application;

        return (
            <div>
                <EmployerInformationForm isWithin={withinTerritories} isAdmin={true} application={this.state.application} />
                <ProposedInternshipForm isWithin={withinTerritories} isAdmin={true} application={this.state.application} />
                <TrainingPlanForm isWithin={withinTerritories} isAdmin={true} application={this.state.application} />
                <DeclarationForm isWithin={withinTerritories} isAdmin={true} application={this.state.application} />
            </div>
        );
    }

    render() {

        if (auth.currentUser === null || this.state.application === null) {
            return (<div />);
        }

        return (
            <div className="inner-container application">
                { this.renderFormData() }
            </div>
        );

    }
}
export default withRouter(AdminApplication);