import React from 'react';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import { SubFormWages, SubFormTraining, SubFormTravel, SubFormOther } from '../../index'

interface Props {
    isAdmin?: boolean
    claimAdvance?: any
    isAdminPage: boolean
    edit: boolean
}

export default class DetailedClaimSheet extends React.Component<Props> {
    render() {
        return (
            <>
                <BlueHeaderSeparator title={this.props.isAdminPage ? "" : "Step 2."} subtitle={" Detailed Claim Sheet"} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                a) Please fill in all wage information for the intern(s) so that it MATCHES the information
                                on their supporting paystubs.
                    </span>
                        </div>
                    </div>
                }
                <SubFormWages isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                b) Please fill in all training  information that you are claiming over this claim period.
                                If you were not pre-approved upon applying for the program for training please ignore this section.
                        <span className="important-info"> Note any training filled in should be supported by invoices
                        that are attached in the <strong>OTHER INVOICES</strong> tab.</span>
                            </span>
                        </div>
                    </div>
                }
                <SubFormTraining text={"Claimed"} isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                c) Please fill in all other direct costs related to travel.
                        <span className="important-info"> Note any direct costs related to travel should be
                        supported by invoices in the <strong>OTHER INVOICES</strong> tab.</span>
                            </span>
                        </div>
                    </div>
                }
                <SubFormTravel isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                d) Please fill in all other direct costs not including travel.
                        <span className="important-info"> Note any direct costs not including travel should be supported
                        by invoices in the <strong>OTHER INVOICES</strong> tab.</span>
                            </span>
                        </div>
                    </div>
                }
                <SubFormOther text={"Claimed"} isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />
            </>
        );
    }
}