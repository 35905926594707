import React from 'react';
import { Switch, Route, Link } from "react-router-dom";

import './intro.css'

import { Welcome } from '../index';
import { SelectTerritory, Login } from '../index';

import BackButton from '../../components/BackButton/BackButton';
import BigImage from '../../components/BigImage/BigImage'

export default class Intro extends React.Component{
    render() {
      return (
          <div className="container">
            <BigImage/>
            <div className="centered-container">
              <Switch>
                <Route exact path="/welcome" component={Welcome}/>
                <Route path="/welcome/application">
                  <Link to="/welcome"><BackButton/></Link>
                  <SelectTerritory />
                </Route>
                <Route path="/welcome/login">
                  <Link to="/welcome"><BackButton/></Link>
                  <Login />
                </Route>
              </Switch>
            </div>
          </div>
      );
    }
}