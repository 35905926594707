import React from 'react';
import { Redirect } from 'react-router-dom';
import FormHeader from '../../../components/FormHeader/FormHeader';
import SolidButton from '../../../components/SolidButton/SolidButton';
import UserMenu from '../../../components/UserMenu/UserMenu';
import { ClaimInstructions, SupportingDocumentation, DetailedClaimSheet } from '../../index'
import { auth, storage, ref } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import axios from "../../../http-common";
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import ClaimErrorList from '../../../components/ErrorList/ClaimErrorList';

enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset"
}

interface State {
    redirect: boolean,
    initialValues: any, 
    claimAdvance: any,
    attached: {
        paystubs: any,
        invoices: any
    }
    edit: boolean,
    disabled: boolean,
    paystubEdit: any,
    invoiceEdit: any,
    formKey: number,
}

export default class ClaimForm extends React.Component<any, State> {
    isBoth = false;
    claimAdvanceId: number = 0;
    attachedInvoices: Array<File> = new Array<File>();
    attachedPaystubs: Array<File> = new Array<File>();
    isAdminPage: any = window.location.pathname.includes('/admin/') || window.location.pathname.includes('/myforms/');
    uploadsRemaining = 0;

    initialValuesWages = {
        periodFrom: '',
        periodTo: '',
        hourlyRate: 0,
        totalHoursPaid: 0,
        grossAmount: 0,
        mercs: 0,
        cilob: 0,
        totalSalary: 0,
        totalSalaryClaimed: 0,
        sharingRation: 0
    }

    initialValuesEmployee = {
        name: '',
        title: '',
        wages: [this.initialValuesWages]
    }

    initialValuesTraining = {
        provider: '',
        type: '',
        startDate: '',
        invoiceDate: '',
        invoiceNum: 0,
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalClaimed: 0
    }

    initialValuesIntern = {
        name: '',
        training: [this.initialValuesTraining] 
    }

    initialValuesTrips = {
        kmsTravelled: 0,
        kmRate: 0,
        invoiceNum: 0,
        invoiceDate: '',
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalExpense: 0
    }

    initialValuesTravel = {
        dateTo: '',
        dateFrom: '',
        name: '',
        description: '',
        reason: '',
        trips: [this.initialValuesTrips] 
    }

    initialValuesItems = {
        description: '',
        invoiceDate: '',
        invoiceNum: 0,
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalClaimed: 0
    }

    initialValuesOthers = {
        name: '',
        items: [this.initialValuesItems] 
    }

    initialValues = {
        employeeForms: [this.initialValuesEmployee],
        internForms: [this.initialValuesIntern],
        travelForms: [this.initialValuesTravel],
        otherForms: [this.initialValuesOthers]
    }

    validationSchema = Yup.object().shape({
        employeeForms: Yup.array()
            .of(Yup.object().shape({
                name: Yup.string().required("*This field is required."),
                title: Yup.string().required("*This field is required."),
                wages: Yup.array()
                    .of(Yup.object().shape({
                        periodFrom: Yup.date().required("*This field is required."),
                        periodTo: Yup.date().required("*This field is required."),
                        hourlyRate: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalHoursPaid: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        grossAmount: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        mercs: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        cilob: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalSalary: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalSalaryClaimed: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        sharingRation: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    }))
        })),
        internForms: Yup.array()
        .of(Yup.object().shape({
            name: Yup.string().required("*This field is required."),
            training: Yup.array()
                .of(Yup.object().shape({
                    provider: Yup.string().required("*This field is required."),
                    type: Yup.string().required("*This field is required."),
                    startDate: Yup.date().required("*This field is required."),
                    invoiceDate: Yup.date().required("*This field is required."),
                    invoiceNum: Yup.string().required("*This field is required."),
                    tax: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    gstHst: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    totalClaimed: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
                }))
        })),
        travelForms: Yup.array()
        .of(Yup.object().shape({
            dateTo: Yup.date().required("*This field is required."),
            dateFrom: Yup.date().required("*This field is required."),
            name: Yup.string().required("*This field is required."),
            description: Yup.string().required("*This field is required."),
            reason: Yup.string().required("*This field is required."),
            trips: Yup.array()
                .of(Yup.object().shape({
                    kmsTravelled: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    kmRate: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    invoiceNum: Yup.string().required("*This field is required."),
                    invoiceDate: Yup.date().required("*This field is required."),
                    invoiceAmount: Yup.number().min(0, "*Number is invalid."),
                    tax: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    gstHst: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    totalExpense: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
                }))
        })),
        otherForms: Yup.array()
        .of(Yup.object().shape({
            name: Yup.string().required("*This field is required."),
            items: Yup.array()
                .of(Yup.object().shape({
                    description: Yup.string().required("*This field is required."),
                    invoiceDate: Yup.date().required("*This field is required."),
                    invoiceNum: Yup.string().required("*This field is required."),
                    invoiceAmount: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    tax: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    gstHst: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    totalClaimed: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
                }))
        }))
    });

    onSubmit(data: any) {
        if (this.isAdminPage) {
            if (window.confirm("Click 'OK' to save your edits")) {
                this.submitForm(data)
            }
        } else {
            this.submitForm(data)
        }
    }

    submitForm(data: any) {
        let claim = {
            userId: this.isAdminPage ? this.props.claimAdvance.userId : this.props.location.state.userFk,
            type: 'C'
        }
        if (this.isBoth) {
            claim = {
                userId: this.isAdminPage ? this.props.claimAdvance.userId : this.props.location.state.userFk,
                type: 'CA'
            }
        }

        if (this.isAdminPage) {
            this.claimAdvanceId = this.props.claimAdvance.claimAdvanceId
        }

        try {
            if (this.claimAdvanceId !== undefined){
                if (this.isAdminPage) {
                    this.uploadAttachments()
                }

                this.onUpdate(data);
            }
            else {
                axios.post("/claimAdvances", claim).then(async (response) => {
                    this.claimAdvanceId = response.data.claimAdvanceId;
                    this.onUpdate(data);
                });
            }
        } catch (e: any) {
            alert(e);
        }
    }
    
    async onUpdate(data: any) {
        let deleteObj = {
            claimAdvanceId: this.claimAdvanceId, 
            isAdvance: false
        }

        if (data.travelForms.length === 0) {
            window.sessionStorage.setItem('emptyTravel', 'true');
        } else {
            window.sessionStorage.setItem('emptyTravel', 'false');
        }

        if (data.otherForms.length === 0) {
            window.sessionStorage.setItem('emptyOther', 'true');
        } else {
            window.sessionStorage.setItem('emptyOther', 'false');
        }

        if (data.employeeForms.length === 0) {
            window.sessionStorage.setItem('emptyWage', 'true');
        } else {
            window.sessionStorage.setItem('emptyWage', 'false');
        }

        if (data.internForms.length === 0) {
            window.sessionStorage.setItem('emptyTraining', 'true');
        } else {
            window.sessionStorage.setItem('emptyTraining', 'false');
        }
        
        axios.put("/employees", deleteObj).then(async (response) => {
            data.employeeForms.forEach((row: any) => {

                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = false;
                const wages = row.wages;

                axios.post("/employees", row).then((response) => {

                    const employeeId = response.data.id;
                        wages.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.employeeId = employeeId;
                        })
                        
                        axios.post("/wages", wages);
                });
            });
        });

        axios.put("/interns", deleteObj).then(() => {
            data.internForms.forEach((row: any) => {

                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = false;
                const training = row.training;

                axios.post("/interns", row).then((response) => {
                    const internId = response.data.id;
                    if (training) {
                        training.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.internId = internId;
    
                        })
    
                        axios.post("/training", training);
                    }
                });
            });
        });

        axios.put("/travels", deleteObj).then(() => {
            data.travelForms.forEach((row: any) => {

                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = false;
                const trips = row.trips;

                axios.post("/travels", row).then((response) => {
                    const travelId = response.data.id;
                    
                    if (trips) {
                        trips.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.travelId = travelId;
    
                        })
    
                        axios.post("/trips", trips);
                    }
                });
            });
        });

        axios.put("/suppliers", deleteObj).then(() => {
            data.otherForms.forEach((row: any) => {

                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = false;
                const items = row.items;

                axios.post("/suppliers", row).then((response) => {

                    const supplierId = response.data.id;
                    if (items) {
                        items.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.supplierId = supplierId;
    
                        })
    
                        axios.post("/items", items);
                    }
                });
            });
        });

        if (!this.isAdminPage) {
            this.setState({ redirect: true });
        } else {
            this.setState({edit: false, disabled: true})
        }
    }

    uploadAttachments(){
        const uid = auth.currentUser!.uid;
        const timestamp = Date.now();
        let paystubArr = this.state.paystubEdit
        let invoiceArr = this.state.invoiceEdit
        
        Promise.all(paystubArr.map((paystub: File) => {
            return this.uploadAttachment(paystub, uid, timestamp, true)
        }))
        

        Promise.all(invoiceArr.map((invoice: File) => {
            return this.uploadAttachment(invoice, uid, timestamp, false)
        }))
    }

    uploadAttachment(file: File, uid: string, timestamp: number, isPaystub: boolean) {
        let deleteObj = {
            claimAdvanceId: this.claimAdvanceId
        }

        axios.put("/attachments", deleteObj).then((res) => {
            // Upload each file to Firebase storage
            const directory = isPaystub ? '/paystubs/' : '/invoices/';
            const storageRef = ref(storage, uid + directory + timestamp + '_' + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // TODO: progress visualization
                    //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    // TODO: handle failed uploads
                },
                () => {
                    // Handle successful upload
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // Add entry to database Attachments table
                        let attachment = {
                            claimAdvanceId: this.claimAdvanceId,
                            isPaystub: isPaystub,
                            path: downloadURL
                        }

                        axios.post("/attachments", attachment).then(() => {
                            this.setState({edit: false, disabled: true})
                        })
                    });
                }
            );
        })
    }
    
    constructor(props: any) {
        super(props);
        
        if (this.props.isAdmin) {
            const { Employees, Interns, Travels, Suppliers } = this.props.claimAdvance;

            if (Employees.length > 0) {
                Employees.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((employee: any, index: number) => {
                    const { name, title, Wages } = employee;
                    Wages.id = undefined;
                    this.initialValues.employeeForms.push({
                        name: name,
                        title: title,
                        wages: Wages
                    });
                });
                this.initialValues.employeeForms.shift();
            }

            if (Interns.length > 0) {
                Interns.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((intern: any, index: number) => {
                    const { name, Trainings } = intern;
                    Trainings.id = undefined;
                    this.initialValues.internForms.push({
                        name: name,
                        training: Trainings
                    });
                });
                this.initialValues.internForms.shift();
            }

            if (Travels.length > 0) {
                Travels.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((travel: any, index: number) => {
                    const { dateFrom, dateTo, name, description, reason, Trips } = travel;
                    Trips.id = undefined;
                    this.initialValues.travelForms.push({
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                        name: name,
                        description: description,
                        reason: reason,
                        trips: Trips 
                    });
                });
                this.initialValues.travelForms.shift();
            }

            if (Suppliers.length > 0) {
                Suppliers.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((supplier: any, index: number) => {
                    const { name, Items } = supplier;
                    Items.id = undefined;
                    this.initialValues.otherForms.push({
                        name: name,
                        items: Items 
                    });
                });
                this.initialValues.otherForms.shift();
            }
        }

        let newAttached = {
            invoices: this.attachedInvoices, 
            paystubs: this.attachedPaystubs
        }
        
        this.state = {
            redirect: false,
            initialValues: this.initialValues, 
            claimAdvance: this.props.claimAdvance,
            attached: newAttached,
            edit: false, 
            disabled: this.isAdminPage ? true : false,
            paystubEdit: [],
            invoiceEdit: [],
            formKey: 0,
        }

        if (this.props.location.state) {
            this.isBoth = this.props.location.state.isBoth;
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.handlePaystubEdit = this.handlePaystubEdit.bind(this);
        this.handleInvoiceEdit = this.handleInvoiceEdit.bind(this);
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                this.props.history.push("/");
            } else {
                this.forceUpdate();
            }
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        if (this.props?.location?.state?.type === 'CA'){
            this.isBoth = true;
        }


        if (this.props.history?.location?.state?.attached !== undefined || this.props.history?.location?.state?.attached !== null) {
            this.attachedPaystubs = this.props.history?.location?.state?.attached?.paystubs 
            this.attachedInvoices = this.props.history?.location?.state?.attached?.invoices 
        }

        this.claimAdvanceId = this.props.location.state?.claimAdvanceId;
        if (this.claimAdvanceId !== undefined){

            axios.get("/claimAdvances/" + this.claimAdvanceId).then((response) => {
                let data = response?.data;

                    const { Employees, Interns, Travels, Suppliers } = data;
                    if (Employees.length > 0) {
                        Employees.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((employee: any, index: number) => {
                            const { name, title, Wages } = employee;
                            Wages.id = undefined;
                            this.initialValues.employeeForms.push({
                                name: name,
                                title: title,
                                wages: Wages
                            });
                        });
                        this.initialValues.employeeForms.shift();
                    }

                    if (Interns.length > 0) {
                        Interns.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((intern: any, index: number) => {
                            const { name, Trainings } = intern;
                            Trainings.id = undefined;
                            this.initialValues.internForms.push({
                                name: name,
                                training: Trainings
                            });
                        });
                        this.initialValues.internForms.shift();
                    }

                    if (Travels.length > 0) {
                        Travels.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((travel: any, index: number) => {
                            const { dateFrom, dateTo, name, description, reason, Trips } = travel;
                            Trips.id = undefined;
                            this.initialValues.travelForms.push({
                                dateFrom: dateFrom,
                                dateTo: dateTo,
                                name: name,
                                description: description,
                                reason: reason,
                                trips: Trips 
                            });
                        });
                        this.initialValues.travelForms.shift();
                    }

                    if (Suppliers.length > 0) {
                        Suppliers.filter((e: { isAdvance: boolean; }) => !e.isAdvance).forEach((supplier: any, index: number) => {
                            const { name, Items } = supplier;
                            Items.id = undefined;
                            this.initialValues.otherForms.push({
                                name: name,
                                items: Items 
                            });
                        });
                        this.initialValues.otherForms.shift();
                    }
                    
                    this.setState({initialValues: this.initialValues, claimAdvance: data, attached: {invoices: this.attachedInvoices , paystubs: this.attachedPaystubs}});
            }         
        )}      
    }

    onPaystubsAdded(paystubs: Array<File>) {
        this.attachedPaystubs = paystubs    
        this.setState(prevState => ({
            attached: {
                ...prevState.attached,
                paystubs: paystubs
            }
        }))
    }

    onInvoicesAdded(invoices: Array<File>) {
        this.attachedInvoices = invoices;
        this.setState(prevState => ({
            attached: {
                ...prevState.attached,
                invoices: invoices
            }
        }))
    }

    handleEdit() {
        if (!this.state.edit) {
            this.setState({edit: true, disabled: false})
        } else {
            if (window.confirm("Cancel Edit?")) {
                this.setState({edit: false, disabled: true, formKey: Math.random()})
            }
        }
    }

    handlePaystubEdit (data: any) {
        this.setState({paystubEdit: data})
    }

    handleInvoiceEdit(data: any) {
        this.setState({invoiceEdit: data})
    }

    render() {
        if (auth.currentUser === null) {
            return (<div />);
        }

        if (this.state.redirect) {
            if(this.isBoth){
                return <Redirect to =
                    {{
                        pathname: '/claims/advanceStepOne',
                        state: { 
                            claimAdvanceId: this.claimAdvanceId,
                            userFk: this.props.location.state.userFk,
                            type: 'CA',
                            attached: this.state.attached,
                        }
                    }} 
                />
            }else{
                return <Redirect to =
                    {{
                        pathname: '/claims/stepTwo',
                        state: { 
                            claimAdvanceId: this.claimAdvanceId,
                            userFk: this.props.location.state.userFk,
                            type: 'C',
                            attached: this.state.attached,
                        }
                    }} 
                />
            }
        }

        return (
            <>
                <UserMenu email={auth.currentUser.email!} />
                <div>
                    <Formik
                    enableReinitialize={true}
                    initialValues={this.state.initialValues}
                    onSubmit={this.onSubmit}
                    validationSchema={this.validationSchema}
                    >{(errors) => (
                        <Form>
                            <FormHeader subtitle={this.props.isAdmin ? 'Claim' : 'Report a Claim'} />

                            { !this.props.isAdmin &&
                                <ClaimInstructions />
                            }

                            <SupportingDocumentation
                                attached={this.props.history?.location?.state?.attached}
                                claimAdvance={this.state.claimAdvance} //this is for edit
                                claimAdvanceFk={this.claimAdvanceId}
                                onInvoicesAdded={(invoices: any) => this.onInvoicesAdded(invoices)}
                                onPaystubsAdded={(paystubs: any) => this.onPaystubsAdded(paystubs)}
                                isAdmin={this.props.isAdmin}
                                edit={this.state.edit}
                                disabled={this.state.disabled}
                                isAdminPage={this.isAdminPage}
                                handlePaystubEdit={this.handlePaystubEdit}
                                handleInvoiceEdit={this.handleInvoiceEdit}
                            />

                            {
                                this.isAdminPage && !this.state.edit ?
                                <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                                :
                                null
                            }

                            {
                                this.isAdminPage && this.state.edit ?
                                <div className='edit-btn-container'>
                                    <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                                    <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                                </div>
                                : null
                            }
                            <DetailedClaimSheet isAdmin={this.state.disabled} isAdminPage={this.isAdminPage} edit={this.state.edit} />

                            <ClaimErrorList errors={errors.errors} />

                            { !this.props.isAdmin &&
                                <div className="submit-btn-wrapper">
                                    <SolidButton type={ButtonTypes.submit} text={"Next"} />
                                </div>
                            }

                            {
                                this.isAdminPage && this.state.edit ?
                                <div className='edit-btn-container'>
                                    <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                                    <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                                </div>
                                : null
                            }
                        </Form>
                    )}</Formik>

                    {
                        this.isAdminPage && !this.state.edit ?
                        <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                        :
                        null
                    }
                </div>
            </>
        );
    }
}