import React from 'react';
import './adminBrowseClaims.css';
import { withRouter } from "react-router-dom";
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import axios from "../../../http-common";
import AdminHeader from '../../../components/AdminHeader/AdminHeader';
import UserMenu from '../../../components/UserMenu/UserMenu';

interface State{
    claimAdvances: Array<any>,
    isAdmin: boolean
}

class AdminBrowseClaims extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            claimAdvances: [],
            isAdmin: false
        };

        axios.get("/claimAdvances").then((response) => {
            let tempArr: any = [];
            
            response.data.map((item:any) => {
                if (item.type === "C" || item.type === "CA") {
                    if (item.Information.length >= 1) {
                        tempArr.push(item)
                    }
                }
            })

            this.setState({ claimAdvances: tempArr});
        });
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                this.props.history.push("/admin");
            } else {
                axios.get("/users/" + auth.currentUser?.uid).then((response) => {
                    this.setState({ isAdmin: response.data != null && response.data.isAdmin });
                });
            }
        });
    }

    renderTableHeader() {
        if (this.state.claimAdvances.length <= 0) {
            return;
        }

        return (
            <tr>
                <th>Id</th>
                <th>Date</th>
                <th>Project Start Date</th>
                <th>Project Completion Date</th>
                <th>Attached Paystubs</th>
                <th>Attached Invoices</th>
                <th>Organization</th>
                <th>Contact</th>
                <th>Submitted By</th>
            </tr>
        );
    }

    onClaimSelected(id: number) {
        this.props.history.push("/admin/claim/" + id);
    }

    renderTableData() {
        return this.state.claimAdvances.map((claimAdvance, index) => {
            const { claimAdvanceId, createdAt, Attachments, User, Information } = claimAdvance;

            if (Information === null || Information.length === 0) {
                return null;
            }

            const dateString = (new Date(createdAt).toDateString());

            let email = "";
            if (User != null) email = User.email;

            const { projectStartDate, projectCompletionDate, organizationName, contactName } = Information[0];

            let numPaystubs = 0;
            let numInvoices = 0;
            Attachments.forEach((attachment: any) => {
                if (attachment.isPaystub) {
                    numPaystubs++;
                } else {
                    numInvoices++;
                }
            });

            const startDateString = (new Date(projectStartDate).toDateString());
            const endDateString = (new Date(projectCompletionDate).toDateString());

            return (
                <tr key={claimAdvanceId} onClick={() => this.onClaimSelected(claimAdvanceId)}>
                    <td>{claimAdvanceId}</td>
                    <td>{dateString}</td>
                    <td>{startDateString}</td>
                    <td>{endDateString}</td>
                    <td>{numPaystubs}</td>
                    <td>{numInvoices}</td>
                    <td>{organizationName}</td>
                    <td>{contactName}</td>
                    <td>{email}</td>
                </tr>
            );
        });
    }

    render() {

        if (auth.currentUser == null) {
            return (<div />);
        }

        if (!this.state.isAdmin) {
            return (<div />);
        } else {
            return (
                <>
                    <UserMenu email={auth.currentUser.email!} />
                    <AdminHeader currentTab={1} />
                    <div className="inner-container browse">
                        <table id='applications'>
                            <tbody>
                                {this.renderTableHeader()}
                                {this.renderTableData()}
                            </tbody>
                        </table>
                    </div>
                </>
            );
        }
    }
}
export default withRouter(AdminBrowseClaims);