import React from 'react';
import './travelTotalTable.css';
import '../table.css';
import NumberFormat from "react-number-format";

interface Props{
  totalExpense: number
}

interface State{
  totalExpense: number
}

export default class TravelTotalTable extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      totalExpense: 0
    };

    this.setState({ 
      totalExpense: this.props.totalExpense
    })
  }

  render() {
    return (
      <>
      <div className="custom-table travel-total-table">
        <div className="custom-table-cell header"></div>
          <div className="custom-table-cell header">Total Travel Expense</div>

        <div className="custom-table-cell">Total</div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={this.state.totalExpense} />
        </div>
      </div>
      </>
    );
  }
}