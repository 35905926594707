import React from 'react';
import { Link, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import AddAnother from '../../../components/AddAnother/AddAnother';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import FormHeader from '../../../components/FormHeader/FormHeader';
import FormInput from '../../../components/FormInput/FormInput';
import OutlineButton from '../../../components/OutlineButton/OutlineButton';
import Separator from '../../../components/Separator/Separator';
import SolidButton from '../../../components/SolidButton/SolidButton';
import TextArea from '../../../components/TextArea/TextArea';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from "yup";
import axios from "../../../http-common";
import RemoveItem from '../../../components/RemoveItem/RemoveItem';
import ProposedInternErrorList from '../../../components/ErrorList/ProposedInternErrorList';
import InternshipDocuments from '../../../components/DocumentUploader/InternshipDocuments';
import { auth, storage, ref } from "../../../firebase";
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';

enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset"
} 

interface Props{
    isWithin: boolean,
    isAdmin?: boolean,
    application?: any,
    location: {
        state: any
    }
}

interface State {
    redirect: boolean
    daysOfWorkPerWeek: number[]
    hoursOfWorkPerDay: number[]
    numWeeks: number[]
    totalHours: number[]
    internPay: number[]
    totalWages: number[]
    employerCost: number[]
    totalInternCost: number[]
    initialValues: any
    edit: boolean
    disabled: boolean
    formKey: number
    applicationId: number
    attachments: any
    savedAttachments: any
}

class ProposedInternshipForm extends React.Component<Props & RouteComponentProps, State> {
    applicationId: number = 0;
    titleText: string = "outside";
    isAdminPage: any = window.location.pathname.includes('/admin/') || window.location.pathname.includes('/myforms/');
    minDate: Date = new Date(2022,3,1); // month (second num) is 0 indexed
    minEndDate: Date = new Date(2023,2,31);
    attachments: Array<File> = new Array<File>();

    initialValues = [{
        jobTitle: '',
        startDate: '',
        endDate: '',
        workDaysWeek: 0,
        hoursWorkDay: 0,
        totalNumWeeks: 0,
        totalHours: 0,
        internRatePay: 0,
        totalTraineeWages: 0,
        employerCosts: 0,
        costInternPlacement: 0,
        contributionAmount: 0,
        description: ''
    }]

    validationSchema = Yup.object().shape({
        forms: Yup.array()
            .of(Yup.object().shape({
                jobTitle: Yup.string().required("*This field is required."),
                startDate: Yup.date().required("*This field is required.")
                .min(this.minDate, "Date must be 2022 or later"),
                endDate: Yup.date().required("*This field is required.")
                    .min(this.minEndDate, "Date must be 2022 or later")
                    .min(Yup.ref('startDate'),"End date can't be before start date"),
                workDaysWeek: Yup.number().required("*This field is required.").min(0, "*Number is invalid.").max(7, "Cannot be more than 7."),
                hoursWorkDay: Yup.number().required("*This field is required.").min(0, "*Number is invalid.").max(24, "Cannot be more than 24."),
                totalNumWeeks: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                totalHours: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                internRatePay: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                totalTraineeWages: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                employerCosts: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                costInternPlacement: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                contributionAmount: Yup.number().required("*This field is required.").min(0, "*Number is invalid.").max(this.props.isWithin ? 22500 : 25500, "*Number is invalid."),
                description: Yup.string().required("*This field is required.")
            }))
    });

    constructor(props: any){
        super(props);

        if(this.props.isWithin) {
            this.titleText = "for"
        }

        this.applicationId = this.props.isAdmin ? this.props.application.id : this.props.location.state?.applicationId;

        if (this.props.isAdmin) {
            const { Internships } = this.props.application;

            if (Internships.length > 0) {
                this.initialValues.pop();

                Internships.forEach((internship: any, index: number) => {
                    const {
                        jobTitle,
                        startDate,
                        endDate,
                        workDaysWeek,
                        hoursWorkDay,
                        totalNumWeeks,
                        totalHours,
                        internRatePay,
                        totalTraineeWages,
                        employerCosts,
                        costInternPlacement,
                        contributionAmount,
                        description } = internship;

                    this.initialValues.push({
                        jobTitle: jobTitle,
                        startDate: startDate,
                        endDate: endDate,
                        workDaysWeek: workDaysWeek,
                        hoursWorkDay: hoursWorkDay,
                        totalNumWeeks: totalNumWeeks,
                        totalHours: totalHours,
                        internRatePay: internRatePay,
                        totalTraineeWages: totalTraineeWages,
                        employerCosts: employerCosts,
                        costInternPlacement: costInternPlacement,
                        contributionAmount: contributionAmount,
                        description: description
                    });
                });
            }
        }

        this.state = {
            redirect: false,
            daysOfWorkPerWeek: [0],
            hoursOfWorkPerDay: [0],
            numWeeks: [0],
            totalHours: [0],
            internPay: [0],
            totalWages: [0],
            employerCost: [0],
            totalInternCost: [0],
            initialValues: null,
            edit: false, 
            disabled: this.isAdminPage ? true : false,
            formKey: 0,
            applicationId: this.props.location?.state?.applicationId,
            attachments: [],
            savedAttachments: []
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        
        if (this.isAdminPage) {
            this.applicationId = this.props.application.id
        } else {
            this.applicationId = this.props.location.state?.applicationId;
        }

        if (this.applicationId !== undefined) {
            axios.get("/Applications/" + this.applicationId).then((response) => {
                const { Internships } = response?.data;

                if (Internships.length > 0) {
                    this.initialValues.pop();

                    Internships.forEach((internship: any, index: number) => {
                        const {
                            jobTitle,
                            startDate,
                            endDate,
                            workDaysWeek,
                            hoursWorkDay,
                            totalNumWeeks,
                            totalHours,
                            internRatePay,
                            totalTraineeWages,
                            employerCosts,
                            costInternPlacement,
                            contributionAmount,
                            description } = internship;

                        this.initialValues.push({
                            jobTitle: jobTitle,
                            startDate: startDate,
                            endDate: endDate,
                            workDaysWeek: workDaysWeek,
                            hoursWorkDay: hoursWorkDay,
                            totalNumWeeks: totalNumWeeks,
                            totalHours: totalHours,
                            internRatePay: internRatePay,
                            totalTraineeWages: totalTraineeWages,
                            employerCosts: employerCosts,
                            costInternPlacement: costInternPlacement,
                            contributionAmount: contributionAmount,
                            description: description
                        });
                    });
                }

                this.setState({initialValues: this.initialValues, savedAttachments: response.data.AppAttachments})
            }); 
        }
    }

    onSubmit( data: any ) {
        if (this.isAdminPage) {
            if (window.confirm("Click 'OK' to save your edits")) {
                this.documentUpload()
                this.submitForm(data)
            }
        } else {
            this.documentUpload()
            this.submitForm(data)
        }
    }

    documentUpload() {
        const appId = this.applicationId;

        Promise.all(this.state.attachments.map((attachment: File) => {
            return this.uploadAttachment(attachment, appId)
        }))
    }

    uploadAttachment(file: File, appId: number) {
        let deleteObj = {
            applicationId: appId
        }

        axios.put("/appattachments", deleteObj).then(() => {
            const storageRef = ref(storage, appId + '_' + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);
            
            uploadTask.on('state_changed',
                (snapshot) => {
                    // TODO: progress visualization
                    //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    // TODO: handle failed uploads
                },
                () => {
                    // Handle successful upload
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // Add entry to database Attachments table
                        let attachment = {
                            applicationId: appId,
                            name: file.name.toString(),
                            path: downloadURL
                        };
                        
                        axios.post("/appattachments", attachment)
                    });
                }
            );
        })
    }

    submitForm(data: any) {
        data.forms.forEach((row: any) => {
            row.applicationId = this.applicationId;
        });

        data.forms.applicationId = this.applicationId;

        axios.post("/internships/update/"+this.applicationId, data.forms).then(() => {
            if (this.isAdminPage) {
                this.setState({edit: false, disabled: true})
            } else {
                this.setState({redirect: true});
            }
        });
    }

    async updateDaysOfWorkPerWeek(value: any, index: number) {
        let newDaysOfWorkPerWeek = [...this.state.daysOfWorkPerWeek];
        newDaysOfWorkPerWeek[index] = Number(value);
        await this.setState({ 
            daysOfWorkPerWeek: newDaysOfWorkPerWeek 
        });
        this.updateTotalHours(index);
    }

    async updateHoursOfWorkPerDay(value: any, index: number) {
        let newHoursOfWorkPerDay = [...this.state.hoursOfWorkPerDay];
        newHoursOfWorkPerDay[index] = Number(value);
        await this.setState({ 
            hoursOfWorkPerDay: newHoursOfWorkPerDay 
        });
        this.updateTotalHours(index);
    }

    async updateNumWeeks(value: any, index: number) {
        let newNumWeeks = [...this.state.numWeeks];
        newNumWeeks[index] = Number(value);
        await this.setState({ 
            numWeeks: newNumWeeks 
        });
        this.updateTotalHours(index);
    }

    async updateRateOfPay(value: any, index: number) {
        let newRateOfPay = [...this.state.internPay];
        newRateOfPay[index] = Number(value);
        await this.setState({ 
            internPay: newRateOfPay 
        });
        this.updateTotalWages(index);
    }

    async updateEmployerCosts(value: any, index: number) {
        let newEmployerCost = [...this.state.employerCost];
        newEmployerCost[index] = Number(value);
        await this.setState({ 
            employerCost: newEmployerCost 
        });
        this.updateTotalWages(index);
    }

    updateTotalHours(index: number) {
        const total: number = this.state.daysOfWorkPerWeek[index] * this.state.hoursOfWorkPerDay[index] * this.state.numWeeks[index];
        
        let newTotalHours = [...this.state.totalHours];
        newTotalHours[index] = total;
        this.setState({ 
            totalHours: newTotalHours 
        });
        this.updateTotalWages(index);
    }

    updateTotalWages(index: number) {
        const total: number = this.state.totalHours[index] * this.state.internPay[index];
        
        let newTotalWages = [...this.state.totalWages];
        newTotalWages[index] = total;
        this.setState({ 
            totalWages: newTotalWages 
        });
        this.updateTotalInternCosts(index);
    }

    updateTotalInternCosts(index: number) {
        const total: number = this.state.totalWages[index] + this.state.employerCost[index];
        
        let newTotalInternCosts = [...this.state.totalInternCost];
        newTotalInternCosts[index] = total;
        this.setState({ 
            totalInternCost: newTotalInternCosts 
        });
    }

    handleEdit() {
        if (!this.state.edit) {
            this.setState({edit: true, disabled: false})
        } else {
            if (window.confirm("Cancel Edit?")) {
                this.setState({edit: false, disabled: true, formKey: Math.random()})
            }
        }
    }

    onAttachmentsAdded(attachments: Array<File>) {
        this.attachments = attachments
        this.setState(prevState => ({
            ...prevState.attachments,
            attachments: attachments,
            savedAttachments: attachments

        }))
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to = 
                {{
                    pathname: this.props.isWithin? '/application/within/trainingPlan': '/application/outside/trainingPlan',
                    state: { applicationId: this.applicationId }
                }} 
            />
        }

        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ forms: [...this.initialValues] }}
                    onSubmit={this.onSubmit} 
                    key={this.state.formKey}
                    validationSchema={this.validationSchema}
                >{({ values, handleChange, setFieldValue, errors }) => (
                <Form>
                    { !this.props.isAdmin &&
                        <FormHeader subtitle={'Internship Host Organization (IHO) Application'} subtitle2={'('+this.titleText+' territories)'}/>
                    }

                    <div className='print-break-PI-title'>
                        <BlueHeaderSeparator title={"Proposed Internship"}/>
                        
                        <div className="form-inner-section-container">
                            <div className="form-inner-section">

                            <FieldArray
                                name="forms"
                                render={arrayHelpers => (
                                    <>
                                    {values.forms.map((item, index) => (

                                    <div key={index}>
                                    {!this.props.isAdmin && index !== 0 &&
                                        <div onClick={() => { arrayHelpers.remove(index) }}>
                                            <RemoveItem />
                                        </div>
                                    }

                                    <div className="sub-form-sub-container-wrapper" key={index}>
                                        <div className="margin-item">
                                            <FormInput label={"1. Job Title"} name={`forms.${index}.jobTitle`} disabled={this.state.disabled} />
                                        </div>
                                        <div className="sub-form-sub-container">
                                            <FormInput label={"2. Proposed start date of internship"} type={'date'} name={`forms.${index}.startDate`} disabled={this.state.disabled} />
                                            <FormInput label={"3. Proposed end date of internship"} type={'date'} name={`forms.${index}.endDate`} disabled={this.state.disabled} />
                                        </div>
                                        <div className="sub-form-sub-container">
                                            <FormInput
                                                label={"4. Days of work per week"} 
                                                type={'number'} 
                                                name={`forms.${index}.workDaysWeek`}
                                                handleChange={handleChange}
                                                disabled={this.state.disabled}
                                                onChange={async (e: any) => {
                                                    await this.updateDaysOfWorkPerWeek(e, index);
                                                    setFieldValue(`forms.${index}.totalHours`, this.state.totalHours[index]);
                                                    setFieldValue(`forms.${index}.totalTraineeWages`, this.state.totalWages[index]);
                                                    setFieldValue(`forms.${index}.costInternPlacement`, this.state.totalInternCost[index]);
                                                }}
                                            />
                                            <FormInput 
                                                label={"5. Hours of work per day"} 
                                                type={'number'} 
                                                name={`forms.${index}.hoursWorkDay`}
                                                handleChange={handleChange}
                                                disabled={this.state.disabled}
                                                onChange={async (e: any) => {
                                                    await this.updateHoursOfWorkPerDay(e, index);
                                                    setFieldValue(`forms.${index}.totalHours`, this.state.totalHours[index]);
                                                    setFieldValue(`forms.${index}.totalTraineeWages`, this.state.totalWages[index]);
                                                    setFieldValue(`forms.${index}.costInternPlacement`, this.state.totalInternCost[index]);
                                                }} 
                                            />
                                            <FormInput 
                                                label={"6. Total number of weeks"} 
                                                type={'number'} 
                                                name={`forms.${index}.totalNumWeeks`}
                                                handleChange={handleChange}
                                                disabled={this.state.disabled}
                                                onChange={async (e: any) => {
                                                    await this.updateNumWeeks(e, index);
                                                    setFieldValue(`forms.${index}.totalHours`, this.state.totalHours[index]);
                                                    setFieldValue(`forms.${index}.totalTraineeWages`, this.state.totalWages[index]);
                                                    setFieldValue(`forms.${index}.costInternPlacement`, this.state.totalInternCost[index]);
                                                }} 
                                            />
                                            <FormInput 
                                                label={"7. Total number of hours"} 
                                                type={'number'} 
                                                name={`forms.${index}.totalHours`}
                                                disabled={this.state.disabled}
                                            />
                                        </div>
                                        <div className="sub-form-sub-container print-break-PI-9">
                                            <FormInput 
                                                label={"8. Intern rate of pay ($/hr)"} 
                                                type={'number'} 
                                                name={`forms.${index}.internRatePay`}
                                                handleChange={handleChange}
                                                disabled={this.state.disabled}
                                                onChange={async (e: any) => {
                                                    await this.updateRateOfPay(e, index);
                                                    setFieldValue(`forms.${index}.totalTraineeWages`, this.state.totalWages[index]);
                                                    setFieldValue(`forms.${index}.costInternPlacement`, this.state.totalInternCost[index]);
                                                }} 
                                            />
                                            
                                            <FormInput 
                                                label={"9. Total trainee wages ($)"} 
                                                type={'number'} 
                                                name={`forms.${index}.totalTraineeWages`}
                                                disabled={this.state.disabled}
                                            />
                                        </div>
                                        <div className="sub-form-sub-container">
                                            <FormInput 
                                                label={"10. Estimated mandatory employer costs ($)"} 
                                                type={'number'} 
                                                name={`forms.${index}.employerCosts`}
                                                handleChange={handleChange}
                                                disabled={this.state.disabled}
                                                onChange={async (e: any) => {
                                                    await this.updateEmployerCosts(e, index);
                                                    setFieldValue(`forms.${index}.costInternPlacement`, this.state.totalInternCost[index]);
                                                }}
                                            />
                                            <FormInput 
                                                label={"11. Total wage cost of intern placement ($)"} 
                                                type={'number'} 
                                                name={`forms.${index}.costInternPlacement`}
                                                disabled={this.state.disabled}
                                            />
                                        </div>
                                        <div>
                                            <FormInput 
                                                label={this.props.isWithin? 
                                                    "12. Pinnguaq contribution amount (100% of total wage costs up to a maximum of $22,500.00)":
                                                    "12. Pinnguaq contribution amount (100% of total wage costs up to a maximum of $25,500.00)"
                                                }
                                                type={'number'}
                                                name={`forms.${index}.contributionAmount`}
                                                disabled={this.state.disabled}
                                            />
                                        </div>
                                        <div className="margin-item">
                                            13. Job Description, Work Plan and Mentoring Arrangement (attach additional information if necessary)

                                            <div className='print-textarea'>{item.description}</div>
                                            <TextArea name={`forms.${index}.description`} disabled={this.state.disabled}/>
                                        </div>
                                        <div style={{marginTop:"30px"}}><Separator/></div>
                                    </div>
                                    </div>
                                        
                                    ))}
                            
                                    { !this.props.isAdmin &&
                                        <div onClick={() => {
                                            arrayHelpers.push(this.initialValues[0])
                                            this.setState({ daysOfWorkPerWeek: [...this.state.daysOfWorkPerWeek, 0] })
                                            this.setState({ hoursOfWorkPerDay: [...this.state.hoursOfWorkPerDay, 0] })
                                            this.setState({ numWeeks: [...this.state.numWeeks, 0] })
                                            this.setState({ totalHours: [...this.state.totalHours, 0] })
                                            this.setState({ internPay: [...this.state.internPay, 0] })
                                            this.setState({ totalWages: [...this.state.totalWages, 0] })
                                            this.setState({ employerCost: [...this.state.employerCost, 0] })
                                            this.setState({ totalInternCost: [...this.state.totalInternCost, 0] })
                                        }}>
                                            <AddAnother text={'proposed internship'}/>
                                        </div>
                                    }
                                    </>
                                )}
                                />
                            </div>
                        </div>
                    </div>

                    <BlueHeaderSeparator title={" Supporting Documentation"} />
                    <InternshipDocuments
                        attachments={this.state.savedAttachments}
                        isAdmin={this.props.isAdmin}
                        userId={this.state.applicationId}
                        onAttachmentsAdded={(attachments) => this.onAttachmentsAdded(attachments)}
                        edit={this.state.edit}
                        disabled={this.state.disabled}
                        isAdminPage={this.isAdminPage}
                    />
                    
                    <ProposedInternErrorList errors={errors} />

                    { !this.props.isAdmin &&
                        <div className="submit-btn-wrapper">
                            <Link to={{pathname: this.props.isWithin ? '/application/within/employerInformation/' : '/application/outside/employerInformation/', state: {applicationId: this.applicationId}}}>
                                <OutlineButton text={"Previous: Employer Information"}/>
                            </Link>
                            <SolidButton type={ButtonTypes.submit} text={"Next: Training Plan"}/>
                        </div>
                    }

                    {
                        this.isAdminPage && this.state.edit ?
                        <div className='edit-btn-container'>
                            <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                            <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                        </div>
                        : null
                    }
                </Form>
                
                )}</Formik>
                {
                    this.isAdminPage && !this.state.edit ?
                    <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                    : null
                }
            </div>
        );
    }
}

export default withRouter(ProposedInternshipForm);