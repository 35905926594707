import React from 'react';
import './DocumentUploader.css'
import { TiDelete } from 'react-icons/ti'

interface Props {
    isAdmin?: boolean,
    userId: number,
    onAttachmentsAdded: (paystubs: Array<File>) => void,
    edit: boolean,
    disabled: boolean,
    isAdminPage: boolean,
    attachments?: any
}

export default class InternshipDocuments extends React.Component<Props, any> {
    constructor(props: any) {
        super(props);
        this.handleFilesSelected = this.handleFilesSelected.bind(this);

        this.state = {
            attachments: this.props.attachments,
        };
    }

    componentDidUpdate() {
        if (this.state.attachments !== this.props.attachments) {
            this.setState({attachments: this.props.attachments});
        }
    }

    handleFilesSelected(selectedFiles: FileList | null) {
        if (selectedFiles) {
            const currentList = this.state.attachments;
            // Merge new file list with existing file list
            let mergedList = [...currentList, ...Array.from(selectedFiles)];

            // Remove duplicates by filename
            mergedList = mergedList.filter((item, index, self) => {
                return index === self.findIndex((file) => (
                    file.name === item.name
                ))
            });

            this.setState({ attachments: mergedList });
            this.props.onAttachmentsAdded(mergedList);
        }
    }

    handleRemoveFile(removeFile: File) {
        const currentList = this.state.attachments
        
        if (removeFile){
            let list = [...currentList];
            let filteredList = list.filter((item, index, self) => {
                return (item.name !== removeFile.name)
            }); 

            this.setState({ attachments: filteredList });
            this.props.onAttachmentsAdded(filteredList);
        }
    }

    attachmentList() {
        const currentList = this.state.attachments
        
        if (this.props.disabled) {
            return currentList.map((file: any, index: any) => (
                <div className="attachment" key={file.name}><a href={file.path} download={file.path} target="_blank" rel="noopener noreferrer" 
                onClick={() => { }}>{file.name}</a></div>
            ))
        } else {
            return currentList.map((file: any, index: any) => (
                <div className="attachment" key={file.name} onClick={() => {this.handleRemoveFile(file)}}>{file.name} <div className="x"><TiDelete/></div> </div>
            ))
        }
    }

    render() {
        return (
            <div className="internship-container">
                <div>
                    { 
                        this.state.attachments.length === 0 &&
                        <div className="document-upload-title">
                            Please attach documents here
                        </div>
                    }

                    <div className="document-upload-list">{this.attachmentList()}</div>

                    {   
                        !this.props.disabled &&
                        <div className="custom-file-input">
                            <label>
                                <input 
                                    type="file" 
                                    multiple 
                                    ref={this.state.fileInput} 
                                    onChange={(e) => this.handleFilesSelected(e.target.files)} 
                                />
                                Attach documents
                            </label>
                        </div>
                    }
                </div>
            </div>
        );
    }
}