import React from 'react';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import { SubFormDetailedJustification, SubFormMonthlyAdvance, SubFormOther, SubFormTraining, SubFormTravel, SubFormWages } from '../../index'

interface Props {
    isAdmin?: boolean
    claimAdvance?: any
    isAdminPage: boolean
    edit: boolean
    values: any
}

export default class AdvanceJustification extends React.Component<Props> {
    render() {
        return (
            <>
                <BlueHeaderSeparator title={this.props.isAdminPage ? "" : "Step 1."} subtitle={"Advance Justification"} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                a) Please fill in the expected monthly advance amounts and break down of expected costs.
                    </span>
                        </div>
                    </div>
                }
                <SubFormMonthlyAdvance isAdmin={this.props.isAdmin} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                b) Please fill in all wage information for the intern(s) so
                                that is thoroughly justifies the amount you are requesting for
                                an advance for this quarter.
                    </span>
                        </div>
                    </div>
                }
                <SubFormWages isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                c) Please fill in all training  information that you are claiming
                                over this claim period. If you were not pre-approved upon applying
                                for the program for training please ignore this section.
                        <span className="important-info"> Note any training filled in should
                        be supported by invoices come next quarter.</span>
                            </span>
                        </div>
                    </div>
                }
                <SubFormTraining text={"Advance"} isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                d) Please fill in all direct costs related to travel for the intern(s) so
                                that is thoroughly justifies the amount you are requesting
                                for an advance for this quarter.
                        <span className="important-info"> Note any training filled in will have
                        to be supported by invoices come next quarter.</span>
                            </span>
                        </div>
                    </div>
                }
                <SubFormTravel isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                e) Please fill in all other direct costs not including travel for the
                                intern(s) so that is thoroughly justifies the amount you are
                                requesting for an advance for this quarter.
                        <span className="important-info"> Note any training filled in will
                        have to be supported by invoices come next quarter. </span>
                            </span>
                        </div>
                    </div>
                }
                <SubFormOther text={"Advance"} isAdmin={this.props.isAdmin} isAdminPage={this.props.isAdminPage} edit={this.props.edit} />

                { !this.props.isAdmin &&
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <span className="block-span">
                                f) Please provide a written justification for the advance you are requesting.
                            </span>
                        </div>
                    </div>
                }
                <SubFormDetailedJustification value={this.props.values.detailedJustification} isAdmin={this.props.isAdmin} />
            </>
        );
    }
}