import React from 'react';
import _ from 'lodash';
import './ErrorList.css'

interface Props {
    errors: any
}

export default function ProposedInternErrorList(props: Props) {
    const errorListBuilder = (array: any) => {
        return array.map((item: any, index: any) => {
            if (item) {
                return (
                    <div>
                        <div className='err-msg-sub-header'>Form {index + 1}</div>
                        {item && item?.jobTitle && <div className='err-msg'>1. Job Title</div>}
                        {item && item?.endDate && <div className='err-msg'>3. Proposed end date of internship</div>}
                        {item && item?.description && <div className='err-msg'>13. Job Description, Work Plan and Mentoring Arrangement</div>}
                    </div>
                )
            }
        })
    }

    return (
        <div>
            {_.isEmpty(props.errors) ? null : "The below fields are Missing or are filled out incorrectly"} 
            {_.isEmpty(props.errors) ? null : errorListBuilder(props.errors.forms)}
        </div>
    )

}