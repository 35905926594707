import React from 'react';
import { Link, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import FormHeader from '../../../components/FormHeader/FormHeader';
import FormInput from '../../../components/FormInput/FormInput';
import OutlineButton from '../../../components/OutlineButton/OutlineButton';
import SolidButton from '../../../components/SolidButton/SolidButton';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import axios from "../../../http-common";
import emailjs from "emailjs-com";
import { init } from 'emailjs-com';
import { register } from '../../../firebase';
import DeclarationErrorList from '../../../components/ErrorList/DeclarationErrorList';

enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset"
} 

interface Props{
    isWithin: boolean
    isAdmin?: boolean
    application?: any
    location: {
        state: any
    }
}

interface State{
    redirect: boolean
    edit: boolean
    disabled: boolean
    email: any
    formKey: number
}

class DeclarationForm extends React.Component<Props & RouteComponentProps, State> {
    applicationId: number = 0;
    titleText: string = "outside";
    minDate: Date = new Date(2021, 0, 1);
    isAdminPage: any = window.location.pathname.includes('/admin/') || window.location.pathname.includes('/myforms/');

    initialValues = {
        employerName: '',
        employerTitle: '',
        employerSignature: '',
        date: ''
    }

    validationSchema = Yup.object().shape({
        employerName: Yup.string().required("*This field is required."),
        employerTitle: Yup.string().required("*This field is required."),
        employerSignature: Yup.string().required("*This field is required."),
        date: Yup.date().required("*This field is required.").min(this.minDate, "Date must be 2021 or later")
    });

    constructor(props: any){
        super(props);

        if(this.props.isWithin) {
            this.titleText = "for"
        }
        
        // ApplicationId is not available (and not required) if isAdmin=true
        this.applicationId = this.props.isAdmin ? this.props.application.id : this.props.location.state.applicationId;

        if (this.props.isAdmin) {
            const { Declarations } = this.props.application;

            if (Declarations != null && Declarations.length > 0) {
                const {
                    employerName,
                    employerTitle,
                    employerSignature,
                    date } = Declarations[0];

                this.initialValues = {
                    employerName: employerName,
                    employerTitle: employerTitle,
                    employerSignature: employerSignature,
                    date: date
                }
            }
        }


        this.state = {
            redirect: false,
            edit: false, 
            disabled: this.isAdminPage ? true : false,
            email: window.sessionStorage.getItem("email"),
            formKey: 0,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit( data: any ) {
        if (this.isAdminPage) {
            if (window.confirm("Click 'OK' to save your edits")) {
                this.submitForm(data)
            }
        } else {
            this.submitForm(data)
        }
    }

    submitForm(data: any) {
        data.applicationId = this.applicationId;
        
        if (this.isAdminPage) {
            axios.post("/declarations/update/"+this.applicationId, data).then((response) => {
                this.setState({edit: false, disabled: true})
            })
        } else {
            register(this.state.email).then(() => {
                axios.post("/declarations", data).then((response) => {
                    init("user_oPeZQ3GPWqFkyMWPuU0vN");

                    var templateParams = {
                        to_name: "DS4Y administrator",
                        from_name: data.employerName,
                        message: "http://ds4y.pinnguaq.com/admin/application/" + data.applicationId
                    }

                    emailjs.send("service_30vfdxi", "template_hck975t", templateParams);
                    this.setState({redirect: true});
                    sessionStorage.clear()
                })
            })
        }
    }

    handleEdit() {
        if (!this.state.edit) {
            this.setState({edit: true, disabled: false})
        } else {
            if (window.confirm("Cancel Edit?")) {
                this.setState({edit: false, disabled: true, formKey: Math.random()})
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to = 
                {{
                    pathname: '/application/complete', state: { applicationId: this.applicationId }
                }} 
            />
        }
        
        return (
            <div>
                <Formik 
                    initialValues={this.initialValues} 
                    onSubmit={this.onSubmit} 
                    key={this.state.formKey}
                    validationSchema={this.validationSchema}
                >{({errors}) => (
                    <Form>
                    { !this.props.isAdmin &&
                        <FormHeader subtitle={'Internship Host Organization (IHO) Application'} subtitle2={'('+this.titleText+' territories)'}/>
                    }
                    <BlueHeaderSeparator title={"Declaration"}/>
                    
                    <div className="form-inner-section-container">
                        <div className="form-inner-section">
                            <div className="margin-item">
                                <strong>
                                I hereby certify that the information in this application is 
                                true and correct to the best of my knowledge and belief.
                                </strong>
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Employer Name"} name={'employerName'} disabled={this.state.disabled} />
                                <FormInput label={"Employer Title"} name={'employerTitle'} disabled={this.state.disabled} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Employer Signature"} name={'employerSignature'} disabled={this.state.disabled} />
                                <FormInput label={"Date"} type={'date'} name={'date'} disabled={this.state.disabled} />
                            </div>
                            <div className="margin-item">
                                <i>
                                The Digital Skills for Youth Program is a non-repayable contribution program which aims to combine valuable work experience and training related to
                                digital skills for underemployed recent post-secondary graduates so that they can successfully transition and participate in the new digital economy and
                                is delivered under the Career Focus (CF) stream of the Government of Canada’s Youth Employment Strategy (YES). The Pinnguaq Association is an
                                approved Recipient of the Digital Skills for Youth Program.
                                </i>
                            </div>
                        </div>
                    </div>

                    <DeclarationErrorList errors={errors} />

                    { !this.props.isAdmin &&
                        <div className="submit-btn-wrapper">
                            <Link to={{pathname: this.props.isWithin ? '/application/within/trainingPlan': '/application/outside/trainingPlan', state: {applicationId: this.applicationId}}}>
                                <OutlineButton text={"Previous: Training Plan"}/>
                            </Link>
                            <SolidButton type={ButtonTypes.submit} text={"Submit"}/>
                        </div>
                    }

                    {
                        this.isAdminPage && this.state.edit ?
                        <div className='edit-btn-container'>
                            <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                            <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                        </div>
                        : null
                    }
                    </Form>
                )}</Formik>
                {
                    this.isAdminPage && !this.state.edit ?
                    <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                    : null
                }
            </div>
        );
    }
}

export default withRouter(DeclarationForm);