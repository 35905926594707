import React, { useEffect, useRef } from 'react';
import AddRow from '../../../../components/AddRow/AddRow';
import '../table.css';
import './employeeTable.css';
import { useFormikContext, FieldArray, Field } from 'formik';
import NumberFormat from "react-number-format";
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';

export default function EmployeeTable(props: any) {
    useEffect(() => {
        initializeValues()
    })

    const initialValuesWages = {
        periodFrom: '',
        periodTo: '',
        hourlyRate: 0,
        totalHoursPaid: 0,
        grossAmount: 0,
        mercs: 0,
        cilob: 0,
        totalSalary: 0,
        totalSalaryClaimed: 0,
        sharingRation: 0
    }

    const values: any = useFormikContext().values;
    const handleChange: any = useFormikContext().handleChange;
    
    const totalValues = useRef({
        grossAmount: 0,
        cilob: 0,
        totalSalary: 0,
        totalSalaryClaimed: 0,
    })

    const updateGrossAmount = () => {
        let total = 0;
        const employees = values.employeeForms;
        employees.forEach((element: any) => {
            const wages = element.wages;

                wages.forEach((element: any) => {
                    element.grossAmount = element.hourlyRate * element.totalHoursPaid;
                    total+=element.grossAmount;
                });
        });
        totalValues.current.grossAmount = total;
        props.updateValues(totalValues.current);
    }

    const updateCilob = () => {
        let total = 0;
        const employees = values.employeeForms;
        employees.forEach((element: any) => {
            const wages = element.wages;
                wages.forEach((element: any) => {
                    element.cilob = element.grossAmount * (element.mercs/100);
                    total+=element.cilob;
                });
        });
        totalValues.current.cilob = total;
        props.updateValues(totalValues.current);
    }

    const updateTotalSalary = () => {
        let total = 0;
        const employees = values.employeeForms;
        employees.forEach((element: any) => {
            const wages = element.wages;

                wages.forEach((element: any) => {
                    element.totalSalary = element.grossAmount + element.cilob;
                    total+=element.totalSalary;
                });
        });
        totalValues.current.totalSalary = total;
        props.updateValues(totalValues.current);
    }

    const updateTotalSalaryClaimed = () => {
        let total = 0;
        const employees = values.employeeForms;
        employees.forEach((element: any) => {
            const wages = element.wages;
                wages.forEach((element: any) => {
                    const totalSalaryClaimed = element.totalSalaryClaimed ?
                        parseFloat(element.totalSalaryClaimed) :
                        0;
                    total += totalSalaryClaimed;
                    element.sharingRation =
                        element.totalSalary <= 0 ? 0
                        : (totalSalaryClaimed / element.totalSalary) * 100;
                });
        });
        totalValues.current.totalSalaryClaimed = total;
        props.updateValues(totalValues.current);
    }

    const initializeValues = () =>{
        updateGrossAmount();
        updateCilob();
        updateTotalSalary();
        updateTotalSalaryClaimed();
    }

    return (
        <FieldArray
            name={`employeeForms.${props.employeeIndex}.wages`}
            render={arrayHelpers => (
            <>
            <div className="custom-table employee-table">
                <div className="custom-table-cell header" style={{padding: "5px 0"}}>
                    <div className="table-cell-subdiv-wrapper">
                        <div className="table-cell-subdiv">        
                            <div className="custom-table-cell readonly" style={{gridArea:"header"}}>Time Period Covered by Days Worked</div>
                            <div className="custom-table-cell readonly" style={{gridArea:"sub1"}}>From</div>
                            <div className="custom-table-cell readonly" style={{gridArea:"sub2"}}>To</div>
                        </div>
                    </div>     
                </div>   
                <div className="custom-table-cell header readonly">Hourly Rate</div>
                <div className="custom-table-cell header readonly">Total Hours Paid</div>
                <div className="custom-table-cell header readonly">Gross Amount</div>
                <div className="custom-table-cell header readonly">* MERC'S (%)</div>
                <div className="custom-table-cell header readonly">Added in Lieu of Benefits</div>
                <div className="custom-table-cell header readonly">Total Salary</div>
                <div className="custom-table-cell header readonly">Total Salary claimed to DS4Y (max 100%)</div>
                <div className="custom-table-cell header readonly">DS47 Sharing Ration %</div>
                <div className="custom-table-cell header readonly remove" />

                {
                    values.employeeForms[props.employeeIndex].wages &&
                    values.employeeForms[props.employeeIndex].wages.map((item: any, index: number) => (
                        <>
                        <div className="custom-table-cell">
                            <div className="custom-table-cell">
                                <Field max={'9999-12-31'} type="date" name={`employeeForms.${props.employeeIndex}.wages.${index}.periodFrom`} disabled={props.isAdmin}/>
                            </div>
                            <div className="custom-table-cell">
                                <Field max={'9999-12-31'} type="date" name={`employeeForms.${props.employeeIndex}.wages.${index}.periodTo`} disabled={props.isAdmin}/>
                            </div>
                        </div>
                        <div className="custom-table-cell">
                            <span>$</span>
                            <NumberFormat
                                className={props.isAdmin ? "fill" : ""}
                                displayType={props.isAdmin ? "text" : "input"}
                                name={`employeeForms.${props.employeeIndex}.wages.${index}.hourlyRate`}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                    updateGrossAmount()
                                }}
                                value={item.hourlyRate} />
                        </div>
                        <div className="custom-table-cell">
                            <Field
                                type="number"
                                name={`employeeForms.${props.employeeIndex}.wages.${index}.totalHoursPaid`}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                    updateGrossAmount()
                                }}
                                disabled={props.isAdmin}/>
                        </div>
                        <div className="custom-table-cell readonly">
                            <span>$</span>
                            <NumberFormat
                                className="fill"
                                displayType={"text"}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={item.grossAmount} />
                        </div>
                        <div className="custom-table-cell">
                            <NumberFormat
                                className={props.isAdmin ? "fill" : ""}
                                displayType={props.isAdmin ? "text" : "input"}
                                name={`employeeForms.${props.employeeIndex}.wages.${index}.mercs`}
                                isNumericString={true}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                }}
                                value={item.mercs} />
                            <span>%</span>
                        </div>
                        <div className="custom-table-cell readonly">
                            <span>$</span>
                            <NumberFormat
                                className="fill"
                                displayType={"text"}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={item.cilob} />
                        </div>
                        <div className="custom-table-cell readonly">
                            <span>$</span>
                            <NumberFormat
                                className="fill"
                                displayType={"text"}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={item.totalSalary} />
                        </div>
                        <div className="custom-table-cell">
                            <span>$</span>
                            <NumberFormat
                                className={props.isAdmin ? "fill" : ""}
                                displayType={props.isAdmin ? "text" : "input"}
                                name={`employeeForms.${props.employeeIndex}.wages.${index}.totalSalaryClaimed`}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                onKeyUp={(e: any) => {
                                    handleChange(e)
                                    updateTotalSalaryClaimed()
                                }}
                                value={item.totalSalaryClaimed} />
                        </div>
                        <div className="custom-table-cell readonly">
                            <NumberFormat
                                className="fill"
                                displayType={"text"}
                                isNumericString={true}
                                decimalScale={2}
                                value={item.sharingRation} />
                            <span>%</span>
                        </div>
                        <div className="custom-table-cell header remove">
                            <div hidden={index === 0 || props.isAdmin} onClick={() => {arrayHelpers.remove(index)}}>
                                <RemoveItem/>
                            </div>                    
                        </div>
                        </>
                    ))
                }
            </div>

            { !props.isAdmin &&
                <div onClick={() => {arrayHelpers.push(initialValuesWages)}}>
                    <AddRow text={"employee"}/>
                </div>
            }
            </>
        )}/>
    );
}