import React from 'react';
import '../subForm.css';
import TextArea from '../../../../components/TextArea/TextArea';

interface Props {
    isAdmin?: boolean
    value: any
}

export default class SubFormDetailedJustification extends React.Component<Props> {
    render() {
      return (
        <div className='scroll-container'>
          <div className="sub-forms-container">
            <div className="sub-form">
              <h1 style={{marginBottom:"20px"}}>Detailed Justification for Advance</h1>
              <div className='print-textarea'>{this.props.value}</div>
              <TextArea name={'detailedJustification'} disabled={this.props.isAdmin}/>
            </div>
          </div>
        </div>
      );
    }
}