import { useFormikContext } from 'formik';
import React from 'react';
import FormInput from '../../../../components/FormInput/FormInput';
import '../subForm.css';

export default function SubFormInformation(props: any) {
    const values: any = useFormikContext().values;
    const handleChange: any = useFormikContext().handleChange;
    const handleBlur: any = useFormikContext().handleBlur;

    return (
        <div className='scroll-container'>
            <div className="sub-forms-container">
                <div className="sub-form">
                    <h1>Information</h1>
                    <div className="sub-form">
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Project Start Date"} type={'date'} name={`information.projectStartDate`} disabled={props.isAdmin} />
                                <FormInput label={"Project Completion Date"} type={'date'} name={`information.projectCompletionDate`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Name of Organization"} name={`information.organizationName`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Address, Street, Unit Number, etc."} name={`information.address`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"City"} name={`information.city`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <div className="custom-form-input">
                                    <label>Province</label>
                                    <select
                                        name="information.province"
                                        value={values.information.province}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ maxHeight: 45}}
                                        disabled={props.isAdmin}>

                                        <option value="AB" label="Alberta" />
                                        <option value="BC" label="British Columbia" />
                                        <option value="MB" label="Manitoba" />
                                        <option value="NB" label="New Brunswick" />
                                        <option value="NL" label="Newfoundland and Labrador" />
                                        <option value="NT" label="Northwest Territories" />
                                        <option value="NS" label="Nova Scotia" />
                                        <option value="NU" label="Nunavut" />
                                        <option value="ON" label="Ontario" />
                                        <option value="PE" label="Prince Edward Island" />
                                        <option value="QC" label="Quebec" />
                                        <option value="SK" label="Saskatchewan" />
                                        <option value="YT" label="Yukon" />
                                    </select>
                                </div>
                                <FormInput label={"Postal Code"} name={`information.postalCode`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Contact Name"} name={`information.contactName`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Telephone Number"} name={`information.phoneNumber`} disabled={props.isAdmin} />
                                <FormInput label={"Contact Email"} name={`information.email`} disabled={props.isAdmin} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}