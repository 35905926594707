import React from 'react';
import { Link, Redirect } from "react-router-dom";
import SolidButton from '../../../components/SolidButton/SolidButton';
import Title from '../../../components/Title/Title';
import './welcome.css';
import UserMenu from '../../../components/UserMenu/UserMenu';
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import axios from "../../../http-common";

interface State{
  redirect: boolean,
  loginButton: boolean,
  isLoggedIn: boolean
}

export default class Welcome extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      redirect: false,
      loginButton: false,
      isLoggedIn: false
    };
  }

  componentWillMount() {
    onAuthStateChanged(auth, (user) => {
      if (window.location.pathname.endsWith('welcome')) {
        this.props.history.push("/welcome");
      }
    });
  }

  navigateClaims = () => {
    if (auth.currentUser) {
      axios.get("/users/" + auth.currentUser.uid).then((response) => {
        const userFk = response.data.userId;
  
        this.props.history.push({
          pathname: '/claims',
          state: { userFk: userFk }
        });
      })
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to=
        {{
          pathname: "/welcome/login",
          state: {
            loginButton: true
          }
        }}
      />
    }

    return (
      <div className = "inner-container welcome">
        <Title/>
        <div>What do you want to do today?</div>
        <Link to="/welcome/application"><SolidButton text="Fill out the application form" /></Link>

        {
          auth.currentUser === null ?
          <Link to="/welcome/login"><SolidButton text="Make a claim and/or an advance" /></Link>
          :
          <div onClick={() => this.navigateClaims()}><SolidButton text="Make a claim and/or an advance" /></div>
        }

        {
          auth.currentUser === null ? null :
          <Link to="/myforms/claims"><SolidButton text="My Forms" /></Link>
        }

        {
          auth.currentUser === null ?
          <div className="loginButton" onClick={() => this.setState({redirect: true, loginButton: true})}><SolidButton text="Login"></SolidButton></div>
          :
          <UserMenu email={auth.currentUser.email!} />
        }
      </div>
    );
  }
}