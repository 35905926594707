import React from 'react';
import './UserMenu.css'
import { TiUser } from 'react-icons/ti'
import { logout } from "../../firebase";

interface Props {
    email: string 
}

export default class SaveButton extends React.Component<Props, any> {
    signOut() {
        if (window.confirm("Click 'OK' to sign out.")) {
            logout();
        }
    }

    render() {
        return (
            <div className="usermenu-btn-container" onClick={this.signOut}>
                <span>{this.props.email}</span>
                <div className="usermenu-btn">
                <TiUser />
                </div>
            </div>
        );
    }
}