import React from 'react';
import BackButton from '../../../components/BackButton/BackButton';
import { Link } from "react-router-dom";
import './form.css'

interface Props{
    component: any,
    backLink?: string
}

export default class Form extends React.Component<Props> {
    render() {
      return (
          <div className="form">
            <div className="form-container">
              <Link to={this.props.backLink ? this.props.backLink : ""}><BackButton/></Link>
              {this.props.component}
            </div>
          </div>
      );
    }
}