import React, { useState, useEffect } from 'react';
import '../subForm.css';
import Separator from '../../../../components/Separator/Separator'
import FormInput from '../../../../components/FormInput/FormInput'
import { EmployeeTable, EmployeeTotalTable } from '../../../index'
import AddAnother from '../../../../components/AddAnother/AddAnother';
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';
import { useFormikContext, FieldArray } from 'formik';
import _ from 'lodash';

interface Props {
  isAdmin?: boolean
  claimAdvance?: any
  isAdminPage: boolean
  edit: boolean
}

export default function SubFormWages(props: Props) {
  useEffect(() => {
    if (_.isEqual(initialValues, values.employeeForms[0])) {
      setIsEqual(true)
    }
  }, [])

  const initialValuesWages = {
    periodFrom: '',
    periodTo: '',
    hourlyRate: 0,
    totalHoursPaid: 0,
    grossAmount: 0,
    mercs: 0,
    cilob: 0,
    totalSalary: 0,
    totalSalaryClaimed: 0,
    sharingRation: 0
  }

  const initialValues = {
    name: '',
    title: '',
    wages: [initialValuesWages] 
  }

  let values: any = useFormikContext().values;
  const [grossAmount, setGrossAmount] = useState(0);
  const [mercs, setMercs] = useState(0);
  const [cilob, setCilob] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);
  const [totalSalaryClaimed, setTotalSalaryClaimed] = useState(0);
  const [sharingRation, setSharingRation] = useState(0);
  const [isEqual, setIsEqual] = useState(false);
  const [enable, setEnable] = useState(false);
  const [isEmpty, setIsEmpty] = useState(window.sessionStorage.getItem("emptyWage") === "true" ? true : false)

  if (isEqual && props.isAdminPage && !enable || isEmpty) {
    values.employeeForms = []
  }

  const totalValues = {
    grossAmount: grossAmount,
    mercs: mercs,
    cilob: cilob,
    totalSalary: totalSalary,
    totalSalaryClaimed: totalSalaryClaimed,
    sharingRation: sharingRation
  }

  const updateValues = (values: any) => {
    setGrossAmount(values.grossAmount)
    setMercs(values.mercs)
    setCilob(values.cilob)
    setTotalSalary(values.totalSalary)
    setTotalSalaryClaimed(values.totalSalaryClaimed)
    setSharingRation(values.sharingRation)
  }

  const addNewRow = (helper: any) => {
    setEnable(true)
    setIsEmpty(false)
    helper.push(initialValues)
  }
  
  return (
    <div className='scroll-container'>
      <div className="sub-forms-container">
        <div className="sub-form">
          <FieldArray
            name="employeeForms"
            render={arrayHelpers => (
            <>
              <h1>Wages</h1>
              {
                values.employeeForms.length === 0 ? null :
                <div className="sub-form">
                  {
                    values.employeeForms.map((item: any, index: number) => (
                      <div className="sub-form-sub-container-wrapper" key={index}>
                        { !props.isAdmin &&
                          <div onClick={() => {arrayHelpers.remove(index)}}>
                            <RemoveItem/>
                          </div>
                        }

                        <div className="sub-form-sub-container">
                          <FormInput label={"Employee Name"} name={`employeeForms.${index}.name`} disabled={props.isAdmin} />
                          <FormInput label={"Title"} name={`employeeForms.${index}.title`} disabled={props.isAdmin} />
                        </div>
                      
                        <EmployeeTable employeeIndex={index} updateValues={updateValues} isAdmin={props.isAdmin}/>
                        <div style={{marginTop:"20px", marginLeft:"-30px", marginRight:"-30px"}}><Separator/></div>
                      </div>
                    ))
                  } 
                </div>
              }

              { 
                !props.isAdmin &&
                  <div style={{marginBottom:"30px"}} onClick={() => addNewRow(arrayHelpers)}>
                    <AddAnother text={"employee"}/>
                  </div>
              }
            </>           
            )}
          />

          <EmployeeTotalTable values={totalValues}/>
        </div>
      </div>
    </div>
  );
}