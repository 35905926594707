import React from 'react';
import { Link } from 'react-router-dom';
import SolidButton from '../../../components/SolidButton/SolidButton';
import Title from '../../../components/Title/Title';
import './complete.css'

export default class CompleteForm extends React.Component<any> {
    render() {
      return (
        <div className="full-screen-centered">
          <Title text={this.props.text}/>
          <h2>Your submission has been added to the waitlist and we will be in contact once we have more information available.</h2>
          <div className="btn-wrapper">
            <Link to="/welcome"><SolidButton text={"Take me back to the welcome page"}/></Link>
          </div>
        </div>
      );
    }
}