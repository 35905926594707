import React from 'react';
import {TiArrowLeftThick} from 'react-icons/ti';
import './BackButton.css';

export default class BackButton extends React.Component{
  render() {
    return (
          <div className="back-btn"><TiArrowLeftThick/><span>Back</span></div>
    );
  }
}