import React from 'react';
import { TiDelete, TiPlus } from 'react-icons/ti';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';

export default class AdvanceInstructions extends React.Component {
    render() {
      return (
        <div>
            <BlueHeaderSeparator title={"Introduction"}/>
            <div className="form-inner-section-container">
                <div className="form-inner-section">
                    <span className="block-span">
                    Please submit an advance if you would like to recieve 85% of your funds prior to actually incurring any costs during this
                    quarter. 
                    </span>
                    <span className="block-span">
                    Please provide a mathematical justification for any advances made. 
                    </span>
                    <span className="block-span" style={{ display: "inline-flex", alignItems: "center" }}>
                        Tap the &nbsp;<TiPlus className="add-another-icon" />&nbsp; button to append additional rows.
                    </span><br />
                    <span className="block-span" style={{ display: "inline-flex", alignItems: "center" }}>
                        Tap the &nbsp;<TiDelete className="remove-item-icon" />&nbsp; button to remove any empty rows or attachments.
                    </span>
                    <span className="important-info block-span" style={{display:"block", marginTop: "50px"}}>
                    Please follow the instructions below:
                    </span>
                </div>
            </div>
        </div>
      );
    }
}