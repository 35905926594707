import React from 'react';
import { TiDelete } from 'react-icons/ti'
import './RemoveItem.css'

export default class RemoveItem extends React.Component {
    render() {
      return (
          <div className="remove-item">
              <div className="remove-item-content">
                <TiDelete className="remove-item-icon"/>
              </div>
          </div>
      );
    }
}