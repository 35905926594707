import React from 'react';
import _ from 'lodash';
import './ErrorList.css'

interface Props {
    errors: any
}

export default function AdvanceErrorList(props: Props) {
    const {
        detailedJustification,
        employeeForms,
        internForms,
        otherForms,
        travelForms
    } = props.errors
    
    return (
        <>
            {_.isEmpty(props.errors) ? null : "The below fields are Missing or are filled out incorrectly"}

            <div className='error-container'>
                <div>
                    {employeeForms && <div className='err-msg-header'>Wage Form</div>}
                    {
                        employeeForms &&
                        employeeForms.map((item: any, index: any) => {
                            return (
                                <>
                                    <div className='err-msg-sub-header'>Form {index + 1}</div>
                                    {item && item.name && <div className='err-msg'>Employee Name</div>}
                                    {item && item.title && <div className='err-msg'>Title</div>}
                                    {
                                        item && item.wages &&
                                        item.wages.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    {item && <div className='err-msg-sub-header'>Row {index + 1}</div>}
                                                    {item && item.periodFrom && <div className='err-msg'>Time Period From</div>}
                                                    {item && item.periodTo && <div className='err-msg'>Time Period To</div>}
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
                <div>
                    {internForms && <div className='err-msg-header'>Training Form</div>}
                    {
                        internForms &&
                        internForms.map((item: any, index: any) => {
                            return (
                                <>
                                    <div className='err-msg-header'>Form {index + 1}</div>
                                    {item && item.name && <div className='err-msg'>Intern's Name</div>}
                                    {
                                        item && item.training &&
                                        item.training.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    {item && <div className='err-msg-sub-header'>Row {index + 1}</div>}
                                                    {item && item.provider && <div className='err-msg'>Training Provider</div>}
                                                    {item && item.type && <div className='err-msg'>Type of Training</div>}
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
                <div>
                    {travelForms && <div className='err-msg-header'>Travel Form</div>}
                    {
                        travelForms &&
                        travelForms.map((item: any, index: any) => {
                            return (
                                <>
                                    <div className='err-msg-sub-header'>Form {index + 1}</div>
                                    {item && item.name && <div className='err-msg'>Name of Traveller</div>}
                                    {item && item.description && <div className='err-msg'>Item / Description</div>}
                                    {item && item.reason && <div className='err-msg'>Reason for Travel</div>}
                                    {item && item.dateFrom && <div className='err-msg'>Date of Travel From</div>}
                                    {item && item.dateTo && <div className='err-msg'>Date of Travel To</div>}
                                    {
                                        item && item.trips &&
                                        item.trips.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    {item && <div className='err-msg-sub-header'>Row {index + 1}</div>}
                                                    {item && item.invoiceDate && <div className='err-msg'>Invoice Date</div>}
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
                <div>
                    {otherForms && <div className='err-msg-header'>Other Costs Form</div>}
                    {
                        otherForms &&
                        otherForms.map((item: any, index: any) => {
                            if (item) {
                                return (
                                    <>
                                        <div className='err-msg-sub-header'>Form {index + 1}</div>
                                        {item.name && <div className='err-msg'>Supplier</div>}
                                        {
                                            item && item.items &&
                                            item.items.map((item: any, index: any) => {
                                                return (
                                                    <>
                                                        {item && <div className='err-msg-sub-header'>Row {index + 1}</div>}
                                                        {item.description && <div className='err-msg'>Item Description</div>}
                                                        {item.invoiceDate && <div className='err-msg'>Invoice Date</div>}
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        })
                    }
                </div>
                
            </div>
            <div>
                {detailedJustification && <div className='err-msg-sub-header'>Detailed Justifaction For Advance</div>}
            </div>
        </>
    )
}