import React from 'react';
import './monthlyAdvanceTable.css';
import '../table.css';
import { useFormikContext } from 'formik';
import NumberFormat from "react-number-format";

export default function MonthlyAdvanceTable(props:any) {

    const values: any = useFormikContext().values;
    const handleChange: any = useFormikContext().handleChange;

    const updateWages = () => {
        let total = 0;
        const wages = values.expectedCostsForms.wages;
        total = parseFloat(wages.month1) + parseFloat(wages.month2) + parseFloat(wages.month3);
        wages.total = total;
    }

    const updateTraining = () => {
        let total = 0;
        const training = values.expectedCostsForms.training;
        total = parseFloat(training.month1) + parseFloat(training.month2) + parseFloat(training.month3);
        training.total = total;
    }

    const updateTravel = () => {
        let total = 0;
        const travel = values.expectedCostsForms.travel;
        total = parseFloat(travel.month1) + parseFloat(travel.month2) + parseFloat(travel.month3);
        travel.total = total;
    }

    const updateOther = () => {
        let total = 0;
        const other = values.expectedCostsForms.other;
        total = parseFloat(other.month1) + parseFloat(other.month2) + parseFloat(other.month3);
        other.total = total;
    }

    const updateTotal = () => {
        let t = 0;
        const total = values.expectedCostsForms.total;
        t = parseFloat(total.month1) + parseFloat(total.month2) + parseFloat(total.month3);
        total.total = t;
    }

    const updateMonth = (index: number) => {
        let total = 0;
        switch(index){
            case 1:
                const wages1 = parseFloat(values.expectedCostsForms.wages.month1);
                const training1 = parseFloat(values.expectedCostsForms.training.month1);
                const travel1 = parseFloat(values.expectedCostsForms.travel.month1);
                const other1 = parseFloat(values.expectedCostsForms.other.month1);
                total = wages1 + training1 + travel1 + other1;
                values.expectedCostsForms.total.month1 = total;
                updateTotal();
                break;
            case 2:
                const wages2 = parseFloat(values.expectedCostsForms.wages.month2);
                const training2 = parseFloat(values.expectedCostsForms.training.month2);
                const travel2 = parseFloat(values.expectedCostsForms.travel.month2);
                const other2 = parseFloat(values.expectedCostsForms.other.month2);
                total = wages2 + training2 + travel2 + other2;
                values.expectedCostsForms.total.month2 = total;
                updateTotal();
                break;
            case 3:
                const wages3 = parseFloat(values.expectedCostsForms.wages.month3);
                const training3 = parseFloat(values.expectedCostsForms.training.month3);
                const travel3 = parseFloat(values.expectedCostsForms.travel.month3);
                const other3 = parseFloat(values.expectedCostsForms.other.month3);
                total = wages3 + training3 + travel3 + other3;
                values.expectedCostsForms.total.month3 = total;
                updateTotal();
                break;
        }
    }

    return (
    <>
    <div className="custom-table monthly-advance-table">
        <div className="custom-table-cell header">Cost Category</div>
        <div className="custom-table-cell header">Month 1</div>
        <div className="custom-table-cell header">Month 2</div>
        <div className="custom-table-cell header">Month 3</div>
        <div className="custom-table-cell header">Total for Period</div>

        
        <div className="custom-table-cell">
            Wages
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.wages.month1`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateWages()
                    updateMonth(1)
                }}
                onChange={(e: any) => {
                    handleChange(e)
                    updateWages()
                    updateMonth(1)
                }}
                value={values.expectedCostsForms.wages.month1}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.wages.month2`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateWages()
                    updateMonth(2)
                }}
                onChange={(e: any) =>{
                    handleChange(e)
                    updateWages()
                    updateMonth(2)
                }}
                value={values.expectedCostsForms.wages.month2}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.wages.month3`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateWages()
                    updateMonth(3)
                }}
                onChange={(e: any) => {
                    handleChange(e)
                    updateWages()
                    updateMonth(3)
                }}
                value={values.expectedCostsForms.wages.month3}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.wages.total`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.wages.total} />
        </div>

        <div className="custom-table-cell">
            Training
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.training.month1`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateTraining()
                    updateMonth(1)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.training.month1}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.training.month2`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateTraining()
                    updateMonth(2)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.training.month2}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.training.month3`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateTraining()
                    updateMonth(3)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.training.month3}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.training.total`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.training.total} />
        </div>

        <div className="custom-table-cell">
            Other Direct Costs (including Travel)
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.travel.month1`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateTravel()
                    updateMonth(1)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.travel.month1}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.travel.month2`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateTravel()
                    updateMonth(2)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.travel.month2}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.travel.month3`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateTravel()
                    updateMonth(3)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.travel.month3}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.training.total`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.travel.total} />
        </div>

        <div className="custom-table-cell">
            Other Direct Costs (NOT including Travel)
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.other.month1`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateOther()
                    updateMonth(1)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.other.month1}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.other.month2`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateOther()
                    updateMonth(2)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.other.month2}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={props.isAdmin ? "text" : "input"}
                name={`expectedCostsForms.other.month3`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                onKeyUp={(e: any) => {
                    handleChange(e)
                    updateOther()
                    updateMonth(3)
                }}
                onChange={(e: any) => handleChange(e)}
                value={values.expectedCostsForms.other.month3}
                disabled={props.isAdmin} />
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.other.total`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.other.total} />
        </div>

        <div className="custom-table-cell readonly">
            TOTAL
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.total.month1`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.total.month1} />
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.total.month2`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.total.month2} />
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.total.month3`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.total.month3} />
        </div>
        <div className="custom-table-cell readonly">
            <span>$</span>
            <NumberFormat
                className="fill"
                displayType={"text"}
                name={`expectedCostsForms.total.total`}
                isNumericString={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.expectedCostsForms.total.total} />
           </div>
    </div>
    </>
    );
}