import React from 'react';
import _ from 'lodash';
import './ErrorList.css'

interface Props {
    errors: any
}

export default function EmployeeInfoErrorList(props: Props) {
    const {
        address,
        businessNumber,
        contactEmail,
        contactName,
        contactPhone,
        description,
        doingBusinessAs,
        legalBusinessName,
        process,
        purpose,
        numInternships,
    } = props.errors

    return (
        <div>
            {_.isEmpty(props.errors) ? null : "The below fields are Missing or are filled out incorrectly"}
            {legalBusinessName && <div className='err-msg'>1. Legal Business Name</div>}
            {doingBusinessAs && <div className='err-msg'>2. Doing Business As</div>}
            {businessNumber && <div className='err-msg'>10. Business Number</div>}
            {contactName && <div className='err-msg'>11. Contact Name</div>}
            {address && <div className='err-msg'>12. Address</div>}
            {contactPhone && <div className='err-msg'>14. Contact Telephone</div>}
            {contactEmail && <div className='err-msg'>15. Contact Email</div>}
            {numInternships && <div className='err-msg'> 14. Number of internships applied for</div>}
            {description && <div className='err-msg'>18. Brief history and description of organization (including mandate)</div>}
            {purpose && <div className='err-msg'>19. Purpose of funding request</div>}
            {process && <div className='err-msg'>20. Brief description of recruitment and hiring process for interns</div>}
        </div>
    )

}