import React from 'react';
import './DocumentUploader.css'
import { TiDelete } from 'react-icons/ti'

interface Props {
    isAdmin?: boolean,
    claimAdvance?: any,
    claimAdvanceFk: number,
    onPaystubsAdded: (paystubs: Array<File>) => void,
    onInvoicesAdded: (invoices: Array<File>) => void,
    attached: {
        paystubs: any,
        invoices: any
    },
    edit: boolean,
    disabled: boolean,
    isAdminPage: boolean,
    handlePaystubEdit: any,
    handleInvoiceEdit: any
}
export default class DocumentUploader extends React.Component<Props, any> {
    tabs = [
        {
            tabText: "Paystubs",
            containerText: "all paystubs",
            uploadText: "paystubs",
            isActive: true,
            dataKey: 0
        },
        {
            tabText: "Other Invoices",
            containerText: "other invoices",
            uploadText: "invoices",
            isActive: false,
            dataKey: 1
        },
    ];

    constructor(props: any) {
        super(props);

        this.onTabClicked = this.onTabClicked.bind(this);
        this.handleFilesSelected = this.handleFilesSelected.bind(this);
        this.state = {
            currentTab: 0,
            attachedPaystubs: new Array<File>(),
            attachedInvoices: new Array<File>(),
            hostedPaystubs: new Array<string>(),
            hostedInvoices: new Array<string>(),
        };
        
        if (this.props.claimAdvance) {
            const { Attachments } = this.props.claimAdvance;

            Attachments.forEach((attachment: any) => {
                const url = new URL(attachment.path);
                const name = decodeURIComponent(url.pathname).split('/').pop()?.split('_').pop();

                if (attachment.isPaystub) {
                    this.state.hostedPaystubs.push({
                        name: name,
                        path: attachment.path
                    });
                } else {
                    this.state.hostedInvoices.push({
                        name: name,
                        path: attachment.path
                    });
                }
            });
        }
    }


    componentDidMount() {
        let attachedPaystubs = new Array<any>();
        let attachedInvoices = new Array<any>();

        if (this.props.attached){
            this.props.attached.paystubs.forEach((attachment: any) => {

                attachedPaystubs.push({
                    name: attachment.name,
                    path: attachment.path
                });

            });

            this.props.attached.invoices.forEach((attachment: any) => {
                attachedInvoices.push({
                    name: attachment.name,
                    path: attachment.path
                });

            });
        }

        if (this.props.isAdminPage) {
            this.props.handleInvoiceEdit(this.state.hostedInvoices)
            this.props.handlePaystubEdit(this.state.hostedPaystubs)
        }
        
        this.setState({attachedPaystubs: attachedPaystubs, attachedInvoices: attachedInvoices});
    }
    
    onTabClicked(event: any) {
        const tabId = parseInt(event.target.getAttribute('data-key'));
        this.setState({ currentTab: tabId });
    }

    handleFilesSelected(selectedFiles: FileList | null) {
        if (selectedFiles) {
            const currentList = this.currentList();
            // Merge new file list with existing file list
            let mergedList = [...currentList, ...Array.from(selectedFiles)];

            // Remove duplicates by filename
            mergedList = mergedList.filter((item, index, self) => {
                return index === self.findIndex((file) => (
                    file.name === item.name
                ))
            });

            // Update appropriate state
            if (this.state.currentTab === 0) {
                this.setState({ attachedPaystubs: mergedList });
                this.props.onPaystubsAdded(mergedList);

                if (this.props.isAdminPage) {
                    this.setState({hostedPaystubs: mergedList})
                    this.props.handlePaystubEdit(mergedList)
                }
            } else {
                this.setState({ attachedInvoices: mergedList });
                this.props.onInvoicesAdded(mergedList);

                if (this.props.isAdminPage) {
                    this.setState({hostedInvoices: mergedList})
                    this.props.handleInvoiceEdit(mergedList)
                }
            }
        }
    }

    handleRemoveFile(removeFile: File) {
        const currentList = this.currentList();
        
        if (removeFile){
            let list = [...currentList];
            let filteredList = list.filter((item, index, self) => {
                return (item.name !== removeFile.name)
            }); 

            if (this.state.currentTab === 0) {
                this.setState({ attachedPaystubs: filteredList });
                this.props.onPaystubsAdded(filteredList);

                if (this.props.isAdminPage) {
                    this.setState({hostedPaystubs: filteredList})
                    this.props.handlePaystubEdit(filteredList)
                }

            } else {
                this.setState({ attachedInvoices: filteredList });
                this.props.onInvoicesAdded(filteredList);

                if (this.props.isAdminPage) {
                    this.setState({hostedInvoices: filteredList})
                    this.props.handleInvoiceEdit(filteredList)
                }
            }
        }
    }

    currentList() {
        if (this.props.isAdmin) {
            return this.state.currentTab === 0 ? this.state.hostedPaystubs : this.state.hostedInvoices;
        } else {
            return this.state.currentTab === 0 ? this.state.attachedPaystubs : this.state.attachedInvoices;
        }
    }

    attachmentList() {
        const currentList = this.currentList();

        if (this.props.disabled) {
            return currentList.map((file: any, index: any) => (
                <div className="attachment" key={file.name}><a href={file.path} download={file.path} target="_blank" rel="noopener noreferrer" 
                onClick={() => { }}>{file.name}</a></div>
            ))
        } else {
            return currentList.map((file: any, index: any) => (
                <div className="attachment" key={file.name} onClick={() => {this.handleRemoveFile(file)}}>{file.name} <div className="x"><TiDelete/></div> </div>
            ))
        }
    }

    render() {
        return (
            <div className="document-uploader">
                <div className="document-uploader-tabs">
                    {this.tabs.map((object: any, i: number) => {
                        return <div className={this.state.currentTab === i ? 'document-uploader-tab active' : 'document-uploader-tab'} key={i} data-key={i} onClick={this.onTabClicked}>
                            {this.tabs[i].tabText}
                        </div>
                    })}
                </div>
                <div className="document-container">
                    <div>

                        { this.currentList().length === 0 &&
                            <div className="document-upload-title">
                                Please attach <span>{this.tabs[this.state.currentTab].containerText}</span> here
                            </div>
                        }

                        <div className="document-upload-list">
                            {
                                this.attachmentList()
                            }
                        </div>

                        {   !this.props.disabled &&
                            <div className="custom-file-input">
                                <label>
                                <input type="file" multiple ref={this.state.fileInput} onChange={(e) => this.handleFilesSelected(e.target.files)} />
                            Attach {this.currentList().length > 0 ? "more" : ""} <span>{this.tabs[this.state.currentTab].uploadText}</span>
                                </label>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}