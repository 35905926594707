import React from 'react';
import { TiDelete, TiPlus } from 'react-icons/ti';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';

export default class ClaimInstructions extends React.Component {
    render() {
      return (
        <div>
            <BlueHeaderSeparator title={"Introduction"}/>
            <div className="form-inner-section-container">
                <div className="form-inner-section">
                    <span className="block-span">
                    Please submit a claim if you have incurred any cost over your current claim period.
                    </span>
                    <span className="block-span">
                    If this is your first claim and your intern(s) commenced their internship prior to the current quarter. Please claim from the 
                    start of their internship.
                    </span>
                    <span className="block-span">
                    Any claim made MUST be supported with documentation (i.e. paystubs, invoices for training, travel, etc.).
                    </span>
                    <span className="block-span" style={{ display: "inline-flex", alignItems: "center" }}>
                    Tap the &nbsp;<TiPlus className="add-another-icon" />&nbsp; button to append additional rows.
                    </span><br/>
                    <span className="block-span" style={{ display: "inline-flex", alignItems: "center" }}>
                    Tap the &nbsp;<TiDelete className="remove-item-icon" />&nbsp; button to remove any empty rows or attachments.
                    </span>
                    <span className="important-info block-span" style={{display:"block", marginTop: "50px"}}>
                    Please follow the instructions below:
                    </span>
                </div>
            </div>
        </div>
      );
    }
}