import React from 'react';
import { Link } from "react-router-dom";
import './MyFormHeader.css'

interface Props {
    currentTab: number
}

export default class MyFormHeader extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.onTabClicked = this.onTabClicked.bind(this);
        this.state = {
            currentTab: 0
        }
    }

    onTabClicked(event: any) {
        const tabId = event.target.getAttribute('data-key');
        this.setState({ currentTab: tabId });
    }

    render() {
        return (
            <div className="myforms-header">
                <h2>Digital Skills for Youth</h2>
                <span>My Forms</span>
                <div className="myforms-header-tabs">
                    <Link to={ "/myforms/claims/" }>
                        <div className={this.props.currentTab === 0 ? "myforms-header-tab active" : "myforms-header-tab"} data-key={1} onClick={this.onTabClicked}>
                            Claims
                        </div>
                    </Link>
                    <Link to={"/myforms/advances/"}>
                        <div className={this.props.currentTab === 1 ? "myforms-header-tab active" : "myforms-header-tab"} data-key={2} onClick={this.onTabClicked}>
                            Advances
                        </div>
                    </Link>

                </div>
            </div>
        );
    }
}              