import React from 'react';
import FormInput from '../../../../components/FormInput/FormInput';
import '../subForm.css';

interface Props {
    isAdmin?: boolean,
    claimAdvance?: any,
}

export default class SubFormCertification extends React.Component<Props> {
    render() {
        return (
            <div className='scroll-container certification-print-break'>
                <div className="sub-forms-container">
                    <div className="sub-form">
                        <h1>Certification</h1>
                        <div className="sub-form">
                            I certify that:<br/>
                            a) The costs described in this claim and considered eligible were or will be incurred under the agreement (CA)<br/>
                            b) Pinnguaq's payment will be applied to the project in accordance with the agreement and costing memorandum<br/>
                            c) Generally accepted and consistently followed accounting process have been followed<br/>
                            d) All representations, warranties, and undertakings pursuant to the agreement remain applicable and in force<br/>
                            e) There are no events of default under any of the provisions of the agreement and no state of facts exist which, with the giving of the notice of passing of
                            time, or both, would constitute such an event of default.
                        </div>
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Signature of Authorized Offical"} type={"textarea"} name={`certification.signature`} disabled={this.props.isAdmin} />
                            </div>
                        </div>
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Name"} name={`certification.name`} disabled={this.props.isAdmin} />
                                <FormInput label={"Date"} type={"date"} name={`certification.date`} disabled={this.props.isAdmin} />
                            </div>
                        </div>
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Title"} name={`certification.title`} disabled={this.props.isAdmin} />
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}