import { Field } from 'formik';
import React from 'react';
import '../subForm.css';

export default function SubFormTotalRequested(props: any) {
  return (
    <div className='total-container'>
      <div className="sub-forms-container">
          <div className="sub-form">
              <div className="sub-form-sub-container" style={{paddingBottom:0}}>
                  <h1>Total Requested</h1>
                  <Field style={{flexGrow:2}} name={`totalRequested`} disabled={props.isAdmin}/>
              </div>
          </div>
      </div>
    </div>
  );
}