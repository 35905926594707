import React from 'react';
import FormInput from '../../../../components/FormInput/FormInput';
import '../subForm.css';

export default function SubFormClaim(props: any) {
    return (
        <div className='scroll-container claim-print-break'>
            <div className="sub-forms-container">
                <div className="sub-form">
                    <h1>Claim</h1>
                    <div className="sub-form">
                        <div className="sub-form-sub-container-wrapper">
                            <div className="sub-form-sub-container">
                                <FormInput label={"Amount Incurred"} type={'number'} name={`claim.amountIncurred`} disabled={props.isAdmin} />
                                <FormInput label={"Amount Paid"} type={'number'} name={`claim.amountPaid`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Quarter(s) Covered"} type={'number'} name={`claim.quartersCovered`} disabled={props.isAdmin} />
                            </div>
                            <div className="sub-form-sub-container">
                                <FormInput label={"Claim Period Start Date"} type={'date'} name={`claim.periodStartDate`} disabled={props.isAdmin} />
                                <FormInput label={"Claim Period End Date"} type={'date'} name={`claim.periodEndDate`} disabled={props.isAdmin} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}