import React from 'react';
import { Link, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import BlueHeaderSeparator from '../../../components/BlueHeaderSeparator/BlueHeaderSeparator';
import FormHeader from '../../../components/FormHeader/FormHeader';
import FormInput from '../../../components/FormInput/FormInput';
import OutlineButton from '../../../components/OutlineButton/OutlineButton';
import RadioReturnQuestion from '../../../components/RadioQuestion/RadioReturnQuestion';
import SolidButton from '../../../components/SolidButton/SolidButton';
import TextArea from '../../../components/TextArea/TextArea';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from "yup";
import axios from "../../../http-common";
import TrainingPlanErrorList from '../../../components/ErrorList/TrainingPlanErrorList';

enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset"
} 

interface Props{
    isWithin: boolean
    isAdmin?: boolean
    application?: any
    location: {
        state: any
    }
}

interface State{
    redirect: boolean
    initialValues: any
    edit: boolean
    disabled: boolean
    formKey: number
    validationSchema: any
    provideTraining: boolean
}

class TrainingPlanForm extends React.Component<Props & RouteComponentProps, State> {
    applicationId: number = 0;
    titleText: string = "outside";
    isAdminPage: any = window.location.pathname.includes('/admin/') || window.location.pathname.includes('/myforms/');

    initialValues = [{
        provideTraining: 'true',
        trainingPlan: '',
        amount: 0
    }]

    constructor(props: any){
        super(props);

        if(this.props.isWithin) {
            this.titleText = "for"
        }
        
        // ApplicationId is not available (and not required) if isAdmin=true
        this.applicationId = this.props.isAdmin ? this.props.application.id : this.props.location.state?.applicationId;

        if (this.props.isAdmin) {
            const { TrainingPlans } = this.props.application;

            if (TrainingPlans.length > 0) {
                this.initialValues.pop();

                TrainingPlans.forEach((item: any, index: number) => {
                    const {
                        provideTraining,
                        trainingPlan,
                        amount } = item;

                    this.initialValues.push({
                        provideTraining: String(provideTraining),
                        trainingPlan: trainingPlan,
                        amount: amount
                    });
                });
            }
        }

        this.state = {
            redirect: false,
            initialValues: this.initialValues,
            edit: false, 
            disabled: this.isAdminPage ? true : false,
            formKey: 0,
            provideTraining: true,
            validationSchema: Yup.object().shape({
                forms: Yup.array().of(Yup.object().shape({
                    trainingPlan: Yup.string().required("*This field is required."),
                    amount: Yup.number().required("*This field is required.").positive("*Number is invalid.")
                }))
            })
        };
        
        this.onSubmit = this.onSubmit.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
    }

    componentDidMount() {
        if (this.isAdminPage) {
            this.applicationId = this.props.application.id
        } else {
            this.applicationId = this.props.location.state?.applicationId;
        }
        
        if (this.applicationId !== undefined){
            axios.get("/Applications/" + this.applicationId).then((response) => {

                const { TrainingPlans } = response?.data;
    
                if (TrainingPlans.length > 0) {
                    this.initialValues.pop();
    
                    TrainingPlans.forEach((item: any, index: number) => {
                        const {
                            provideTraining,
                            trainingPlan,
                            amount } = item;
    
                        this.initialValues.push({
                            provideTraining: String(provideTraining),
                            trainingPlan: trainingPlan,
                            amount: amount
                        });

                        if (!item.provideTraining) {
                            this.setState({
                                provideTraining: false,
                                validationSchema: Yup.object().shape({
                                    forms: Yup.array().of(Yup.object().shape({
                                        trainingPlan: Yup.string(),
                                        amount: Yup.number()
                                    }))
                                })
                            })
                        }
                    });
                }
                
                this.setState({redirect: false, initialValues: this.initialValues})                   
            }); 
        }
    }

    onSubmit( data: any ) {
        if (this.isAdminPage) {
            if (window.confirm("Click 'OK' to save your edits")) {
                this.submitForm(data)
            }
        } else {
            this.submitForm(data)
        }
    }

    submitForm(data: any) {
        data.forms.forEach((row: any) => {
            row.applicationId = this.applicationId;
        });

        data.forms.applicationId = this.applicationId;

        axios.post("/trainingPlans/update/"+this.applicationId, data.forms).then((response) => {
            if (this.isAdminPage) {
                this.setState({edit: false, disabled: true})
            } else {
                this.setState({redirect: true});
            }
        });
    }

    handleEdit() {
        if (!this.state.edit) {
            this.setState({edit: true, disabled: false})
        } else {
            if (window.confirm("Cancel Edit?")) {
                this.setState({edit: false, disabled: true, formKey: Math.random()})
            }
        }
    }

    handleRadio() {
        if (this.state.provideTraining) {
            this.setState({
                provideTraining: false,
                validationSchema: Yup.object().shape({
                    forms: Yup.array().of(Yup.object().shape({
                        trainingPlan: Yup.string(),
                        amount: Yup.number()
                    }))
                })
            })
        } else {
            this.setState({
                provideTraining: true,
                validationSchema: Yup.object().shape({
                    forms: Yup.array().of(Yup.object().shape({
                        trainingPlan: Yup.string().required("*This field is required."),
                        amount: Yup.number().required("*This field is required.").positive("*Number is invalid.")
                    }))
                })
            })
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to = 
                {{
                    pathname: this.props.isWithin? '/application/within/declaration': '/application/outside/declaration',
                    state: { applicationId: this.applicationId }
                }} 
            />
        }

        return (
            <div className="print-break-TP-title">
                <Formik 
                enableReinitialize={true}
                initialValues={{ forms: [...this.initialValues] }} 
                onSubmit={this.onSubmit} 
                key={this.state.formKey}
                validationSchema={this.state.validationSchema}
                >{({ values, errors }) => (
                    <Form>
                        { !this.props.isAdmin &&
                            <FormHeader subtitle={'Internship Host Organization (IHO) Application'} subtitle2={'(' + this.titleText + ' territories)'} />
                        }
                        
                        <BlueHeaderSeparator title={"Training Plan"}/>
                        
                        <div className="form-inner-section-container">
                            <div className="form-inner-section">
                                <FieldArray
                                name="forms"
                                render={arrayHelpers => (
                                    <>
                                    {values.forms.map((item, index) => (
                                        <div className="sub-form-sub-container-wrapper" key={ index }>
                                            <RadioReturnQuestion 
                                                text={"1. Do you want Pinnguaq to provide training?"} 
                                                name={`forms.${index}.provideTraining`}
                                                disabled={this.state.disabled}
                                                handleRadio={this.handleRadio}
                                            />

                                            <div className="margin-item">
                                                2. Description of training plan (if different for each intern please specify)

                                                <div className='print-textarea'>{item.trainingPlan}</div>
                                                <TextArea name={`forms.${index}.trainingPlan`} disabled={this.state.disabled}/>
                                            </div>
                                            
                                            <div className="margin-item">
                                                <FormInput 
                                                    name={`forms.${index}.amount`}
                                                    label={"3. Amount of training per intern (eligible for a total of $4,000 with the option of up to $1,000 for soft-skill training)"}
                                                    disabled={this.state.disabled}
                                                    training={this.state.provideTraining}
                                                />
                                            </div>                                     
                                        </div>
                                    ))}
                                    </>
                                )}
                                />
                                
                            </div>
                        </div>
                        
                        {
                            this.state.provideTraining &&
                            <TrainingPlanErrorList errors={errors} />
                        }

                        { !this.props.isAdmin &&
                            <div className="submit-btn-wrapper">
                                <Link to={{pathname: this.props.isWithin ? '/application/within/proposedInternship' : '/application/outside/proposedInternship', state: {applicationId: this.applicationId}}}>
                                    <OutlineButton text={"Previous: Proposed Internship"} />
                                </Link>
                                <SolidButton type={ButtonTypes.submit} text={"Next: Declaration"} />
                            </div>
                        }

                    {
                        this.isAdminPage && this.state.edit ?
                        <div className='edit-btn-container'>
                            <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                            <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                        </div>
                        : null
                    }
                    </Form>
                )}</Formik>
                
                {
                    this.isAdminPage && !this.state.edit ?
                    <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                    : null
                }
            </div>
        );
    }
}

export default withRouter(TrainingPlanForm);