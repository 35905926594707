import React from 'react';
import './FormHeader.css'

interface Props{
    subtitle: string
    subtitle2?:string
}
export default class FormHeader extends React.Component<Props> {
    render() {
      return (
        <div className="form-header">
            <h2>Digital Skills for Youth</h2>
            <span>{this.props.subtitle}</span>
            <span><br/>{this.props.subtitle2}</span>
        </div>
      );
    }
}
              