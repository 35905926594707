import React from 'react';
import './adminAdvance.css';
import { withRouter } from "react-router-dom";
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import axios from "../../../http-common";
import { AdvanceForm, ACStepTwoForm } from '../..';

interface State{
    claimAdvance: any
}

class AdminAdvance extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            claimAdvance: null
        };

        axios.get("/claimAdvances/" + this.props.match.params.claimAdvanceId).then((response) => {
            this.setState({ claimAdvance: response.data });
        });
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            this.forceUpdate();
        });
    }

    boolToString(input: boolean) {
        return input ? 'Yes' : 'No';
    }

    renderFormData() {
        return (
            <div>
                <AdvanceForm isAdmin={true} claimAdvance={this.state.claimAdvance} location={{state: null}} />
                <ACStepTwoForm isAdmin={true} claimAdvance={this.state.claimAdvance} location={{state: null}} />
            </div>
        );
    }

    render() {

        if (auth.currentUser === null || this.state.claimAdvance === null) {
            return (<div />);
        }

        return (
            <div className="inner-container application">
                { this.renderFormData() }
            </div>
        );

    }
}
export default withRouter(AdminAdvance);