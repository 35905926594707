import React from 'react';
import './trainingTotalTable.css';
import '../table.css';
import NumberFormat from "react-number-format";

interface Props {
    text: string
    values: any
}

interface State {
    invoiceAmount: number
    tax: number
    gstHst: number
    totalClaimed: number
}

export default class TrainingTotalTable extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            invoiceAmount: 0,
            tax: 0,
            gstHst: 0,
            totalClaimed: 0
        };

        this.setState({
            invoiceAmount: this.props.values.invoiceAmount,
            tax: this.props.values.tax,
            gstHst: this.props.values.gstHst,
            totalClaimed: this.props.values.totalClaimed
        })
    }

    render() {
        return (
            <>
                <div className="custom-table training-total-table">
                    <div className="custom-table-cell header"></div>
                    <div className="custom-table-cell header">Invoice Amount Before Taxes</div>
                    <div className="custom-table-cell header">Provincial Tax (after Rebate)</div>
                    <div className="custom-table-cell header">GST/HST (after Rebate - 50%)</div>
                    <div className="custom-table-cell header">Total {this.props.text} Amount</div>

                    <div className="custom-table-cell">Total</div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.invoiceAmount} />
                    </div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.tax} />
                    </div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.gstHst} />
                    </div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.totalClaimed} />
                    </div>
                </div>
            </>
        );
    }
}