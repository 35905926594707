import React from 'react';
import { Link } from "react-router-dom";
import SolidButton from '../../../components/SolidButton/SolidButton';
import Title from '../../../components/Title/Title'
import UserMenu from '../../../components/UserMenu/UserMenu'
import { auth } from '../../../firebase';
import { onAuthStateChanged } from "firebase/auth"
import BackButton from '../../../components/BackButton/BackButton';
import './selectType.css'

interface State {
    redirect: boolean
}

export default class SelectType extends React.Component<any, State> {
    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                this.props.history.push("/");
            } else {
                this.forceUpdate();
            }
        });
    }

    render() {
        if (auth.currentUser == null) {
            return (<div />);
        }
        return (
            <div className="select-type">
                <Link to="/welcome"><BackButton/></Link>
                <UserMenu email={auth.currentUser.email!} />
                <Title text={"Welcome, " + auth.currentUser?.email} />
                <div className="select-type-container">
                    <div>Which form do you want to fill out today?</div>
                    <div className="btn-container">
                        <Link to={{
                            pathname: 'claims/claimStepOne',
                            state: { userFk: this.props.location.state.userFk }
                        }} onClick={() => sessionStorage.clear()}>
                            <SolidButton text={"Claim"} />
                        </Link>
                        <Link to={{
                            pathname: 'claims/advanceStepOne',
                            state: { 
                                userFk: this.props.location.state.userFk,
                                type: 'A'
                            }
                        }} onClick={() => sessionStorage.clear()}>
                            <SolidButton text={"Advance"} />
                        </Link>
                        <Link to={{
                            pathname: 'claims/claimStepOne',
                            state: { 
                                isBoth: true, 
                                userFk: this.props.location.state.userFk,
                                type: 'C'
                            }
                        }} onClick={() => sessionStorage.clear()}>
                            <SolidButton text={"Both"} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}