import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { AdvanceInstructions, AdvanceJustification } from '../..';
import FormHeader from '../../../components/FormHeader/FormHeader';
import SolidButton from '../../../components/SolidButton/SolidButton';
import UserMenu from '../../../components/UserMenu/UserMenu';
import { auth } from '../../../firebase';
import { onAuthStateChanged } from "firebase/auth"
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import axios from "../../../http-common";
import OutlineButton from '../../../components/OutlineButton/OutlineButton';
import AdvanceErrorList from '../../../components/ErrorList/AdvanceErrorList';

enum ButtonTypes {
    button = "button",
    submit = "submit",
    reset = "reset"
}

interface State {
    redirect: boolean
    initialValues: any
    edit: boolean
    disabled: boolean
    formKey: number
}

export default class AdvanceForm extends React.Component<any, State> {
    claimAdvanceId: number = 0;
    detailedJustification: string = '';
    isAdminPage: any = window.location.pathname.includes('/admin/') || window.location.pathname.includes('/myforms/');

    initialValuesExpectedCosts = {
        wages: {
            month1: 0,
            month2: 0,
            month3: 0,
            total: 0
        },
        training: {
            month1: 0,
            month2: 0,
            month3: 0,
            total: 0
        },
        travel: {
            month1: 0,
            month2: 0,
            month3: 0,
            total: 0
        },
        other: {
            month1: 0,
            month2: 0,
            month3: 0,
            total: 0
        },
        total: {
            month1: 0,
            month2: 0,
            month3: 0,
            total: 0
        }
    }

    initialValuesWages = {
        periodFrom: '',
        periodTo: '',
        hourlyRate: 0,
        totalHoursPaid: 0,
        grossAmount: 0,
        mercs: 0,
        cilob: 0,
        totalSalary: 0,
        totalSalaryClaimed: 0,
        sharingRation: 0
    }

    initialValuesEmployee = {
        name: '',
        title: '',
        wages: [this.initialValuesWages]
    }

    initialValuesTraining = {
        provider: '',
        type: '',
        startDate: '',
        invoiceDate: '',
        invoiceNum: 0,
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalClaimed: 0
    }

    initialValuesIntern = {
        name: '',
        training: [this.initialValuesTraining]
    }

    initialValuesTrips = {
        kmsTravelled: 0,
        kmRate: 0,
        invoiceNum: 0,
        invoiceDate: '',
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalExpense: 0
    }

    initialValuesTravel = {
        dateTo: '',
        dateFrom: '',
        name: '',
        description: '',
        reason: '',
        trips: [this.initialValuesTrips]
    }

    initialValuesItems = {
        description: '',
        invoiceDate: '',
        invoiceNum: 0,
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalClaimed: 0
    }

    initialValuesOthers = {
        name: '',
        items: [this.initialValuesItems]
    }

    initialValues = {
        expectedCostsForms: this.initialValuesExpectedCosts,
        employeeForms: [this.initialValuesEmployee],
        internForms: [this.initialValuesIntern],
        travelForms: [this.initialValuesTravel],
        otherForms: [this.initialValuesOthers],
        detailedJustification: ''
    }

    validationSchema = Yup.object().shape({
        expectedCostsForms: Yup.object().shape({
            wages: Yup.object().shape({
                month1: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month2: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month3: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
            }),
            training: Yup.object().shape({
                month1: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month2: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month3: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
            }),
            travel: Yup.object().shape({
                month1: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month2: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month3: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
            }),
            other: Yup.object().shape({
                month1: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month2: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                month3: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
            })
        }),
        employeeForms: Yup.array()
            .of(Yup.object().shape({
                name: Yup.string().required("*This field is required."),
                title: Yup.string().required("*This field is required."),
                wages: Yup.array()
                    .of(Yup.object().shape({
                        periodFrom: Yup.date().required("*This field is required."),
                        periodTo: Yup.date().required("*This field is required."),
                        hourlyRate: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalHoursPaid: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        grossAmount: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        mercs: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        cilob: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalSalary: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalSalaryClaimed: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        sharingRation: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                    }))
            })),
        internForms: Yup.array()
            .of(Yup.object().shape({
                name: Yup.string().required("*This field is required."),
                training: Yup.array()
                    .of(Yup.object().shape({
                        provider: Yup.string().required("*This field is required."),
                        type: Yup.string().required("*This field is required."),
                        startDate: Yup.date().required("*This field is required."),
                        invoiceDate: Yup.date().required("*This field is required."),
                        invoiceNum: Yup.string().required("*This field is required.").min(0, "*Number is invalid."),
                        tax: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        gstHst: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalClaimed: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
                    }))
            })),
        travelForms: Yup.array()
            .of(Yup.object().shape({
                dateTo: Yup.date().required("*This field is required."),
                dateFrom: Yup.date().required("*This field is required."),
                name: Yup.string().required("*This field is required."),
                description: Yup.string().required("*This field is required."),
                reason: Yup.string().required("*This field is required."),
                trips: Yup.array()
                    .of(Yup.object().shape({
                        kmsTravelled: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        kmRate: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        invoiceNum: Yup.string().required("*This field is required."),
                        invoiceDate: Yup.date().required("*This field is required."),
                        invoiceAmount: Yup.number().min(0, "*Number is invalid."),
                        tax: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        gstHst: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalExpense: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
                    }))
            })),
        otherForms: Yup.array()
            .of(Yup.object().shape({
                name: Yup.string().required("*This field is required."),
                items: Yup.array()
                    .of(Yup.object().shape({
                        description: Yup.string().required("*This field is required."),
                        invoiceDate: Yup.date().required("*This field is required."),
                        invoiceNum: Yup.string().required("*This field is required."),
                        invoiceAmount: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        tax: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        gstHst: Yup.number().required("*This field is required.").min(0, "*Number is invalid."),
                        totalClaimed: Yup.number().required("*This field is required.").min(0, "*Number is invalid.")
                    }))
            })),
        detailedJustification: Yup.string().required("*This field is required.")
    });

    onSubmit(data: any) {
        if (this.isAdminPage) {
            if (window.confirm("Click 'OK' to save your edits")) {
                this.submitForm(data)
            }
        } else {
            this.submitForm(data)
        }
    }

    submitForm(data: any) {
        this.detailedJustification = data.detailedJustification;

        if (this.isAdminPage) {
            this.claimAdvanceId = this.props.claimAdvance.claimAdvanceId
        }

        let advance = {
            claimAdvanceId: this.claimAdvanceId,
            type: 'A',
            userId: this.isAdminPage ? this.props.claimAdvance.userId : this.props.location.state.userFk,
            detailedJustification: this.detailedJustification,
        }

        if (!this.isAdminPage) {
            if (this.props.location.state.type === 'CA' || this.props?.claimAdvance?.type === 'CA') {
                advance.type = 'CA';
            }
        }


        if (this.isAdminPage || advance.type === 'CA') {
            axios.post("/claimAdvances/update", advance).then(() => {
                this.storeToDb(data);
            })

        } else {
            axios.post("/claimAdvances", advance).then((response) => {
                this.claimAdvanceId = response.data.claimAdvanceId;
                this.storeToDb(data);
            });
        }
    }

    async storeToDb(data: any) {
        if (this.isAdminPage) {
            this.claimAdvanceId = this.props.claimAdvance.claimAdvanceId;
        }

        const expectedCosts = {
            claimAdvanceId: this.claimAdvanceId,
            wages1: data.expectedCostsForms.wages.month1,
            wages2: data.expectedCostsForms.wages.month2,
            wages3: data.expectedCostsForms.wages.month3,
            training1: data.expectedCostsForms.training.month1,
            training2: data.expectedCostsForms.training.month2,
            training3: data.expectedCostsForms.training.month3,
            travel1: data.expectedCostsForms.travel.month1,
            travel2: data.expectedCostsForms.travel.month2,
            travel3: data.expectedCostsForms.travel.month3,
            other1: data.expectedCostsForms.other.month1,
            other2: data.expectedCostsForms.other.month2,
            other3: data.expectedCostsForms.other.month3,
        }
        
        if (this.isAdminPage) {
            let deleteObj = {
                claimAdvanceId: this.claimAdvanceId,
                isAdvance: true
            }
            
            axios.put("/employees", deleteObj).then(() => {
                data.employeeForms.forEach((row: any) => {
                    row.claimAdvanceId = this.claimAdvanceId;
                    row.isAdvance = true;
                    const wages = row.wages;

                    axios.post("/employees", row).then((response) => {
                        const employeeId = response.data.id;

                        wages.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.employeeId = employeeId;
                        })
                        
                        axios.post("/wages", wages)
                    });
                });
            })

            axios.put("/interns", deleteObj).then(() => {
                data.internForms?.forEach((row: any) => {
                    row.claimAdvanceId = this.claimAdvanceId;
                    row.isAdvance = true;
                    const training = row.training;
    
                    axios.post("/interns", row).then((response) => {
                        const internId = response.data.id;
                        training.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.internId = internId;
                        })
    
                        axios.post("/training", training)
                    });
                });
            })

            axios.put("travels", deleteObj).then(() => {
                data.travelForms?.forEach((row: any) => {
                    row.claimAdvanceId = this.claimAdvanceId;
                    row.isAdvance = true;
                    const trips = row.trips;
    
                    axios.post("/travels", row).then((response) => {
                        const travelId = response.data.id;
                        trips.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.travelId = travelId;
                        })
    
                        axios.post("/trips", trips)
                    });
                });
            })

            axios.put("/suppliers", deleteObj).then(() => {
                data.otherForms.forEach((row: any) => {
                    row.claimAdvanceId = this.claimAdvanceId;
                    row.isAdvance = true;
                    const items = row.items;
    
                    axios.post("/suppliers", row).then((response) => {
                        const supplierId = response.data.id;
                        items.forEach((row: any) => {
                            row.id = undefined;
                            row.claimAdvanceId = this.claimAdvanceId;
                            row.supplierId = supplierId;
                        })
    
                        axios.post("/items", items)
                    });
                });
            })

            axios.post("/expectedCosts", expectedCosts).then(() => {
                this.setState({edit: false, disabled: true})
            })

        } else {
            axios.post("/expectedCosts", expectedCosts)

            data.employeeForms?.forEach((row: any) => {
                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = true;
                const wages = row.wages;

                axios.post("/employees", row).then((response) => {
                    const employeeId = response.data.id;
                    wages.forEach((row: any) => {
                        row.id = undefined;
                        row.claimAdvanceId = this.claimAdvanceId;
                        row.employeeId = employeeId;
                    })

                    axios.post("/wages", wages)
                });
            });


            data.internForms?.forEach((row: any) => {
                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = true;
                const training = row.training;

                axios.post("/interns", row).then((response) => {
                    const internId = response.data.id;
                    training.forEach((row: any) => {
                        row.id = undefined;
                        row.claimAdvanceId = this.claimAdvanceId;
                        row.internId = internId;
                    })

                    axios.post("/training", training)
                });
            });

            data.travelForms?.forEach((row: any) => {
                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = true;
                const trips = row.trips;

                axios.post("/travels", row).then((response) => {
                    const travelId = response.data.id;
                    trips.forEach((row: any) => {
                        row.id = undefined;
                        row.claimAdvanceId = this.claimAdvanceId;
                        row.travelId = travelId;
                    })

                    axios.post("/trips", trips)
                });
            });

            data.otherForms?.forEach((row: any) => {
                row.claimAdvanceId = this.claimAdvanceId;
                row.isAdvance = true;
                const items = row.items;

                axios.post("/suppliers", row).then((response) => {
                    const supplierId = response.data.id;
                    items.forEach((row: any) => {
                        row.id = undefined;
                        row.claimAdvanceId = this.claimAdvanceId;
                        row.supplierId = supplierId;
                    })

                    axios.post("/items", items)
                });
            });

            this.setState({ redirect: true });
        }
    }

    constructor(props: any) {
        super(props);

        if (this.props.location.state) {
            if (this.props.location.state.claimAdvanceId > 0) {
                this.claimAdvanceId = this.props.location.state.claimAdvanceId;
            }
        }

        if (this.props.isAdmin) {
            const { ExpectedCosts, Employees, Interns, Travels, Suppliers } = this.props.claimAdvance;

            if (ExpectedCosts.length > 0) {
                const item = ExpectedCosts[0];
                this.initialValues.expectedCostsForms = {
                    wages: {
                        month1: item.wages1,
                        month2: item.wages2,
                        month3: item.wages3,
                        total: item.wages1 + item.wages2 + item.wages3
                    },
                    training: {
                        month1: item.training1,
                        month2: item.training2,
                        month3: item.training3,
                        total: item.training1 + item.training2 + item.training3
                    },
                    travel: {
                        month1: item.travel1,
                        month2: item.travel2,
                        month3: item.travel3,
                        total: item.travel1 + item.travel2 + item.travel3
                    },
                    other: {
                        month1: item.other1,
                        month2: item.other2,
                        month3: item.other3,
                        total: item.other1 + item.other2 + item.other3
                    },
                    total: {
                        month1: item.wages1 + item.training1 + item.travel1 + item.other1,
                        month2: item.wages2 + item.training2 + item.travel2 + item.other2,
                        month3: item.wages3 + item.training3 + item.travel3 + item.other3,
                        total: item.wages1 + item.wages2 + item.wages3 +
                            item.training1 + item.training2 + item.training3 +
                            item.travel1 + item.travel2 + item.travel3 +
                            item.other1 + item.other2 + item.other3
                    }
                }
            }

            if (Employees.length > 0) {
                Employees.filter((e: { isAdvance: boolean; }) => e.isAdvance).forEach((employee: any, index: number) => {
                    const { name, title, Wages } = employee;
                    this.initialValues.employeeForms.push({
                        name: name,
                        title: title,
                        wages: Wages
                    });
                });
                this.initialValues.employeeForms.shift();
            }

            if (Interns.length > 0) {
                Interns.filter((e: { isAdvance: boolean; }) => e.isAdvance).forEach((intern: any, index: number) => {
                    const { name, Trainings } = intern;
                    this.initialValues.internForms.push({
                        name: name,
                        training: Trainings
                    });
                });
                this.initialValues.internForms.shift();
            }

            if (Travels.length > 0) {
                Travels.filter((e: { isAdvance: boolean; }) => e.isAdvance).forEach((travel: any, index: number) => {
                    const { dateFrom, dateTo, name, description, reason, Trips } = travel;
                    this.initialValues.travelForms.push({
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                        name: name,
                        description: description,
                        reason: reason,
                        trips: Trips
                    });
                });
                this.initialValues.travelForms.shift();
            }

            if (Suppliers.length > 0) {
                Suppliers.filter((e: { isAdvance: boolean; }) => e.isAdvance).forEach((supplier: any, index: number) => {
                    const { name, Items } = supplier;
                    this.initialValues.otherForms.push({
                        name: name,
                        items: Items
                    });
                });
                this.initialValues.otherForms.shift();
            }

            this.initialValues.detailedJustification = this.props.claimAdvance.detailedJustification
        }

        this.state = {
            redirect: false,
            initialValues: this.initialValues,
            edit: false, 
            disabled: this.isAdminPage ? true : false,
            formKey: 0
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                this.props.history.push("/");
            } else {
                this.forceUpdate();
            }
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.claimAdvanceId = this.props.location.state?.claimAdvanceId;

        if (this.claimAdvanceId !== undefined) {
            axios.get("/claimAdvances/" + this.claimAdvanceId).then((response) => {

                let data = response?.data;
                const { ExpectedCosts, Employees, Interns, Travels, Suppliers } = data;

                this.initialValues.detailedJustification = data.detailedJustification;
                this.detailedJustification = data.detailedJustification;

                if (ExpectedCosts.length > 0) {
                    const item = ExpectedCosts[0];
                    this.initialValues.expectedCostsForms = {
                        wages: {
                            month1: item.wages1,
                            month2: item.wages2,
                            month3: item.wages3,
                            total: item.wages1 + item.wages2 + item.wages3
                        },
                        training: {
                            month1: item.training1,
                            month2: item.training2,
                            month3: item.training3,
                            total: item.training1 + item.training2 + item.training3
                        },
                        travel: {
                            month1: item.travel1,
                            month2: item.travel2,
                            month3: item.travel3,
                            total: item.travel1 + item.travel2 + item.travel3
                        },
                        other: {
                            month1: item.other1,
                            month2: item.other2,
                            month3: item.other3,
                            total: item.other1 + item.other2 + item.other3
                        },
                        total: {
                            month1: item.wages1 + item.training1 + item.travel1 + item.other1,
                            month2: item.wages2 + item.training2 + item.travel2 + item.other2,
                            month3: item.wages3 + item.training3 + item.travel3 + item.other3,
                            total: item.wages1 + item.wages2 + item.wages3 +
                                item.training1 + item.training2 + item.training3 +
                                item.travel1 + item.travel2 + item.travel3 +
                                item.other1 + item.other2 + item.other3
                        }
                    }
                }

                let filteredEmployees = Employees.filter((e: { isAdvance: boolean; }) => e.isAdvance);
                if (filteredEmployees.length > 0) {
                    filteredEmployees.forEach((employee: any, index: number) => {
                        const { name, title, Wages } = employee;
                        this.initialValues.employeeForms.push({
                            name: name,
                            title: title,
                            wages: Wages
                        });
                    });
                    this.initialValues.employeeForms.shift();
                }

                let filteredInterns = Interns.filter((e: { isAdvance: boolean; }) => e.isAdvance);
                if (filteredInterns.length > 0) {
                    filteredInterns.forEach((intern: any, index: number) => {
                        const { name, Trainings } = intern;
                        this.initialValues.internForms.push({
                            name: name,
                            training: Trainings
                        });
                    });
                    this.initialValues.internForms.shift();
                }

                let filteredTravels = Travels.filter((e: { isAdvance: boolean; }) => e.isAdvance);
                if (filteredTravels.length > 0) {
                    filteredTravels.forEach((travel: any, index: number) => {
                        const { dateFrom, dateTo, name, description, reason, Trips } = travel;
                        this.initialValues.travelForms.push({
                            dateFrom: dateFrom,
                            dateTo: dateTo,
                            name: name,
                            description: description,
                            reason: reason,
                            trips: Trips
                        });
                    });
                    this.initialValues.travelForms.shift();
                }

                let filteredSuppliers = Suppliers.filter((e: { isAdvance: boolean; }) => e.isAdvance);
                if (filteredSuppliers.length > 0) {
                    filteredSuppliers.forEach((supplier: any, index: number) => {
                        const { name, Items } = supplier;
                        this.initialValues.otherForms.push({
                            name: name,
                            items: Items
                        });
                    });
                    this.initialValues.otherForms.shift();
                }

                this.setState({ initialValues: this.initialValues });
                this.forceUpdate();
            })
        }
    }

    handleEdit() {
        if (!this.state.edit) {
            this.setState({edit: true, disabled: false})
        } else {
            if (window.confirm("Cancel Edit?")) {
                this.setState({edit: false, disabled: true, formKey: Math.random()})
            }
        }
    }

    render() {
        if (auth.currentUser == null) {
            return (<div />);
        }

        let hidePreviousButton = true;
        if (this.props.location?.state?.type === 'CA' || this.props?.claimAdvance?.type === "CA") {
            hidePreviousButton = false;
        }

        if (this.state.redirect) {
            return <Redirect to=
                {{
                    pathname: '/claims/stepTwo',
                    state: {
                        claimAdvanceId: this.claimAdvanceId,
                        userFk: this.props.location.state.userFk,
                        type: this.props.location.state.type,
                        detailedJustification: this.detailedJustification,
                        attached: this.props.location.state.attached
                    }
                }}
            />
        }

        return (
            <>
                <UserMenu email={auth.currentUser.email!} />

                <div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={this.initialValues}
                        onSubmit={this.onSubmit}
                        validationSchema={this.validationSchema}
                        key={this.state.formKey}
                    >{({values, errors }) => (
                            <Form>
                                {(!this.props.isAdmin || this.props.claimAdvance.type === "A" || this.props.claimAdvance.type === "CA") &&
                                    <>
                                        <FormHeader subtitle={'Advanced Justification'} />
                                    </>
                                }
                                {!this.props.isAdmin &&
                                    <>
                                        <AdvanceInstructions />
                                    </>
                                }

                                <AdvanceJustification values={values} isAdmin={this.state.disabled} isAdminPage={this.isAdminPage} edit={this.state.edit} />

                                <AdvanceErrorList errors={errors} />

                                {!this.props.isAdmin &&
                                    <div className="submit-btn-wrapper">
                                        <Link hidden={hidePreviousButton} to={{ pathname: '/claims/claimStepOne', state: { claimAdvanceId: this.claimAdvanceId, type: this.props.location.state.type, attached: this.props.history.location.state.attached } }}>
                                            <OutlineButton text={"Previous"} />
                                        </Link>
                                        <SolidButton type={ButtonTypes.submit} text={"Next"} />
                                    </div>
                                }
                                {
                                    this.isAdminPage && this.state.edit ?
                                    <div className='edit-btn-container'>
                                        <button className="edit-btn" type={ButtonTypes.submit}>Save</button>
                                        <div className="edit-btn" onClick={() => this.handleEdit()}>Cancel</div>
                                    </div>
                                    : null
                                }
                            </Form>
                        )}</Formik>
                    {
                        this.isAdminPage && !this.state.edit ?
                            <button className="edit-btn" onClick={() => this.handleEdit()}>Edit</button>
                        :
                        null
                    }
                </div>
            </>
        );
    }
}