import React from 'react';
import './OutlineButton.css'

enum ButtonTypes {
  button = "button",
  submit = "submit",
  reset = "reset"
} 

interface Props{
  text: string
  type?: ButtonTypes
}

export default class OutlineButton extends React.Component<Props> {
  static defaultProps = {
    type: ButtonTypes.button
  }

  render() {
    return (
      <button type={this.props.type} className="outline-btn">
        {this.props.text}
      </button>
    );
  }
}