import React from 'react';
import { TiPlus } from 'react-icons/ti'
import './AddAnother.css'

interface Props{
  text: string
}

export default class AddRow extends React.Component<Props> {
    render() {
      return (
          <div className="add-another">
              <div className="add-another-content">
                <TiPlus className="add-another-icon"/>
                <span>Add another {this.props.text}</span>
              </div>
          </div>
      );
    }
}