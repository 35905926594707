import React from 'react';
import './RadioQuestion.css'
import { Field, ErrorMessage } from 'formik';

interface Props{
    text: string
    option1?: string
    option2?: string
    id?: string
    name: string
    type?: string
    disabled?: boolean
}

export default class RadioQuestion extends React.Component<Props> {
    static defaultProps = {
      option1: 'Yes',
      option2: 'No'
    }

    render() {
      return (
        <div className="radio-input">
            <span>{this.props.text}</span>
            <Field 
                  type="radio"
                  value="true"
                  name={this.props.name}
                  id={this.props.id}
                  disabled={this.props.disabled}
            />{this.props.option1}
            <Field 
              type="radio" 
              value="false"
              name={this.props.name} 
              id = {this.props.id} 
              disabled = {this.props.disabled}
            />{this.props.option2}
            <ErrorMessage name={this.props.name!} component="div" className='error-msg'/>
        </div>
      );
    }
}
              