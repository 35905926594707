import React from 'react';
import './login.css';
import { TiLockClosed, TiMail } from 'react-icons/ti';
import { Link, withRouter } from "react-router-dom";
import { signIn, auth, resetPassword } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import SolidButton from '../../../components/SolidButton/SolidButton';
import Title from '../../../components/Title/Title';
import axios from "../../../http-common";

interface State{
    email: string
    password: string
    formErrors: { [name: string]: string }
    emailValid: boolean
    passwordValid: boolean
    forgotForm: boolean
    forgotEmail: string
    loginButton: boolean
}
class Login extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.setPassword = this.setPassword.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setForgotEmail = this.setForgotEmail.bind(this);
        this.state = {
            email: '',
            password: '',
            formErrors: { email: "" },
            emailValid: false,
            passwordValid: false,
            forgotForm: false,
            forgotEmail: '',
            loginButton: this.props.location.state ? this.props.location.state.loginButton : false,
        };
    }

    setPassword(e: { target: { value: string; }; }) {
        this.setState({password: e.target.value});
    }

    setEmail(e: { target: { value: string; }; }) {
        this.setState({email: e.target.value});
    }

    setForgotEmail(e: { target: { value: string; }; }) {
        this.setState({forgotEmail: e.target.value})
    }

    async login() {
        // TODO: email and password field validation
        await signIn(this.state.email, this.state.password)
            .then(async (user) => {
                if (user) {
                    // Check for existing user in db
                    axios.get("/users/" + user.uid).then((response) => {
                        if (!response.data) {
                            // Create new user in db
                            let userData = {
                                username: user.uid,
                                password: '',
                                firstname: '',
                                lastname: '',
                                email: user.email,
                                organisation: '',
                                approved: false,
                                isAdmin: false
                            };
                            if (this.state.loginButton === false) {
                                axios.post("/users", userData).then((response) => {
                                    const userFk = response.data.userId;
                                    
                                    // Navigate to claims
                                    this.props.history.push({
                                        pathname: '/claims',
                                        state: { userFk: userFk }
                                    });
                                });
                            } else {
                                this.props.history.push({pathname: '/welcome'});
                            }
                        } else {
                            if (this.state.loginButton === false) {
                                const userFk = response.data.userId;
                                // Navigate to claims
                                this.props.history.push({
                                    pathname: '/claims',
                                    state: { userFk: userFk }
                                });
                            } else {
                                this.props.history.push({pathname: '/welcome'});
                            }
                        }
                    });
                } else {
                    // TODO: auth error handling
                }
            });
    }

    async loginKey(e: any) {
        // TODO: email and password field validation

        if (e.key === "Enter") {
            await signIn(this.state.email, this.state.password).then(async (user) => {
                if (user) {
                    // Check for existing user in db
                    axios.get("/users/" + user.uid).then((response) => {
                        if (!response.data) {
                            // Create new user in db
                            let userData = {
                                username: user.uid,
                                password: '',
                                firstname: '',
                                lastname: '',
                                email: user.email,
                                organisation: '',
                                approved: false,
                                isAdmin: false
                            };
                            axios.post("/users", userData).then((response) => {
                                const userFk = response.data.userId;
                                // Navigate to claims
                                this.props.history.push({
                                    pathname: '/claims',
                                    state: { userFk: userFk }
                                });
                            });
                        } else {
                            const userFk = response.data.userId;
                            // Navigate to claims
                            this.props.history.push({
                                pathname: '/claims',
                                state: { userFk: userFk }
                            });
                        }
                    });
                }
            });
        }
    }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (auth.currentUser != null) {
                axios.get("/users/" + auth.currentUser?.uid).then((response) => {
                    if (response.data && response.data.userId && response.data.approved) {
                        this.props.history.push({
                            pathname: '/claims',
                            state: { userFk: response.data.userId }
                        });
                    }
                });
            }
        });
    }

    handleReset() {
        resetPassword(this.state.forgotEmail)
        this.setState({forgotForm: false})
    }

    render() {
        return (
            <div className = "inner-container login">
                <Title/>

                <div className = "login-container">
                    {
                        this.state.forgotForm ?
                        <div className="forgot-container">
                            <div className="forgot-icon">
                                <TiMail className="icon"/>
                                <input type="text" onChange={this.setForgotEmail}/>
                            </div>

                            <div className='forgot-button' onClick={() => this.handleReset()}><SolidButton text={"Send Email"} /></div>
                        </div>
                        :
                        <>
                        <div>You need to sign in before making a claim and/or an advance</div>

                        <form className="login-form" onKeyDown={(e) => this.loginKey(e)}>
                            <div className="icon-input">
                                <TiMail className="icon"/>
                                <input type="text" onChange={this.setEmail}/>
                            </div>

                            <div className="icon-input">
                                <TiLockClosed className="icon"/>
                                <input type="password" onChange={this.setPassword}/>
                            </div>
                        </form>

                        <div>Don't have an account with us? <span className="link"><a href='mailto:ds4y@pinnguaq.com'>Contact us</a></span></div>

                        <Link to="/welcome/login" onClick={() => this.login()}><SolidButton text={"Login"} /></Link>
                        </>
                    }
                    
                    {
                        this.state.forgotForm ? null :
                        <span className="link"><a onClick={() => this.setState({forgotForm: true})}>Forgot Password?</a></span>
                    }
                </div>
            </div>
        );
    }
}
export default withRouter(Login);