import React from 'react';
import './employeeTotalTable.css'
import '../table.css';
import NumberFormat from "react-number-format";

interface Props{
    values: any
}

interface State{
    grossAmount: number,
    cilob: number,
    totalSalary: number,
    totalSalaryClaimed: number,
}

export default class EmployeeTotalTable extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            grossAmount: 0,
            cilob: 0,
            totalSalary: 0,
            totalSalaryClaimed: 0,
        };

        this.setState({
            grossAmount: this.props.values.grossAmount,
            cilob: this.props.values.cilob,
            totalSalary: this.props.values.totalSalary,
            totalSalaryClaimed: this.props.values.totalSalaryClaimed,
        })
    }

    render() {
        return (
            <>
                <div className="custom-table employee-total-table">
                    <div className="custom-table-cell header"></div>
                    <div className="custom-table-cell header">Gross Amount</div>
                    <div className="custom-table-cell header">Added in Lieu of Benefits</div>
                    <div className="custom-table-cell header">Total Salary</div>
                    <div className="custom-table-cell header">Total Salary claimed to DS4Y (max 100%)</div>

                    <div className="custom-table-cell">Total</div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.grossAmount} />
                    </div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.cilob} />
                    </div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.totalSalary} />
                    </div>
                    <div className="custom-table-cell">
                        <span>$</span>
                        <NumberFormat
                            className="fill"
                            displayType={"text"}
                            isNumericString={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.state.totalSalaryClaimed} />
                    </div>
                </div>
            </>
        );
    }
}