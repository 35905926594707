import React from 'react';
import './adminBrowseApplications.css';
import { withRouter } from "react-router-dom";
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth"
import axios from "../../../http-common";
import AdminHeader from '../../../components/AdminHeader/AdminHeader';
import UserMenu from '../../../components/UserMenu/UserMenu';

interface State{
    applications: Array<any>,
    isAdmin: boolean
}

class AdminBrowseApplications extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            applications: [],
            isAdmin: false
        };

        axios.get("/applications").then((response) => {
            let tempArr: any = [];

            response.data.map((item: any) => {
                if (item.Declarations.length >= 1) {
                    tempArr.push(item)
                }
            })

            this.setState({ applications: tempArr });
        });
    }

    componentWillMount() {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                this.props.history.push("/admin");
            } else {
                axios.get("/users/" + auth.currentUser?.uid).then((response) => {
                    this.setState({ isAdmin: response.data != null && response.data.isAdmin });
                });
            }
        });
    }

    renderTableHeader() {
        if (this.state.applications.length <= 0) {
            return;
        }

        return (
            <tr>
                <th>Id</th>
                <th>Date</th>
                <th>Legal Business Name</th>
                <th>Doing Business As</th>
                <th>Province</th>
                <th>Post-Secondary Graduate</th>
                <th>Previous Participation</th>
                <th>Internships Applied For</th>
                <th>Submitted By</th>
            </tr>
        );
    }

    onApplicationSelected(id: number) {
        this.props.history.push("/admin/application/" + id);
    }

    renderTableData() {
        return this.state.applications.map((application, index) => {
            const { id, createdAt, Employers } = application;
            if (Employers === null || Employers.length === 0) {
                return (<></>);
            }

            const { legalBusinessName,
                doingBusinessAs,
                locatedIn,
                postSecondaryGraduate,
                contactEmail,
                previousParticipation,
                numInternships } = Employers[0];
            const dateString = (new Date(createdAt).toDateString());
            return (
                <tr key={id} onClick={() => this.onApplicationSelected(id)}>
                    <td>{id}</td>
                    <td>{dateString}</td>
                    <td>{legalBusinessName}</td>
                    <td>{doingBusinessAs}</td>
                    <td>{locatedIn}</td>
                    <td>{postSecondaryGraduate ? 'Yes' : 'No'}</td>
                    <td>{previousParticipation ? 'Yes' : 'No'}</td>
                    <td>{numInternships}</td>
                    <td>{contactEmail}</td>
                </tr>
            );
        });
    }

    render() {

        if (auth.currentUser == null) {
            return (<div />);
        }

        if (!this.state.isAdmin) {
            return (<div />);
        } else {
            return (
                <>
                    <UserMenu email={auth.currentUser.email!} />
                    <AdminHeader currentTab={0} />
                    <div className="inner-container browse">
                        <table id='applications'>
                            <tbody>
                                {this.renderTableHeader()}
                                {this.renderTableData()}
                            </tbody>
                        </table>
                    </div>
                </>
            );
        }
    }
}
export default withRouter(AdminBrowseApplications);