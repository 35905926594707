import React from 'react';
import { MonthlyAdvanceTable } from '../../..';
import '../subForm.css';

export default function SubFormMonthlyAdvance(props: any) {
  return (
    <div className='scroll-container advance-print-header'>
      <div className="sub-forms-container">
        <div className="sub-form">
          <MonthlyAdvanceTable isAdmin={props.isAdmin}/>
        </div>
      </div>
    </div>
  );
}