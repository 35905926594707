import React, {useEffect, useRef } from 'react';
import AddRow from '../../../../components/AddRow/AddRow';
import '../table.css';
import './otherTable.css';
import { Field, FieldArray, useFormikContext } from 'formik';
import NumberFormat from "react-number-format";
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';

export default function OtherTable(props: any) {
    useEffect(() => {
        updateTotal()
    }, [])

    const initialValuesItems = {
        description: '',
        invoiceDate: '',
        invoiceNum: 0,
        invoiceAmount: 0,
        tax: 0,
        gstHst: 0,
        totalClaimed: 0
    }

    const values: any = useFormikContext().values;
    const handleChange: any = useFormikContext().handleChange;

    const totalClaimed = useRef(0);

    const updateTotal = () => {
        let total = 0;
        const others = values.otherForms;
        others.forEach((element: any) => {
            const items = element.items;

            if (items) {
                items.forEach((element: any) => {
                    const totalClaimed = element.totalClaimed ? parseFloat(element.totalClaimed) : 0;
                    total+=totalClaimed;
                });
            }
        });
        totalClaimed.current = total;
        props.updateTotal(totalClaimed.current);
    }
    
    return (
        <FieldArray
            name={`otherForms.${props.supplierIndex}.items`}
            render={arrayHelpers => (
            <>
                <div className="custom-table other-invoices-table">
                    <div className="custom-table-cell header">Item Description</div>
                    <div className="custom-table-cell header">Invoice Ref. No.</div>
                    <div className="custom-table-cell header">Invoice Date</div>
                    <div className="custom-table-cell header">Invoice Amount Before Tax</div>
                    <div className="custom-table-cell header">Provincial Tax (After rebates)</div>
                    <div className="custom-table-cell header">GST / HST (After rebates)</div>
                    <div className="custom-table-cell header">Total {props.text} Amount </div>
                    <div className="custom-table-cell header remove"></div>

                    {
                        values.otherForms[props.supplierIndex].items &&
                        values.otherForms[props.supplierIndex].items.map((item: any, index: number) => (
                            <>
                            <div className="custom-table-cell">
                                <Field type="text" name={`otherForms.${props.supplierIndex}.items.${index}.description`} disabled={props.isAdmin}/>
                            </div>
                            <div className="custom-table-cell">
                                <Field type="text" name={`otherForms.${props.supplierIndex}.items.${index}.invoiceNum`} disabled={props.isAdmin}/>
                            </div>
                            <div className="custom-table-cell">
                                <Field type="date" max={'9999-12-31'} name={`otherForms.${props.supplierIndex}.items.${index}.invoiceDate`} disabled={props.isAdmin}/>
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`otherForms.${props.supplierIndex}.items.${index}.invoiceAmount`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.invoiceAmount}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`otherForms.${props.supplierIndex}.items.${index}.tax`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.tax}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`otherForms.${props.supplierIndex}.items.${index}.gstHst`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.gstHst}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell">
                                <span>$</span>
                                <NumberFormat
                                    className="fill"
                                    displayType={props.isAdmin ? "text" : "input"}
                                    name={`otherForms.${props.supplierIndex}.items.${index}.totalClaimed`}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onKeyUp={(e: any) => {
                                        handleChange(e)
                                        updateTotal()
                                    }}
                                    value={item.totalClaimed}
                                    disabled={props.isAdmin} />
                            </div>
                            <div className="custom-table-cell header remove">
                                <div hidden={index === 0 || props.isAdmin} onClick={() => {arrayHelpers.remove(index)}}>
                                    <RemoveItem/>
                                </div>
                            </div>
                            </>
                        ))
                    }
                </div>

                { 
                    !props.isAdmin &&
                    <div onClick={() => { arrayHelpers.push(initialValuesItems) }}>
                        <AddRow text={"supplier"} />
                    </div>
                }
            </>
        )}/>
    );
}