import React, { useState, useEffect } from 'react';
import '../subForm.css';
import Separator from '../../../../components/Separator/Separator';
import FormInput from '../../../../components/FormInput/FormInput';
import { OtherTable, OtherTotalTable } from '../../../index';
import AddAnother from '../../../../components/AddAnother/AddAnother';
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';

export default function SubFormOther(props: any) {
  useEffect(() => {
    if (_.isEqual(initialValues, values.otherForms[0])) {
      setIsEqual(true)
    }
  })

  const initialValuesItems = {
    description: '',
    invoiceDate: '',
    invoiceNum: 0,
    invoiceAmount: 0,
    tax: 0,
    gstHst: 0,
    totalClaimed: 0
  }

  const initialValues = {
    name: '',
    items: [initialValuesItems] 
  }

  let values: any = useFormikContext().values;
  const [total, setTotal] = useState(0);
  const [isEqual, setIsEqual] = useState(false);
  const [enable, setEnable] = useState(false);
  const [isEmpty, setIsEmpty] = useState(window.sessionStorage.getItem("emptyOther") === "true" ? true : false)

  if (isEqual && props.isAdminPage && !enable || isEmpty) {
    values.otherForms = []
  }

  const updateTotal = (value: number) => {
    setTotal(value)
  }

  const addNewRow = (helper: any) => {
    setEnable(true)
    setIsEmpty(false)
    helper.push(initialValues)
  }

    return (
      <div className='scroll-container advance-other-pring-break'>
        <div className="sub-forms-container">
          <div className="sub-form">
            <FieldArray
                name="otherForms"
                render={arrayHelpers => ( 
                  <>
                    <h1 style={{textTransform:"none"}}>OTHER DIRECT COSTS (NOT including travel)</h1>
                    {
                      values.otherForms.length === 0 ? null :
                      <div className="sub-form">
                      {
                        values.otherForms.map((item: any, index: number) => (
                          <div key={index} className="sub-form-sub-container-wrapper">
                            { !props.isAdmin &&
                              <div onClick={() => {arrayHelpers.remove(index)}}>
                                <RemoveItem/>
                              </div>
                            }
                            <div className="sub-form-sub-container">
                              <FormInput label={"Supplier"} name={`otherForms.${index}.name`} disabled={props.isAdmin} />
                            </div>
                            <OtherTable text={props.text} supplierIndex={index} updateTotal={updateTotal} isAdmin={props.isAdmin}/>
                            <div style={{marginTop:"20px", marginLeft:"-30px", marginRight:"-30px"}}><Separator/></div>
                          </div>
                        ))
                      }
                      </div>
                    }

                    {
                      !props.isAdmin &&
                        <div style={{ marginBottom: "30px" }} onClick={() => addNewRow(arrayHelpers)}>
                          <AddAnother text={"supplier"} />
                        </div>
                    }
                  </>
                )}
            />
            <OtherTotalTable text={props.text} totalClaimed={total}/>
          </div>
        </div>
      </div>
    );
}