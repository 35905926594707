import * as firebase from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth"
import { getStorage, ref } from "firebase/storage"
import "firebase/firestore"
import axios from "./http-common";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCsS2L7PeCYWN7H3_cEph6X_vXxzDCRmsI",
    authDomain: "ds4y-302f6.firebaseapp.com",
    projectId: "ds4y-302f6",
    storageBucket: "ds4y-302f6.appspot.com",
    messagingSenderId: "67446061728",
    appId: "1:67446061728:web:9daf3f1cc3040bda0ae7c5",
    measurementId: "G-7CHPD5Y2RZ"
};

firebase.initializeApp(firebaseConfig);
const auth = getAuth();
const storage = getStorage();

const signIn = async (email: string, password: string) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    } catch (err) {
        alert(err);
    }
};

const logout = () => {
    auth.signOut();
};

const makeId = (length: number) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length

    for (let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const resetPassword = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err: any) {
        console.error(err);
        alert(err.message);
    }
};

const register = async (email: string) => {
    
    const password = makeId(20);
    try {
        await createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
            // Create new user in db
            let userData = {
                username: userCredential.user.uid,
                password: '',
                firstname: '',
                lastname: '',
                email: userCredential.user.email,
                organisation: '',
                approved: false,
                isAdmin: false
            };
            logout();
            axios.post("/users", userData);
        });
    } catch(err) {
        alert(err);
    }
}

export default firebase;

export {
    auth,
    storage,
    ref,
    signIn,
    register,
    logout,
    resetPassword
};