import React, { useState, useEffect } from 'react';
import '../subForm.css';
import Separator from '../../../../components/Separator/Separator'
import FormInput from '../../../../components/FormInput/FormInput'
import { TravelTable, TravelTotalTable } from '../../../index'
import AddAnother from '../../../../components/AddAnother/AddAnother';
import RemoveItem from '../../../../components/RemoveItem/RemoveItem';
import { Field, FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';

export default function SubFormTravel(props: any) {
  useEffect(() => {
    if (_.isEqual(initialValues, values.travelForms[0])) {
      setIsEqual(true)
    }
  }, [])

  const initialValuesTrips = {
    kmsTravelled: 0,
    kmRate: 0,
    invoiceNum: 0,
    invoiceDate: '',
    invoiceAmount: 0,
    tax: 0,
    gstHst: 0,
    totalExpense: 0
  }

  const initialValues = {
    dateTo: '',
    dateFrom: '',
    name: '',
    description: '',
    reason: '',
    trips: [initialValuesTrips] 
  }
  
  let values: any = useFormikContext().values;
  const [total, setTotal] = useState(0);
  const [isEqual, setIsEqual] = useState(false);
  const [enable, setEnable] = useState(false);
  const [isEmpty, setIsEmpty] = useState(window.sessionStorage.getItem("emptyTravel") === "true" ? true : false)

  if (isEqual && props.isAdminPage && !enable || isEmpty) {
    values.travelForms = []
  }

  const updateTotal = (value: number) => {
    setTotal(value)
  }

  const addNewRow = (helper: any) => {
    setEnable(true)
    setIsEmpty(false)
    helper.push(initialValues)
  }

  return (
    <div className='scroll-container travel-print-break'>
      <div className="sub-forms-container">
        <div className="sub-form">
          <FieldArray
            name="travelForms"
            render={arrayHelpers => (
            <>
              <h1>Travel</h1>

              {
                values.travelForms.length === 0 ? null :
                <div className="sub-form">
                {
                  values.travelForms.map((item: any, index: number) => (
                    <div className="sub-form-sub-container-wrapper" key={index}>
                      { 
                        !props.isAdmin &&
                        <div onClick={() => {arrayHelpers.remove(index)}}>
                          <RemoveItem/>
                        </div>
                      }

                      <div className="sub-form-sub-container">
                        <div>
                          <FormInput label={"Date of Travel"} type="date" name={`travelForms.${index}.dateFrom`} disabled={props.isAdmin} />
                          <span>to</span>
                          <FormInput label={""} type="date" name={`travelForms.${index}.dateTo`} disabled={props.isAdmin} />
                        </div>
                        
                        <FormInput label={"Name of Traveller"} name={`travelForms.${index}.name`} disabled={props.isAdmin} />
                      </div>
                      <div className="sub-form-sub-container">
                        <FormInput label={"Item / Description (Taxi, meal, hotel, vehicle rental, plane, rail, private vehicle, etc.)"} name={`travelForms.${index}.description`} disabled={props.isAdmin} />
                        <div className="custom-form-input">
                            <label style = {{marginBottom:"33px"}}>Reason for Travel (e.g. meetings, workshops, etc.)</label>
                            <Field type="text" name={`travelForms.${index}.reason`} disabled={props.isAdmin}/>
                        </div>
                      </div>
                      <TravelTable tripIndex={index} updateTotal={updateTotal} isAdmin={props.isAdmin}/>
                      <div style={{marginTop:"20px", marginLeft:"-30px", marginRight:"-30px"}}><Separator/></div>
                    </div>
                  ))
                } 
                </div>
              }

              {
                !props.isAdmin &&
                  <div style={{ marginBottom: "30px" }} onClick={() => addNewRow(arrayHelpers)}>
                    <AddAnother text={"traveler"} />
                  </div>
              }
            </>
          )}/>
          <TravelTotalTable totalExpense={total}/>
        </div>
      </div>
    </div>
  );
}